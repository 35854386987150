import React, { useState, useMemo, useEffect, useRef } from "react";
import Table from "../../../components/Table/Table";
import TableOption from "../../../components/modal/TableOption";
import { SlOptionsVertical } from "react-icons/sl";
import { FiSearch } from "react-icons/fi";
import InputField from "../../../components/controls/InputField";

// import Requery from "../../components/requery/Requery";
// import Dispute from "../../components/dispute/Dispute";
// import RightDrawer from "../../../components/drawer/RightDrawer";
// import ExportButton from "../../../components/controls/ExportButton";
import { toast } from "react-toastify";
import Loading from "../../../components/loader/Loading";
import "react-toastify/dist/ReactToastify.css";
import { webhookService } from "../../../services/webhook.service";
import useAuth from "../../../usehook/useAuth";
import { FiCopy } from "react-icons/fi";
import { RiFilter3Fill } from "react-icons/ri";
// import { TfiDownload } from "react-icons/tfi";
// import { BsCalendar2Date } from "react-icons/bs";
// import FilterByDate from "./FilterByDate";
import FilterTransactions from "./FilterTransactions";
// import ExportTransaction from "./ExportTransaction";
// import Statistics from "./Statistics";
const TransactionTable = () => {
  const { logout } = useAuth();
  const [transData, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getTransactions = async () => {
    try {
      setIsLoading(true);
      const result = await webhookService.getWebhookNotification(currentPage);
      if (result && result.data && result.data.success === true) {
        setData(result.data.data);
        // alert(JSON.stringify(result.data.data))
        setTotalPages(result.data.pagination.pages);
        setIsLoading(false);
      } else {
        toast.error("Error fetching transaction..");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        logout();
      }
      setData([]);
      setTotalPages(0);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTransactions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageChange = ({ nextPage }) => {
    setCurrentPage(nextPage);
    getTransactions(nextPage);
  };
  const getTransactionsByStatus = async (status) => {
    try {
      setIsLoading(true);
      const result = await webhookService.getWebhookNotificationByStatus(
        status,
        currentPage
      );
      if (result && result.data && result.data.success === true) {
        setData(result.data.data);
        // alert(JSON.stringify(result.data.data))
        setTotalPages(result.data.pagination.pages);
        setIsLoading(false);
      } else {
        toast.error("Error fetching transaction..");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        logout();
      }
      setData([]);
      setTotalPages(0);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const getTransactionsByRef = async (value) => {
    try {
      setIsLoading(true);
      const result = await webhookService.getWebhookNotificationByReference(value);
      if (
        result &&
        result.data &&
        result.data.success === true &&
        result.data.data
      ) {
        setData([result.data.data]);
      } else {
        toast.error("Error fetching transactions.");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        logout();
      }
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const [selectedStatus, setSelectedStatus] = useState("");
  const [ref, setRef] = useState("");
  const handleStatusChange = (event) => {
    const status = event.target.value;
    setSelectedStatus(status);
    getTransactionsByStatus(status);
  };
  const handleReferenceChange = (event) => {
    const value = event.target.value;
    setRef(value);
    if (value === "") {
      // If the search value is empty, fetch all transactions
      getTransactions();
    } else {
      // Otherwise, fetch transactions by reference
      getTransactionsByRef(value);
    }
  };

  
  const [copiedValue, setCopiedValue] = useState(null);

  const handleCopy = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopiedValue(value);
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard", error);
      });
  };
  const repush = (id) =>{
webhookService.repush(id).then((result) => {
  if(result && result.data.success){
    toast.success(result.data.message);
  }else{
    toast.error(result.data.message);
  }
}).catch((error) =>{
  toast.success(error?.response?.data?.message ?? error.message);
})
  }
  const columns = useMemo(() => {
    return [
    
      {
        Header: "Reference",
        accessor: "reference",
        Cell: ({ value }) => {
          // Check if the value is present and has a length greater than 8
          if (value && value.length > 8) {
            const firstFourLetters = value.substring(0, 4);
            const lastFourLetters = value.substring(value.length - 4);

            const modifiedValue = `${firstFourLetters}...${lastFourLetters}`;

            return (
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => handleCopy(value)}
              >
                {modifiedValue}
                <FiCopy
                  size={16}
                  className="ml-2"
                  onClick={() => handleCopy(value)}
                />
              </span>
            );
          } else {
            // Handle the case where the value is not present or too short
            return value;
          }
        },
      },
      {
        Header: "Session Id",
        accessor: "sessionId",
        Cell: ({ value }) => {
          // Check if the value is present and has a length greater than 8
          if (value && value.length > 8) {
            const firstFourLetters = value.substring(0, 4);
            const lastFourLetters = value.substring(value.length - 4);

            const modifiedValue = `${firstFourLetters}...${lastFourLetters}`;

            return (
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => handleCopy(value)}
              >
                {modifiedValue}
                <FiCopy
                  size={16}
                  className="ml-2"
                  onClick={() => handleCopy(value)}
                />
              </span>
            );
          } else {
            // Handle the case where the value is not present or too short
            return value;
          }
        },
      },

      {
        Header: "Amount",
        accessor: "amountReceived",
        Cell: ({ value }) => {
          const formattedAmount = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value);

          return (
            <>
              <div>{formattedAmount}</div>
            </>
          );
        },
      },
    
      {
        Header: "Response",
        accessor: "status",
        Cell: ({ row }) => {
          const status = row.original.status;
          let statusText, statusColor;

          if (status === "successful") {
            statusText = "Successful";
            statusColor = "text-green";
          } else if (status === "pending") {
            statusText = "Pending";
            statusColor = "text-amber-600";
          } else if (status === "paid") {
            statusText = "Paid";
            statusColor = "text-red";
          } else if (status === "initiated") {
            statusText = "initiated";
            statusColor = "text-amber-600";
          } else {
            statusText = "Failed";
            statusColor = "text-red";
          }
          return (
            <div
              className={`flex flex-row space-x-4 justify-center items-center w-20 border rounded-md p-2 `}
            >
              <span className={`text-center ${statusColor}`}>{statusText}</span>
            </div>
          );
        },
      },
      {
        Header: "Virtual Account",
        accessor: "virtualAccount",
        Cell: ({ row }) => {
          return (
            <div className="flex items-center w-20">
              {row?.original?.virtualAccount              }
            </div>
          );
        },
      },
      
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: ({ value }) => {
          const date = new Date(value * 1000); // Convert Unix timestamp to milliseconds
          const formattedDate = date.toLocaleString(); // Format the date as a string
          return <div>{formattedDate}</div>;
        },
      },
    
      {
        Header: "Action",
        accessor: "menu",
        Cell: ({ row }) => {
          return (
            <div>
              <TableOption
                index={0}
                icon={<SlOptionsVertical size={20} className="text-primary" />}
              >
                <ul className="flex flex-col space-y-1 py-2 text-start capitalize">
                {/* <li>
                    <button
                      className="w-full p-2 text-start hover:bg-primary hover:text-white"
                      // onClick={() => repush(row.original.id)}
                    >
                     View Details
                    </button>
                  </li> */}
                  <li>
                    <button
                      className="w-full p-2 text-start hover:bg-primary hover:text-white"
                      onClick={() => repush(row.original.id)}
                    >
                      Re Push
                    </button>
                  </li>
                </ul>
              </TableOption>
            </div>
          );
        },
      },
    ];
  }, []);

 const [isModalFOpen, setIsModalFOpen] = useState(false);
  const filterDropdownRef = useRef(null);
  const handleFOpen = () => {
    setIsModalFOpen(!isModalFOpen);
  };

  //show statistics
  // const [isChecked, setIsChecked] = useState(false);

  // const handleToggle = () => {
  //   const newValue = !isChecked;
  //   setIsChecked(newValue);
  // };
  return (
    <div className="p-3">
      <div> {copiedValue && <p>Copied: {copiedValue}</p>}</div>
      <div className="flex flex-col md:flex-row md:justify-between">
        <div className="flex flex-col space-y-3 space-x-0 md:space-y-0 lg:space-y-0 md:flex-row md:space-x-5 justify-end">
          <div>
            <InputField
              type="text"
              // label="Search"
              name="ref"
              placeholder="Search by reference"
              onChange={handleReferenceChange}
              value={ref}
              rightIcon={<FiSearch size={20} />}
            />
          </div>
          <div className="">
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleFOpen();
              }}
              className="bg-lig w-20 h-12 p-2 flex flex-row space-x-2 cursor-pointer mt-3 rounded-lg"
            >
              <p className="text-dark text-sm">Filter</p>
              <RiFilter3Fill size={20} className="text-dark" />
            </div>
            {isModalFOpen ? (
              <>
                <div
                  ref={filterDropdownRef}
                  className="absolute w-80 h-60 my-4 py-4 border bg-white shadow-xl px-3 mx-auto rounded-md z-50"
                >
                  <FilterTransactions
                    selectedStatus={selectedStatus}
                    handleStatusChange={handleStatusChange}
                    logout={logout}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div>
            {/* <div
              onClick={(e) => {
                e.stopPropagation();
                handleDOpen();
              }}
              className="bg-lig w-8 h-8 p-2 flex flex-row space-x-2 cursor-pointer mt-3 rounded-lg"
            >
              <BsCalendar2Date size={20} className="text-dark" />
            </div> */}
            {/* <ExportButton  /> */}
            {/* {isModalDOpen ? (
              <>
                <div
                  ref={dateDropdownRef}
                  className="absolute w-80 h-80 my-4 py-4 border bg-white shadow-xl px-3 mx-auto  rounded-md z-50"
                >
                  <FilterByDate logout={logout} />
                </div>
              </>
            ) : null} */}
          </div>
          {/* <div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleUOpen();
              }}
              className="bg-lig w-8 h-8 p-2 flex flex-row space-x-2 cursor-pointer mt-3 rounded-lg"
            >
              <TfiDownload size={20} className="text-dark" />
            </div>
          
            {isModalUOpen ? (
              <>
                <div
                  ref={dropdownRef}
                  className="absolute w-80 h-92 my-4 py-4 border bg-white shadow-xl px-3 mx-auto  rounded-md z-50"
                >
                  <ExportTransaction logout={logout} />
                </div>
              </>
            ) : null}
          </div>*/}
        </div> 
      
      </div>
     

      <div className="py-4">
        {isLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            {transData.length === 0 ? (
              <p className="text-center text-red-500 text-md font-semibold">
                No Transaction available
              </p>
            ) : (
              <Table
                columns={columns}
                data={transData}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            )}
          </>
        )}
      </div>
      
    </div>
  );
};

export default TransactionTable;
