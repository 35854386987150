import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBuilding, faIdCard } from "@fortawesome/free-solid-svg-icons";
import { authService } from "services/auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { kycService } from "services/kyc.service";

const StepIndicator = ({ currentStep }) => {
  const steps = [
    { title: "Business Profile", description: "Fill out your business profile details.", icon: faBuilding },
    { title: "Business Details", description: "Provide detailed information about your business.", icon: faIdCard },
    { title: "KYC", description: "Complete the Know Your Customer (KYC) process.", icon: faUser },
  ];

  return (
    <div className="flex flex-col space-y-6 items-start h-[400px] py-8 px-6" style={{ backgroundColor: '#003022', borderRadius: '8px' }}>
      {steps.map((step, index) => (
        <div key={index} className="flex items-center space-x-4" style={{ marginBottom: 40 }}>
          <FontAwesomeIcon
            icon={step.icon}
            className={`text-2xl ${
              index + 1 <= currentStep ? "text-primary" : "text-white"
            }`}
          />
          <div>
            <span
              className={`font-semibold text-lg ${
                index + 1 <= currentStep ? "text-primary" : "text-white"
              }`}
            >
              {step.title}
            </span>
            <p className="text-base text-gray-400">{step.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const KYCWizard = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  // State variables for form inputs
  const [registeredCompanyName, setRegisteredCompanyName] = useState("");
  const [rcNumber, setRcNumber] = useState("");
  const [tinNumber, setTinNumber] = useState("");
  const [companyEmailAddress, setCompanyEmailAddress] = useState("");
  const [estimatedTransactionVolume, setEstimatedTransactionVolume] = useState("");
  const [estimatedTransactionValue, setEstimatedTransactionValue] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [primaryFirstName, setPrimaryFirstName] = useState("");
  const [primaryLastName, setPrimaryLastName] = useState("");
  const [primaryDesignation, setPrimaryDesignation] = useState("");
  const [primaryEmailAddress, setPrimaryEmailAddress] = useState("");
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("");
  const [secondaryFirstName, setSecondaryFirstName] = useState("");
  const [secondaryLastName, setSecondaryLastName] = useState("");
  const [secondaryDesignation, setSecondaryDesignation] = useState("");
  const [secondaryEmailAddress, setSecondaryEmailAddress] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [certificateOfIncorporation, setCertificateOfIncorporation] = useState(null);
  const [formCAC1_1, setFormCAC1_1] = useState(null);
  const [cac7A, setCac7A] = useState(null);
  const [memart, setMemart] = useState(null);
  const [licenseOfOperate, setLicenseOfOperate] = useState(null);
  const [pciDSSComplianceCertificate, setPciDSSComplianceCertificate] = useState(null);
  const [utilityBill, setUtilityBill] = useState(null);
  const [director1ValidID, setDirector1ValidID] = useState(null);
  const [director2ValidID, setDirector2ValidID] = useState(null);

  const getProfile = () => {
    authService
      .getProfile()
      .then((result) => {
        if (result && result.data.success) {
          setUserData(result.data.business);
        } else toast.error(result.data.message);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response?.message ?? error.response?.data?.message ?? "Error fetching profile"
        );
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleNextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = () => {
    setIsProcessing(true);

    // Create FormData
    const formData = new FormData();

    // Helper function to append fields if they are not empty
    const appendFieldIfNotEmpty = (key, value) => {
        if (value) {
            if (value instanceof File) {
                formData.append(key, value);
            } else {
                formData.append(key, value);
            }
        }
    };

    // Append business profile fields
    appendFieldIfNotEmpty('rcNumber', rcNumber);
    appendFieldIfNotEmpty('tinNumber', tinNumber);
    appendFieldIfNotEmpty('companyEmailAddress', companyEmailAddress);
    appendFieldIfNotEmpty('estimatedTransactionVolume', estimatedTransactionVolume);
    appendFieldIfNotEmpty('estimatedTransactionValue', estimatedTransactionValue);
    appendFieldIfNotEmpty('companyAddress', companyAddress);
    appendFieldIfNotEmpty('companyPhoneNumber', companyPhoneNumber);
    appendFieldIfNotEmpty('companyWebsite', companyWebsite);

    // Append primary contact fields
    appendFieldIfNotEmpty('primaryFirstName', primaryFirstName);
    appendFieldIfNotEmpty('primaryLastName', primaryLastName);
    appendFieldIfNotEmpty('primaryDesignation', primaryDesignation);
    appendFieldIfNotEmpty('primaryEmailAddress', primaryEmailAddress);
    appendFieldIfNotEmpty('primaryPhoneNumber', primaryPhoneNumber);

    // Append secondary contact fields
    appendFieldIfNotEmpty('secondaryFirstName', secondaryFirstName);
    appendFieldIfNotEmpty('secondaryLastName', secondaryLastName);
    appendFieldIfNotEmpty('secondaryDesignation', secondaryDesignation);
    appendFieldIfNotEmpty('secondaryEmailAddress', secondaryEmailAddress);
    appendFieldIfNotEmpty('secondaryPhoneNumber', secondaryPhoneNumber);

    // Append document fields
    appendFieldIfNotEmpty('certificateOfIncorporation', certificateOfIncorporation);
    appendFieldIfNotEmpty('formCAC1_1', formCAC1_1);
    appendFieldIfNotEmpty('cac7A', cac7A);
    appendFieldIfNotEmpty('memart', memart);
    appendFieldIfNotEmpty('licenseOfOperate', licenseOfOperate);
    appendFieldIfNotEmpty('pciDSSComplianceCertificate', pciDSSComplianceCertificate);
    appendFieldIfNotEmpty('utilityBill', utilityBill);
    appendFieldIfNotEmpty('director1ValidID', director1ValidID);
    appendFieldIfNotEmpty('director2ValidID', director2ValidID);

    // Call API
    kycService.submitKyc(formData)
        .then((response) => {
            toast.success('KYC submission successful!');
            navigate("/"); // Redirect after successful submission
        })
        .catch((error) => {
            console.log(error);
            toast.error(
                error.response?.message ?? error.response?.data?.message ?? "Error submitting KYC"
            );
        })
        .finally(() => {
            setIsProcessing(false);
        });
};


  
  

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="w-full">
            <h2 className="text-lg font-semibold mb-4">Step 1: Business Profile</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Registered Company Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder={userData?.businessName || ''}
                  value={userData?.businessName || ''}
                  onChange={(e) => setRegisteredCompanyName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">RC Number</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter your RC number"
                  value={rcNumber}
                  onChange={(e) => setRcNumber(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Tax Identification Number (TIN)</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter your TIN number"
                  value={tinNumber}
                  onChange={(e) => setTinNumber(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Company Email Address</label>
                <input
                  type="email"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder={userData?.businessEmail || ''}
                  value={userData?.businessEmail || ''}
                  onChange={(e) => setCompanyEmailAddress(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Estimated Transaction Volume</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder={userData?.estimatedDailyTransactionVolume || ''}
                  value={userData?.estimatedDailyTransactionVolume || ''}
                  onChange={(e) => setEstimatedTransactionVolume(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Estimated Transaction Value</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder={userData?.estimatedDailyTransferVolume || ''}
                  value={userData?.estimatedDailyTransferVolume || ''}
                  onChange={(e) => setEstimatedTransactionValue(e.target.value)}
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="w-full">
            <h2 className="text-lg font-semibold mb-4">Step 2: Business Details</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Company Address</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder={userData?.businessAddress || ''}
                  value={userData?.businessAddress || ''}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Company Phone Number</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder={userData?.phone || ''}
                  value={userData?.phone || ''}
                  onChange={(e) => setCompanyPhoneNumber(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Company Website</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter your company website"
                  value={companyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Primary Contact First Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter primary contact's first name"
                  value={primaryFirstName}
                  onChange={(e) => setPrimaryFirstName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Primary Contact Last Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter primary contact's last name"
                  value={primaryLastName}
                  onChange={(e) => setPrimaryLastName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Primary Contact Designation</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter primary contact's designation"
                  value={primaryDesignation}
                  onChange={(e) => setPrimaryDesignation(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Primary Contact Email Address</label>
                <input
                  type="email"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter primary contact's email address"
                  value={primaryEmailAddress}
                  onChange={(e) => setPrimaryEmailAddress(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Primary Contact Phone Number</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter primary contact's phone number"
                  value={primaryPhoneNumber}
                  onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Secondary Contact First Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter secondary contact's first name"
                  value={secondaryFirstName}
                  onChange={(e) => setSecondaryFirstName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Secondary Contact Last Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter secondary contact's last name"
                  value={secondaryLastName}
                  onChange={(e) => setSecondaryLastName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Secondary Contact Designation</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter secondary contact's designation"
                  value={secondaryDesignation}
                  onChange={(e) => setSecondaryDesignation(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Secondary Contact Email Address</label>
                <input
                  type="email"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter secondary contact's email address"
                  value={secondaryEmailAddress}
                  onChange={(e) => setSecondaryEmailAddress(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Secondary Contact Phone Number</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  placeholder="Enter secondary contact's phone number"
                  value={secondaryPhoneNumber}
                  onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="w-full">
            <h2 className="text-lg font-semibold mb-4">Step 3: Upload Documents</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Certificate of Incorporation</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setCertificateOfIncorporation(e.target.files[0])}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Form CAC1.1</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setFormCAC1_1(e.target.files[0])}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">CAC7A</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setCac7A(e.target.files[0])}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Memart</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setMemart(e.target.files[0])}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">License of Operation</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setLicenseOfOperate(e.target.files[0])}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">PCI DSS Compliance Certificate</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setPciDSSComplianceCertificate(e.target.files[0])}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Utility Bill</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setUtilityBill(e.target.files[0])}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Director 1 Valid ID</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setDirector1ValidID(e.target.files[0])}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Director 2 Valid ID</label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  onChange={(e) => setDirector2ValidID(e.target.files[0])}
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex">
      <StepIndicator currentStep={currentStep} />
      <div className="flex-1 p-8 bg-white rounded-lg shadow-lg">
        {renderCurrentStep()}
        <div className="flex justify-between mt-6">
          <button
            className={`py-2 px-4 rounded-lg border ${currentStep === 1 ? 'border-gray-300' : 'border-blue-500'} text-sm`}
            disabled={currentStep === 1}
            onClick={handlePreviousStep}
          >
            Previous
          </button>
          <button
            className={`py-2 px-4 rounded-lg bg-blue-500 text-white text-sm ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleNextStep}
            disabled={isProcessing}
          >
            {currentStep === 3 ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default KYCWizard;
