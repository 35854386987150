import React from "react";
// import ApiKeysTable from "./Components/Table";
import Form from "./forms/Form"
const ApiKeys = () =>{
    return(
        <div className="p-6">
        <div className="py-12">
            <p className="text-xl text-dark font-semibold">API Keys Apllications</p>
        </div>
        <div className="p-6 bg-white">
        {/* <ApiKeysTable /> */}
        <Form />
        </div>
        
    </div>
    );
};
export default ApiKeys