import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { kycService } from '../../services/kyc.service';
import { uploadService } from '../../services/fileUpload.service';
import { authService } from "services/auth.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaBuilding, FaUser, FaFile } from 'react-icons/fa'; // Icons for steps

const StepIndicator = ({ currentStep }) => {
  const steps = [
    { title: 'Business Profile', description: 'Enter your business details', icon: <FaBuilding /> },
    { title: 'Business Details', description: 'Provide director details', icon: <FaUser /> },
    { title: 'KYC Documents', description: 'Upload necessary documents', icon: <FaFile /> }
  ];

  return (
    <div className="w-72 mr-10 p-5 bg-[#003022] rounded-lg shadow-lg text-white">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`flex items-center mb-5 p-4 rounded-lg ${index === currentStep ? 'bg-[#00BC74]' : 'bg-[#003022]'} text-white`}
        >
          <div className="w-12 h-12 mr-4 bg-[#00BC74] rounded-full flex items-center justify-center text-2xl">
            {step.icon}
          </div>
          <div>
            <div className="text-lg font-semibold">{step.title}</div>
            <div className="text-sm text-gray-300">{step.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const KYCForm = () => {
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      kyc: { bn: '', rc: '', tin: '' },
      documents: [{ name: '', file: null }],
      directors: [
        {
          firstname: '',
          middlename: '',
          surname: '',
          designation: '',
          email: '',
          phone: '',
          bvn: '',
          dateOfBirth: '',
          identityDocument: null,
        }
      ]
    }
  });

  const { fields: documentFields, append: addDocument } = useFieldArray({
    control,
    name: 'documents'
  });

  const { fields: directorFields, append: addDirector } = useFieldArray({
    control,
    name: 'directors'
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [fileUrls, setFileUrls] = useState([]);


  const getProfile = () => {
    authService
      .getProfile()
      .then((result) => {
        if (result && result.data.success) {
          setUserData(result.data.business);
        } else toast.error(result.data.message);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response?.message ?? error.response?.data?.message ?? "Error fetching profile"
        );
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleFileUpload = async (file, name) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const uploadResponse = await uploadService.uploadFile(formData);
      if (uploadResponse.data && uploadResponse.data.success && uploadResponse.data.data) {
        const fileURL = uploadResponse.data.data.url;
        toast.success('File uploaded successfully.');
        setFileUrls((prevFileUrls) => {
          const existingFileIndex = prevFileUrls.findIndex((item) => item.name === name);
          if (existingFileIndex !== -1) {
            const updatedFileUrls = [...prevFileUrls];
            updatedFileUrls[existingFileIndex] = { name, url: fileURL };
            return updatedFileUrls;
          } else {
            return [...prevFileUrls, { name, url: fileURL }];
          }
        });
        return fileURL;
      } else {
        toast.error(uploadResponse.data.message);
        return '';
      }
    } catch (error) {
      toast.error(error.response?.data.message ?? error.message);
      return '';
    } finally {
      setLoading(false);
    }
  };

  const onSubmitForm = async (data) => {
    try {
      const formattedDocuments = await Promise.all(
        data.documents.map(async (doc) => ({
          name: doc.name,
          url: doc.file ? await handleFileUpload(doc.file[0], doc.name) : ''
        }))
      );

      const formattedDirectors = await Promise.all(
        data.directors.map(async (director) => ({
          ...director,
          identityDocumentUrl: director.identityDocument
            ? await handleFileUpload(director.identityDocument[0], director.firstname)
            : ''
        }))
      );

      const formattedData = {
        kyc: data.kyc,
        documents: formattedDocuments,
        directors: formattedDirectors
      };

      console.log('Formatted Payload:', JSON.stringify(formattedData, null, 2));

      const response = await kycService.submitKyc(formattedData);
      toast.success('KYC submitted successfully');
    } catch (error) {
      console.error('Error submitting KYC:', error);
      toast.error('Error submitting KYC');
    }
  };

  const nextStep = () => setCurrentStep((prevStep) => Math.min(prevStep + 1, 2));
  const prevStep = () => setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));

  return (
    <div className="flex items-start pt-5 min-h-screen bg-gray-100">
      <StepIndicator currentStep={currentStep} />

      <form onSubmit={handleSubmit(onSubmitForm)} className="flex-1 p-8 bg-white rounded-lg shadow-lg">
        {/* <h2 className="text-2xl font-semibold text-[#003022] mb-6">KYC Form</h2> */}

        {currentStep === 0 && (
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Business Profile</h3>
            <div className="grid grid-cols-2 gap-6">
              <input {...register('kyc.bn')} value={userData?.businessName || ''} placeholder="Business Name (BN)" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
              <input {...register('kyc.rc')} placeholder="RC Number" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
              <input {...register('kyc.tin')} placeholder="TIN" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
              <div className="p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-700">
              {userData?.businessEmail || ''}
              </div>
              <div className="p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-700">
                Estimated Daily Transaction {userData?.estimatedDailyTransactionVolume || ''}
              </div>
              <div className="p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-700">
              Estimated Daily Transfer {userData?.estimatedDailyTransferVolume || ''}
              </div>
            </div>
          </div>
        )}

        {currentStep === 1 && (
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Business Details</h3>
            {directorFields.map((field, index) => (
              <div key={field.id} className="grid grid-cols-2 gap-6 mb-4">
                <div className="p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-700">
                 {userData?.businessAddress || ''}
                </div>
                <div className="p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-700">
                {userData?.phone || ''}
                </div>
                <input {...register(`directors.${index}.firstname`)} placeholder="First Name" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <input {...register(`directors.${index}.middlename`)} placeholder="Middle Name" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <input {...register(`directors.${index}.surname`)} placeholder="Surname" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <input {...register(`directors.${index}.designation`)} placeholder="Designation" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <input {...register(`directors.${index}.email`)} placeholder="Email" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <input {...register(`directors.${index}.phone`)} placeholder="Phone" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <input {...register(`directors.${index}.bvn`)} placeholder="BVN" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <input {...register(`directors.${index}.dateOfBirth`)} placeholder="Date of Birth" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <label className="text-gray-700">Identity Document</label>
                <input
                  type="file"
                  {...register(`directors.${index}.identityDocument`)}
                  className="p-3 border border-gray-300 rounded-md bg-white"
                />
              </div>
            ))}
            <button type="button" onClick={() => addDirector({
              firstname: '',
              middlename: '',
              surname: '',
              designation: '',
              email: '',
              phone: '',
              bvn: '',
              dateOfBirth: '',
              identityDocument: null,
            })} className="text-white bg-[#003022] hover:bg-primary py-2 px-4 rounded-md">
              Add Director
            </button>
          </div>
        )}

        {currentStep === 2 && (
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">KYC Documents</h3>
            {documentFields.map((field, index) => (
              <div key={field.id} className="grid grid-cols-2 gap-6 mb-4">
                <input {...register(`documents.${index}.name`)} placeholder="Document Name" className="p-3 border border-gray-300 rounded-md bg-gray-100" />
                <input
                  type="file"
                  {...register(`documents.${index}.file`)}
                  className="p-3 border border-gray-300 rounded-md bg-white"
                />
              </div>
            ))}
            <button type="button" onClick={() => addDocument({ name: '', file: null })} className="text-white bg-[#003022] hover:bg-primary py-2 px-4 rounded-md">
              Add Document
            </button>
          </div>
        )}

        <div className="flex justify-between">
          {currentStep > 0 && (
            <button type="button" onClick={prevStep} className="text-white bg-primary hover:bg-[#003022] py-2 px-4 rounded-md">
              Previous
            </button>
          )}
          {currentStep < 2 ? (
            <button type="button" onClick={nextStep} className="text-white bg-[#003022] hover:bg-primary py-2 px-4 rounded-md">
              Next
            </button>
          ) : (
            <button type="submit" disabled={loading} className="text-white bg-[#003022] hover:bg-[#007f5f] py-2 px-4 rounded-md">
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default KYCForm;
