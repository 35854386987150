// FileUpload.js
import React, { useState } from 'react';
const ICON_PATH = "/icons/";

const FileUpload = ({ id, onFileChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      onFileChange(file);
    }
  };

  const handleClick = () => {
    document.getElementById(id).click();
  };

  return (
    <>
      <div htmlFor={id} onClick={handleClick} className="w-60 md:w-80 rounded-xl cursor-pointer border-dashed border-2 border-gray-200 bg-ash p-6 text-secondary flex justify-center items-center">
        <label className="flex items-center cursor-pointer">
          <span className="mr-2 text-md capitalize">Upload file</span>
          <img src={`${ICON_PATH}upload.svg`} alt="upload-icon" />
        </label>

        <input
          type="file"
          id={id}
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      {selectedFile && (
        <div className="text-secondary text-md">
          <span>{selectedFile.name}</span>
        </div>
      )}
    </>
  );
};

export default FileUpload;
