import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/auth/userSlice'; 
import { setWallet } from '../store/wallet/walletSlice'; 
import { setServer } from '../store/api-session/apiSlice'; 
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem("server", false);
    dispatch(setUser(user));
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('wallet');
    localStorage.removeItem('server');
    localStorage.removeItem('pinSet');
    dispatch(setUser({})); 
    dispatch(setServer({})); 
    dispatch(setWallet({})); 
    setIsLoggedIn(false);
    navigate("/")
  };

  const wallet = (walletDetails) => {
    dispatch(setWallet(walletDetails));
    localStorage.setItem('wallet', JSON.stringify(walletDetails));
  };


  return { isLoggedIn, login, logout, wallet };
};

export default useAuth;
