import React from 'react';

const PhoneField = ({ value, onChange, name, label }) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={label} className="mb-1 text-dark text-start text-sm font-medium">
        {label}
      </label>
      <div className="flex items-center space-x-0">
        <input
          type="tel"
          className="w-full bg-transparent border border-gray-300 py-3 px-2 outline-none rounded-lg focus:outline-none focus:bg-border-primary focus:border-gray-300"
          placeholder=""
          value={value}  // Make sure to keep value in sync with the prop
          onChange={onChange}
          name={name}
        />
      </div>
    </div>
  );
};


export default PhoneField;
