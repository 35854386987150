import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TfiAngleDown } from "react-icons/tfi";
import useAuth from "../../../../usehook/useAuth";
import { useSelector } from "react-redux";
import { authService } from "services/auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const UserDropDown = ({isUserDropdownOpen, toggleDropdown, dropdownRef}) => {
  const IMG_PATH = "/img/";
  const ICONS_PATH = "/icons/";
  const { logout } = useAuth();
  const [userData, setUserData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const businessName = useSelector((state) => state.user.businessName);
  const getProfile = () => {
    authService
      .getProfile()
      .then((result) => {
        if (result && result.data.success) {
          setUserData(result.data.business);
        } else toast.error(result.data.message);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response?.message ?? error.response?.data?.message ?? "Error fetching profile"
        );
      });
  };

  useEffect(()=>{
    getProfile()
  }, [])
  // const serverValue = useSelector((state) => state.server.value);
  // console.log(serverValue)
  // const [isDown, setIsDown] = useState(false);
  // const dropdownRef = useRef(null);
  // const closeDropdown = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setIsDown(false);
  //   }
  // };
  // const toggleDropdown = () => {
  //   setIsDown(!isDown);
  // };


  // useEffect(() => {
  //   document.addEventListener("click", closeDropdown);
  //   return () => {
  //     document.removeEventListener("click", closeDropdown);
  //   };
  // }, []);

  return (
    <div className="relative">
      <div className="w-full h-full px-3 py-2 border border-ash2 rounded-3xl flex">
        <div
          onClick={(e) => {
            e.stopPropagation();
            toggleDropdown();
          }}
          className="flex flex-row justify-center items-center space-x-2 cursor-pointer"
        >
          <div className="w-8 h-8 rounded-full cursor-pointer">
            <img src={`${IMG_PATH}/user.svg`} alt="user-logo" />
          </div>

          <div className="hidden md:block">
            <p className="font-semibold">{userData?.businessName}</p>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown();
            }}
          >
            <TfiAngleDown className="text-dark" />
          </div>
        </div>
      </div>
      {isUserDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute flex flex-col space-y-3 top-20 right-0 w-52 h-40 bg-white shadow-lg rounded-lg py-6 pb-3 px-4 z-50"
        >
          <ul className="flex flex-col space-y-4">
            <li className="">
              <Link to="/settings">
                <span className="flex flex-row space-x-4 cursor-pointer">
                  <span>
                    <img src={`${ICONS_PATH}userr.svg`} alt="/user-icon" />
                  </span>
                  <span className="text-md cursor-pointer">Profile</span>
                </span>
              </Link>
            </li>
            <li className="flex flex-row space-x-4 cursor-pointer">
            <Link to="/settings">
            <span className="flex flex-row space-x-4 cursor-pointer">
              <span>
                <img src={`${ICONS_PATH}security.svg`} alt="/security-icon" />
              </span>
              <span className="text-md cursor-pointer">Security
              </span>
              </span>
              </Link>
            </li>
            {/* <li className="flex flex-row space-x-4 cursor-pointer">
            <Link to="/api-keys">
            <span className="flex flex-row space-x-4 cursor-pointer">
              <span>
                <img src={`${ICONS_PATH}coin.svg`} alt="/coin-icon" />
              </span>
              <span className="text-md cursor-pointer">Account Limit
              </span>
              </span>
              </Link>
            </li> */}
            {/* <li className="flex flex-row space-x-4 cursor-pointer">
            <Link to="/api-keys">
            <span className="flex flex-row space-x-4 cursor-pointer">
              <span>
                <img src={`${ICONS_PATH}wallet-add.svg`} alt="/wallet-icon" />
              </span>
              <span className="text-md cursor-pointer">Add New Business
              </span>
              </span>
              </Link>
            </li> */}
            <li className="">
              <Link to="/api-keys">
                <span className="flex flex-row space-x-4 cursor-pointer">
                  <span>
                    <img src={`${ICONS_PATH}key.svg`} alt="/api-key-icon" />
                  </span>
                  <span className="text-md cursor-pointer">API Keys</span>
                </span>
              </Link>
            </li>
            {/* <li className="">
              <Link to="/webhook">
                <span className="flex flex-row space-x-4 cursor-pointer">
                  <span>
                    <img
                      src={`${ICONS_PATH}weebhook.svg`}
                      alt="/webhook-icon"
                    />
                  </span>
                  <span className="text-md cursor-pointer">Webhook Notification</span>
                </span>
              </Link>
            </li> */}

            <li onClick={logout} className="">
              <span className="flex flex-row space-x-4 cursor-pointer">
                <span>
                  <img src={`${ICONS_PATH}logout.svg`} alt="/logout-icon" />
                </span>
                <span className="text-md cursor-pointer">Logout</span>
              </span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserDropDown;
