import InputField from "../../../../components/controls/InputField";
import SelectField from "../../../../components/controls/SelectField";
import SubmitButton from "../../../../components/controls/SubmitButton";
import React from "react";
// import Util from "../../../../utils/util";
// import { toast } from "react-toastify"
// import 'react-toastify/dist/ReactToastify.css';
// import { authService } from '../../../../services/auth.service';
import ClipLoader from "react-spinners/ClipLoader";
const EmailForm = ({
  setTab,
  inputHandler,
  isProcessing,
  validation,
  input,
  setInput,
  sentMail,
  updateEmailValue,
}) => {
  //     const [input, setInput] = useState({
  //         email: "",
  //         businessRegistrationType:"",
  //     });
  //     const [validation] = useState({
  // 		validEmail: true,
  // 		validBusinessType: true,
  // 	});
  // 	const inputHandler = (e) => {
  // 		const name = e.target.name;
  //     const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
  //     setInput((prevInput) => ({ ...prevInput, [name]: capitalizedValue }));
  // 		Util.validate(validation, input, [name]);
  //     updateEmailValue(capitalizedValue);
  // 	};
  //  const [isProcessing, setIsProcessing] =  useState(false)

  //     const sentMail = () => {
  //         Util.validate(validation, input, ["email", "BusinessType"]);
  // 		if ( validation.validEmail && validation.validBusinessType) {
  //             setIsProcessing(true);
  //             const lowercasedInput = {
  //               email: input.email.toLowerCase(),
  //               businessRegistrationType:input.businessRegistrationType.toUpperCase(),
  //             };

  //             authService.register(lowercasedInput).then((result) => {
  //         if (result && result.data) {
  //             setTab(2)
  //             setIsProcessing(false);
  //           toast.success(result.data.message)
  //           localStorage.setItem("token", result.data.token)
  //         } else {
  //             toast.error(result.data.message)
  //         }
  //       }).catch((error)=>{
  //         toast.error(error.response.data.message);
  //       }).finally(()=>{
  //         setIsProcessing(false);
  //       })

  //     } else {
  //         toast.error("All fields are required!")
  //     }
  //     }
  return (
    <div className="w-full flex flex-col space-y-4 text-center py-5">
      <div>
        <InputField
          value={input.email}
          type="text"
          // icon={<YourIconComponent />}
          onChange={inputHandler}
          label="Business Email Address"
          name="email"
          error={validation.validEmail}
        />
      </div>
      <div className="w-full">
        <SelectField
          name="businessRegistrationType"
          id="businessRegistrationType"
          label="Register as a"
          value={input.businessRegistrationType}
          onChange={(e) => {
            setInput({ ...input, businessRegistrationType: e.target.value });
          }}
        >
          <option value="">Type</option>
          <option value="BN">Business Owner (Sole Proprietorship )</option>
          <option value="RC">Company (Registered Business) </option>
        </SelectField>
      </div>
      <div>
        <SubmitButton disabled={false} onClick={sentMail}>
          <span className="text-white font-semibold">
            {isProcessing ? (
              <ClipLoader color="#fff" size={30} data-testid="loader" />
            ) : (
              "Next"
            )}
          </span>
        </SubmitButton>
      </div>
    </div>
  );
};

export default EmailForm;
