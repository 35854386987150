import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import Form from "./forms/Form";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [tab, setTab] = useState(1);
  const navigate = useNavigate();
  const IMG_PATH = "/img/logos/";
  return (
    <div className="w-full bg-white h-screen text-center">
      <div className="header flex flex-col space-y-2">
        <div className="mx-5">
          <Form tab={tab} setTab={setTab} />
        </div>
        <div className="py-2  flex flex-row justify-center items-center space-x-2">
          <p className="text-sm text-secondary">Do you have an account?</p>

          <p
            onClick={() => navigate("/")}
            className="text-sm text-primary flex flex-row justify-center items-center space-x-2 cursor-pointer"
          >
            <span>Login</span>
            <span>
              <FaArrowRight />
            </span>
          </p>
        </div>
        <div className="flex flex-col justify-center items-center mx-auto px-6 py-4 space-y-4">
          <p className="text-md text-secondary text-center ">
            Product of
            <span className="text-md text-primary font-bold px-2">
            Bellbank Microfinance Bank
            </span>
          </p>
          <div className="flex flex-row justify-center items-start space-x-2">
            <img
              className="w-6 h-6"
              src={`${IMG_PATH}/cbn.svg`}
              alt="cbn-img"
            />
            <p className="text-md text-secondary text-center">Lincensed By</p>
            <p className="text-md text-primary text-center">
              Central Bank Of Nigeria
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
