// import React from 'react'
const IMG_PATH ='/img/logos/'
const Logo = () => {
  return (
    <div className="py-3">
        <img className="w-20 h-16" src={`${IMG_PATH}logo.png`} alt="logo"/>
    </div>
  )
}

export default Logo