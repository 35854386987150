import React, { useRef } from 'react';

const OtpField = ({ value, onChange }) => {
  const inputsRef = useRef(Array.from({ length: 5 }));

  const handleInputChange = (index, inputValue) => {
    const updatedValue = [...value];
    updatedValue[index] = inputValue;
    onChange(updatedValue.join(''));
    if (inputValue && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 5);
    const pastedValues = pastedData.split('').slice(0, 5);
    const updatedValue = Array.from({ length: 5 }, (_, index) => pastedValues[index] || '');
    onChange(updatedValue.join(''));
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      const updatedValue = [...value];
      updatedValue[index - 1] = '';
      onChange(updatedValue.join(''));
      inputsRef.current[index - 1].focus();
    }
  };

  const handleFocus = (index) => {
    if (!value[index]) {
      inputsRef.current[index].focus();
    }
  };
  return (
    <div className="flex justify-center items-center space-x-2">
     {Array.from({ length: 5 }, (_, index) => (
        <input
          key={index}
          ref={(el) => (inputsRef.current[index] = el)}
          type="text"
          maxLength={1}
          value={value[index] || ''}
          onChange={(e) => handleInputChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onFocus={() => handleFocus(index)}
          onPaste={handlePaste}
          className="w-10 h-10 text-center border border-gray-300 rounded-md focus:outline-none focus:border-primary"
        />
      ))}
    </div>
  );
};

export default OtpField;
