import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import MainLayout from "../../layouts/mainLayout";
import { useEffect } from 'react';

export default function ProtectedRoute({ children, path }) {
  
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // Check if the userToken exists and is valid (you should implement token validation)
  const isAuthenticated = !!token;

  useEffect(() => {
    if (path !== '/' && !isAuthenticated) {
      // Redirect to the login page if not authenticated, except for the root path
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, navigate, path]);

  // Render the children if authenticated or if the route is the root path
  return isAuthenticated || path === '/' ? <MainLayout>{children}</MainLayout> : null;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
};
