import Util from "../utils/util";
import { http } from "../utils/http";


const getServerType = () => localStorage.getItem("server");

const testUrl = "https://api.bellmonie.com/v1/business/sandbox/";
const liveUrl = "https://api.bellmonie.com/v1/business/";

const getBaseUrl = () => {
  const server = getServerType();
  return server === "true" ? liveUrl : testUrl;
};

let accountService = {
  getMyAccount: async () => {
    const url = `${getBaseUrl()}account/me`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  createIndividualAccount: async (body) => {
    try {
      const url = `${getBaseUrl()}account/clients/individual`;
      const response = await http.post(url, body, Util.getRegisterHeaders());

      // Check for successful response
      if (response && response.data) {
        return { success: true, data: response.data };
      } else {
        return {
          success: false,
          error: "Error while creating virtual account",
        };
      }
    } catch (error) {
      // Handle the error and return it
      if (error.response && error.response.data) {
        return { success: false, error: error.response.data.message };
      } else {
        return {
          success: false,
          error: "An unexpected error occurred",
        };
      }
    }
  },

  createCorporateAccount: async (body) => {
    try {
      const url = `${getBaseUrl()}account/clients/corporate`;
      const response = await http.post(url, body, Util.getRegisterHeaders());

      // Check for successful response
      if (response && response.data) {
        return { success: true, data: response.data };
      } else {
        return {
          success: false,
          error: "Error while creating virtual account",
        };
      }
    } catch (error) {
      // Handle the error and return it
      if (error.response && error.response.data) {
        return { success: false, error: error.response.data.message };
      } else {
        return {
          success: false,
          error: "An unexpected error occurred",
        };
      }
    }
  },
  getCorporateAccounts: async (page) => {
    const url = `${getBaseUrl()}account/clients?limit=${Util.limit}&page=${page}&accountType=corporate`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getIndividualAccounts: async (page) => {
    const url = `${getBaseUrl()}account/clients?limit=${Util.limit}&page=${page}&accountType=individual`;
    return await http.get(url, Util.getRegisterHeaders());
  },
};

export { accountService };
