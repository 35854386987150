import React, { useState, useEffect } from "react";
import Input from "../../../components/controls/Input";
import SubmitButton from "../../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authService } from "../../../services/auth.service";
import ClipLoader from "react-spinners/ClipLoader";
const Profile = () => {
    
  const [isProcessing, setIsProcessing] = useState(false);
  const [userData, setUserData] = useState({});

//   const inputHandler = (e) => {
//     const name = e.target.name;
//     setUserData({ ...userData, [name]: e.target.value });

//   };

   const getProfile = () => {
    authService
      .getProfile()
      .then((result) => {
        if (result && result.data.success) {
          setUserData(result.data.business);
        } else toast.error(result.data.message);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response?.message ?? error.response?.data?.message ?? "Error fetching profile"
        );
      });
  };
  useEffect(()=>{
    getProfile()
  }, [])
  const handleSubmit = async () => {
        try {
          const result = await authService.changePassword();
          if (result && result.data && result.data.success) {
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        } catch (error) {
          toast.error(
            error.response?.data?.message ?? error.response?.message ?? "Error updating password"
          );
        } finally {
          setIsProcessing(false);
        }
    
    
  };
  
  return (
    <div className="bg-white w-full p-6">
        <div className="flex flex-col space-y-4">
        <div>
                <Input
                  type="text"
                  name="fullname"
                  label="Full Name"
                  placeholder="Full Name"
                  value={userData?.User?.fullname}
                  // onChange={inputHandler}
                  disabled
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  value={userData?.User?.email}
                  // onChange={inputHandler}
                  disabled
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="phone"
                  label="Phone Number"
                  placeholder="Phone Number"
                  value={userData?.phone}
                  disabled
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="dateOfBirth"
                  label="DOB"
                  placeholder="DOB"
                  value={userData?.dateOfBirth}
                  disabled
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="gender"
                  label="Gender"
                  placeholder="Gender"
                  value={userData?.gender}
                  disabled
                />
              </div>
        <div>
                <Input
                  type="text"
                  name="businessName"
                  label="Business Name"
                  placeholder="Business Name"
                  value={userData?.businessName}
                  disabled
                  // onChange={inputHandler}
                />
              </div>

              <div>
                <Input
                  type="text"
                  name="businessEmail"
                  label="Business Email Address"
                  placeholder="Business Email Address"
                  value={userData?.businessEmail}
                  disabled
                  // onChange={inputHandler}
                />
              </div>
              <div className="flex flex-col md:flex-row space-x-3">
              <div>
                <Input
                  type="text"
                  name="lga"
                  label="LGA"
                  placeholder="LGA"
                  value={userData?.lga}
                  disabled
                  // onChange={inputHandler}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="state"
                  label="State"
                  placeholder="State"
                  value={userData?.state}
                  disabled
                  // onChange={inputHandler}
                />
              </div>
              </div>
              <div className="">
            <SubmitButton
             onClick={!isProcessing ? handleSubmit : null}
            >
             {isProcessing ? (
                                    <ClipLoader
                                        color="#fff"
                                        size={30}
                                        data-testid="loader"
                                    />
                                ) : (
              "Update"
              )}
            </SubmitButton>
          </div>
        </div>
    </div>
  )
}

export default Profile