import React, { useState } from "react";
import InputField from "../../../components/controls/InputField";
import SubmitButton from "../../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import { TbCurrencyNaira } from "react-icons/tb";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { mockService } from "../../../services/mockapi.service";
const FundAccount = () => {
  const [input, setInput] = useState({
    amount: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const inputHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);

    if (name === "amount") {
      value = value.replace(/[^0-9.]/g, "");
      const parts = value.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      value = parts.join(".");
    }

    setInput({ ...input, [name]: capitalizedValue });
  };
  const deposit = () => {
    setIsProcessing(true);
    mockService.creditAccount(input)
      .then((result) => {
        if (result && result.data && result.data.success) {
          toast.success(result.data.message);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data.message ?? error.message);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };
  return (
    <div className="flex flex-col space-y-4 py-4 px-8">
      <div>
        <InputField
          value={input.amount}
          type="text"
          label="Amount"
          name="amount"
          onChange={inputHandler}
          leftIcon={<TbCurrencyNaira size={20} />}
        />
      </div>
     
      <div className="my-2">
        <SubmitButton onClick={deposit}>
          <span className="text-white font-semibold">
            {isProcessing ? (
              <ClipLoader color="#fff" size={30} data-testid="loader" />
            ) : (
              "Fund"
            )}
          </span>
        </SubmitButton>
      </div>
    </div>
  )
}

export default FundAccount