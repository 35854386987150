import Util from "../utils/util";
import { http } from "../utils/http";

let uploadService = {
  uploadFile: async (formData) => {
    return await http.post(
      `${process.env.REACT_APP_LIVE_URL}upload`,
      formData,
      Util.getAuthorizedFileHeaders()
    );
  },
};

export { uploadService };
