import { storage } from "./storage";

const Util = {
  limit:10,
  pageSize: 20,
  baseUrl : 'https://api.bellmonie.com/v1/',
  // testUrl : 'https://sandbox-dev-api.bellmonie.com/v1/',
  testUrl : 'https://api.bellmonie.com/v1/',
  liveUrl : 'https://api.bellmonie.com/v1/',
  getHeaders() {
    return {
      headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${storage.get("token")}`,

    }
  };
  },
  getAuthorizedHeaders() {
    return {
      headers: {
      Authorization: `Bearer ${storage.get("token")}`,
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
    }};
  },
  getRegisterHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${storage.get("token")}`,
      },
    };
  },
  getAuthorizedFileHeaders() {
    return {
      headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: ``,
      // Authorization: `Bearer ${storage.get("token")}`,
      },
  };
},
  getFileHeaders() {
    return {
      headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    }};
  },
  onImageError(e) {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/no-image.png`;
  },
  capitalize(str) {
    if (typeof str !== "string" || !str) {
      return "";
    }
    return str[0].toUpperCase() + str.slice(1);
  },
  validate(validation, input, keys) {
    keys.forEach((key) => {
      let validationKey = `valid${Util.capitalize(key)}`;
      let value = input[key];

      if (value?.length < 3) validation[validationKey] = false;
      else validation[validationKey] = true;

      if (key === "phone") {
        if (!Util.validateMobile(value)) validation[validationKey] = false;
        else validation[validationKey] = true;
      }

      if (key === "businessEmail") {
        if (!Util.validateEmail(value)) validation[validationKey] = false;
        else validation[validationKey] = true;
      }

      if (key === "confirmPassword") {
        if (value !== input["password"]) validation[validationKey] = false;
        else validation[validationKey] = true;
      }
    });
  },
  validateEmail(businessEmail) {
    return String(businessEmail)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  validateMobile(phone) {
    return String(phone)
      .toLowerCase()
      .match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);
  },
  
};

export default Util;
