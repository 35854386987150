import React from 'react';
import { Link } from "react-router-dom";
// import DashboardBody from "./components/DashboardBody";
import DashboardHeader from "./components/DashboardHeader";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const kycStatus = useSelector((state) => state.user.kycStatus);
  return (
    <div className="">
      <div className="flex flex-col space-y-4 py-6">
        <div className="flex flex-col md:flex-row space-y-8 lg:justify-between py-4">
          <div>
            <h3 className="text-dark text-2xl font-bold capitalize">
              Dashboard
            </h3>
          </div>
          { kycStatus === "approved" ? null : <div className="w-full md:max-w-[500px] h-full px-4 py-2 bg-white rounded-lg">
            <p className="flex flex-col lg:flex-row text-md font-semibold text-dark">
              <span>Complete your onboarding Process by clicking</span>
              <span className="text-red px-2">
                <Link to="/onboarding">
                Complete your Kyc</Link></span>
            </p>
          </div>}
         
        </div>

        <div className="flex flex-col space-y-8">
          <DashboardHeader />
          {/* <DashboardBody /> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
