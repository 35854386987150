import React from 'react'
import { FaAngleLeft } from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import RegistrationTypeForm from './forms/RegistrationTypeForm';
const RegisterType = () => {
    const navigate = useNavigate()
    return (
      <div className="py-12 px-3 md:px-24   mx-auto">
         <div className="flex flex-row space-x-4 items-center">
              <div onClick={() => {navigate("/onboarding")}} className="bg-white h-8 w-8 border flex justify-center items-center border-ash rounded-full cursor-pointer">
              <FaAngleLeft size={15} className="text-secondary" />
              </div>
      <div className="flex flex-col space-y-3 py-4">
          <div>
            <h3 className="text-dark text-2xl font-bold capitalize">
            Business Type Details
            </h3>
          </div>
            <p className="text-md font-regular text-secondary">
            Enter your Business Type Details</p>
        </div>
        </div>
        <div className="max-w-[1000px] h-full flex justify-center items-center bg-white p-3 md:p-6">
          <div>
            <RegistrationTypeForm />
          </div>
  
        </div>
    </div>
    )
  }
  


export default RegisterType