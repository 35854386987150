import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { addMonths } from 'date-fns'; // Import addMonths from date-fns

const DatePickerField = ({ initialStartDate, initialEndDate, onDateRangeChange, maxPastYears = 50, maxFutureMonths = 12 }) => {

  const handleStartDateChange = (date) => {
    if (onDateRangeChange) {
      onDateRangeChange([date, initialEndDate]);
    }
  };

  const handleEndDateChange = (date) => {
    if (onDateRangeChange) {
      onDateRangeChange([initialStartDate, date]);
    }
  };

  const maxPastDate = new Date();
  maxPastDate.setFullYear(maxPastDate.getFullYear() - maxPastYears);

  return (
    <div className="w-full p-2">
      <div className="flex flex-col space-y-4 items-center">
        <div className="flex flex-col space-y-3">
          <label>Start Date</label>
          <DatePicker
            className="w-[290px] border rounded-lg p-3"
            selected={initialStartDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={initialStartDate}
            endDate={initialEndDate}
            dateFormat="MM/dd/yyyy"
            maxDate={addMonths(new Date(), maxFutureMonths)}
            minDate={maxPastDate}
          />
        </div>
        <div className="flex flex-col space-y-3">
          <label>End Date</label>
          <DatePicker
            className="w-[290px] border rounded-lg p-3"
            selected={initialEndDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={initialStartDate}
            endDate={initialEndDate}
            dateFormat="MM/dd/yyyy"
            maxDate={addMonths(new Date(), maxFutureMonths)}
            minDate={maxPastDate}
          />
        </div>
      </div>
    </div>
  );
};

DatePickerField.propTypes = {
  initialStartDate: PropTypes.instanceOf(Date),
  initialEndDate: PropTypes.instanceOf(Date),
  onDateRangeChange: PropTypes.func,
  maxPastYears: PropTypes.number,
  maxFutureMonths: PropTypes.number,
};

export default DatePickerField;
