// import React from "react";
import PropTypes from "prop-types";
const BorderButton = ({ value, onClick, bgColor, textColor, borderColor, disabled }) => {
  return (
    <>
      <button
        className={`w-32 h-12 border ${borderColor} rounded-lg px-3 cursor-pointer ${bgColor} ${textColor}`}
        onClick={onClick}
        disabled={disabled}
      >
        {value}
      </button>
    </>
  );
};

BorderButton.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  borderColor:PropTypes.string,
  textColor: PropTypes.string,
  disabled: PropTypes.bool, // Add the disabled prop type
};

BorderButton.defaultProps = {
  value: "",
  bgColor: "bg-transparent",
  borderColor:"border-ash3",
  textColor: "text-secondary",
  disabled: false, // Set a default value for the disabled prop if none is provided
};

export default BorderButton;
