import React, { useState, useEffect } from "react";
import DatePickerField from "../../../components/controls/DatePicker";
import SubmitButton from "../../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { transactionService } from "../../../services/transaction.service";
import * as XLSX from 'xlsx';
import Input from "../../../components/controls/Input"; // Import the Input component

const FilterByDate = ({ logout }) => {
  const maxFutureMonths = 12;

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [creditAccountNumber, setCreditAccountNumber] = useState(""); // State for account number
  const [data, setData] = useState([]); // State for storing fetched transactions

  const handleDateRangeChange = (dates) => {
    setDateRange({
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  const handleAccountNumberChange = (e) => {
    setCreditAccountNumber(e.target.value); // Update state when user types
  };

  // Fetch transactions based on date range and account number
  const getTransactionsByDateRange = async () => {
    try {
      setData([]); // Reset data before fetching new
      const result = await transactionService.getACollectionByDate(
        dateRange.startDate.getTime() / 1000,
        dateRange.endDate.getTime() / 1000,
        creditAccountNumber // Pass user-inputted account number
      );
      if (result && result.data && result.data.success === true) {
        setData(result.data.data); // Set fetched transactions to state
      } else {
        toast.error("Error fetching transactions.");
      }
    } catch (error) {
      console.error(error);
      setData([]); // Reset data if there's an error
      if (error.response && error.response.status === 401) {
        logout(); // Handle unauthorized access
      }
    }
  };

  // Export the data to an Excel file
  const exportToExcel = () => {
    const formattedData = data.map((item) => ({
      reference: item.reference,
      receiptNumber: item.receiptNumber,
      amount: item.amount,
      creditAccountNumber: item.creditAccountNumber,
      remarks: item.remarks,
      status: item.status,
      updatedAt: new Date(item.updatedAt * 1000).toLocaleDateString("en-GB"), // Format date
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData); // Convert JSON to sheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "transactions"); // Add sheet to workbook
    XLSX.writeFile(workbook, "transactions.xlsx"); // Export the Excel file
  };

  // Automatically export the Excel file when data is available
  useEffect(() => {
    if (data.length > 0) {
      exportToExcel();
      toast.success("Transaction statement downloaded.");
    }
  }, [data]);

  return (
    <div className="p-4">
      <div className="flex flex-col space-y-3">
        <h1 className="text-lg font-bold mb-2">Filter By Date</h1>

        {/* Date Picker for selecting date range */}
        <DatePickerField
          initialStartDate={dateRange.startDate}
          initialEndDate={dateRange.endDate}
          onDateRangeChange={handleDateRangeChange}
          maxFutureMonths={maxFutureMonths}
        />

        {/* Account Number Input */}
        <Input
          label="Enter Credit Account Number"
          placeholder="Enter Account Number"
          value={creditAccountNumber}
          onChange={handleAccountNumberChange}
          type="text"
          name="creditAccountNumber"
        />

        {/* Submit Button for fetching and exporting data */}
        <SubmitButton onClick={getTransactionsByDateRange}>
          Download
        </SubmitButton>
      </div>
    </div>
  );
};

export default FilterByDate;
