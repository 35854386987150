import Util from "../utils/util";
import { http } from "../utils/http";

let kycService = {
  getKycs: async () => {
    return await http.get(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/my-kyc`, Util.getRegisterHeaders());
  },

  addBusinessRegistrationType: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/business-registration-type`, body, Util.getRegisterHeaders());
  },

  addBusinessDocuments: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/documents`, body, Util.getRegisterHeaders());
  },

  addBusinessAddress: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/address`, body, Util.getRegisterHeaders());
  },

  addBusinessDirectors: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/directors`, body, Util.getRegisterHeaders());
  },

  updateDocument: async (id, body) => {
    return await http.put(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/documents/${id}`, body, Util.getRegisterHeaders());
  },

  updateBusinessAddress: async (body) => {
    return await http.put(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/address`, body, Util.getRegisterHeaders());
  },

  updateBusinessDirectors: async (id, body) => {
    return await http.put(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/directors/${id}`, body, Util.getRegisterHeaders());
  },

  deleteBusinessDirectors: async (id, body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/kyc/director/${id}`, body, Util.getRegisterHeaders());
  },

  submitKyc: async (body) => {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_LIVE_URL}auth/business/kyc/submit`,
        body,
        Util.getRegisterHeaders()
      );
      return response;
    } catch (error) {
      console.error('Error submitting KYC:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
  
};

export { kycService };
