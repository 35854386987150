import React, { useState } from 'react'
import OtpForm from './OtpForm';
import NewPassword from './NewPassword';
import PasswordSuccess from './PasswordSuccess';
import ForgotPassForm from './ForgotPassForm';

const Form  = ({tab =1, setTab}) => {
    
    const [subTitle, setSubTitle] = useState([
        "Enter your email address below",
        "Enter verification code sent to sample@gmail.com",
        "Enter your new password below",
        "Your account password has been successfully reset",
      ]);
      const setSubTitleBasedOnTab = () => {
        switch (tab) {
          case 1:
            setSubTitle([
              "Enter your email address below",
              "Enter verification code sent to sample@gmail.com",
              "Enter your new password below",
              "Your account password has been successfully reset",
            ]);
            break;
          // Add other cases if you want to update subTitle for other tab values
          default:
            break;
        }
      };
    
      // Update subTitle when tab changes
      React.useEffect(() => {
        setSubTitleBasedOnTab();
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [tab]);
      const [emailValue, setEmailValue] = useState(""); // State to store the email value

      const updateEmailValue = (email) => {
        setEmailValue(email); // Function to update the email value in the parent component
      };
            // console.log(emailValue)
  return (
    <div className="">
         <p className="text-secondary text-sm">{subTitle[tab - 1]}</p>

        {tab ===1 ?(
            <>
             <ForgotPassForm setTab={setTab} updateEmailValue={updateEmailValue}  />
            </>
        ):tab ===2?(
            <>
             <OtpForm setTab={setTab} email={emailValue} />
            </>
        ):tab===3?(
            <>
             <NewPassword setTab={setTab} />
            </>
        ):(
            <>
             <PasswordSuccess />
            </>
        )}
         
    </div>
  )
}

export default Form