import React from "react";
import SelectField from "../../../components/controls/SelectField";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FilterTransactions = ({handleStatusChange, selectedStatus}) => {
  return (
    <div className="">
    <div className="flex flex-col space-y-5">
        <div> <SelectField label="Filter By Status"  onChange={handleStatusChange}
              value={selectedStatus}>
              <option value="">All</option>
              <option value="successful">Successful</option>
              <option value="pending">Pending</option>
              <option value="failed">Failed</option>
              <option value="reversed">Reversed</option>
            </SelectField></div>
            <div> <SelectField label="Filter By Type"  onChange={handleStatusChange}
              value={selectedStatus}>
              <option value="">All</option>
              <option value="successful">Recieved</option>
              <option value="pending">Sent</option>
              <option value="failed">Reversal</option>
            </SelectField></div>
        </div>
    </div>
  )
}

export default FilterTransactions