import React, { useState } from "react";
import Util from "../../../../utils/util";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authService } from "../../../../services/auth.service";
import OtpForm from "./OtpForm";
import NewPassword from "./NewPassword";
import AccountSuccess from "./AccountSuccess";
import EmailForm from "./EmailForm";
import BusinessForm from "./BusinessForm";

const Form = ({ tab = 1, setTab }) => {
  const [title, setTitle] = useState([
    "Create Your Account",
    "Email Verification",
    "Business Details",
    "Create Password",
    "Account Created",
  ]);
  const setTitleBasedOnTab = () => {
    switch (tab) {
      case 1:
        setTitle([
          "Create Your Account",
          "Email Verification",
          "Business Details",
          "Create Password",
          "Account Created",
        ]);
        break;
      // Add other cases if you want to update subTitle for other tab values
      default:
        break;
    }
  };
  const [subTitle, setSubTitle] = useState([
    "Enter your email address below",
    "Enter verification code sent to your mail",
    "Enter your business details below",
    "Enter your new password below",
    "Your account has been successfully created, welcome to  BellBank Business.",
  ]);
  const setSubTitleBasedOnTab = () => {
    switch (tab) {
      case 1:
        setSubTitle([
          "Enter your email address below",
          "Enter verification code sent to your email",
          "Enter your business details below",
          "Enter your new password below",
          // "Your account password has been successfully reset",
          "Your account has been successfully created, welcome to  BellBank Business.",
        ]);
        break;
      // Add other cases if you want to update subTitle for other tab values
      default:
        break;
    }
  };

  // Update subTitle when tab changes
  React.useEffect(() => {
    setTitleBasedOnTab();
    setSubTitleBasedOnTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
  // const [emailValue, setEmailValue] = useState(""); // State to store the email value

  // const updateEmailValue = (email) => {
  //   setEmailValue(email); // Function to update the email value in the parent component
  // };
  // console.log(emailValue)
  const [input, setInput] = useState({
    email: "",
    businessRegistrationType: "",
  });
  const [validation] = useState({
    validEmail: true,
    validBusinessType: true,
  });
  const inputHandler = (e) => {
    const name = e.target.name;
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    setInput((prevInput) => ({ ...prevInput, [name]: capitalizedValue }));
    Util.validate(validation, input, [name]);
    // updateEmailValue(capitalizedValue);
  };
  const [isProcessing, setIsProcessing] = useState(false);

  const sentMail = () => {
    Util.validate(validation, input, ["email", "BusinessType"]);
    if (validation.validEmail && validation.validBusinessType) {
      setIsProcessing(true);
      const lowercasedInput = {
        email: input.email.toLowerCase(),
        businessRegistrationType: input.businessRegistrationType.toUpperCase(),
      };

      authService
        .register(lowercasedInput)
        .then((result) => {
          if (result && result.data) {
            setTab(2);
            setIsProcessing(false);
            toast.success(result.data.message);
            localStorage.setItem("token", result.data.token);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      toast.error("All fields are required!");
    }
  };
  return (
    <div className="">
      <h3 className="text-dark text-2xl font-bold">{title[tab - 1]}</h3>
      <p className="text-secondary text-sm">{subTitle[tab - 1]}</p>

      {tab === 1 ? (
        <>
          <EmailForm
            setTab={setTab}
            inputHandler={inputHandler}
            isProcessing={isProcessing}
            sentMail={sentMail}
            validation={validation}
            input={input}
            setInput={setInput}
            // updateEmailValue={updateEmailValue}
          />
        </>
      ) : tab === 2 ? (
        <>
          <OtpForm
            setTab={setTab}
            email={input.email}
            sentMail={sentMail}
            // email={emailValue}
          />
        </>
      ) : tab === 3 ? (
        <>
          <BusinessForm setTab={setTab} />
        </>
      ) : tab === 4 ? (
        <>
          <NewPassword setTab={setTab} />
        </>
      ) : (
        <>
          <AccountSuccess />
        </>
      )}
    </div>
  );
};

export default Form;
