import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "services/auth.service";

const Tab = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const checkKycStatus = () => {
    authService
      .getProfile()
      .then((result) => {
        if (result && result.data.success) {
          // No special errorCode handling required if result is successful
        }
      })
      .catch((error) => {
        const errorCode = error.response?.data?.errorCode; // Retrieve errorCode
        console.error("Error Code:", errorCode); // Log the error code

        if (errorCode === "KYC30") {
          setIsModalOpen(true); // Show modal for KYC30 status
        } else if (errorCode === "KYC32") {
          navigate("/kyc"); // Redirect for KYC32 status
        } else {
          console.error("Unexpected error code:", errorCode);
        }
      });
  };

  useEffect(() => {
    checkKycStatus();
  }, []);

  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col space-y-4 py-4" style={{ backgroundColor: '#003022' }}>
      <h1 className="text-2xl font-bold text-center text-white">Get Started with KYC Process</h1>
      <p className="text-center text-secondary text-white">
        Begin your KYC process by filling out the required information.
      </p>
      <button
        className="mt-4 px-6 py-3 bg-[#00BC74] text-white font-semibold rounded-lg hover:bg-[#007f5f]"
        onClick={() => navigate("/kyc")}
      >
        Get Started Process
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">KYC Under Review</h2>
            <p className="text-gray-600 mb-6">
              Your KYC application is currently under review. Please check back later for updates.
            </p>
            <button
              className="px-4 py-2 bg-[#003022] text-white rounded-md hover:bg-[#00BC74]"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tab;
