import OtpField from "../../components/controls/OtpField";
import { FaCircleCheck } from "react-icons/fa6";
import SubmitButton from "../../components/controls/SubmitButton";
import React, { useState, useEffect, useCallback } from "react";
import { authService } from "../../services/auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import useAuth from "../../usehook/useAuth";
import { useNavigate } from "react-router-dom";

const OtpForm = ({ onClose, email, reSend }) => {
  const [timeout, setTimeout] = useState(60);
  const [timeoutEnded, setTimeoutEnded] = useState(false);
  const [otpValue, setOtpValue] = useState(Array(5).fill(""));
  const [isProcessing, setIsProcessing] = useState(false);
  const [delayPassed, setDelayPassed] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (timeout > 0 && !timeoutEnded) {
      timer = setInterval(() => {
        setTimeout((prevTimeout) => prevTimeout - 1);
      }, 1000);
    } else if (timeout === 0 && !timeoutEnded) {
      setTimeoutEnded(true);
    }
    return () => clearInterval(timer);
  }, [timeout, timeoutEnded]);

  useEffect(() => {
    if (reSend) {
      setTimeout(60);
      setTimeoutEnded(false);
    }
  }, [reSend]);

  const handleResendClick = () => {
    setOtpValue(Array(5).fill(""));
    reSend();
    setTimeout(60);
    setTimeoutEnded(false);
  };

  const verifyOtp = useCallback(() => {
    const enteredOtp = otpValue.join("") || "123456";
    setIsProcessing(true);

    const request = { otp: enteredOtp };

    authService.verifyLoginOtp(request)
    .then((result) => {
      console.log("OTP Verification Result:", result);
      if (result && result.data && result.data.success) {
        console.log("Success message:", result.data.message);
        const info = result.data.data;
        const token = result.data.token;
        login(token, info);
        setIsProcessing(false);
        onClose();
        if (result.data.message === "Otp verified and you are logged in") {
          console.log("Navigating to /dashboard");
          navigate("/dashboard");
          toast.success(result.data.message);
        } else {
          console.log("Navigating to /kyc");
          navigate("/dashboard");
          toast.error(result.data.message);
        }
      } else if (result && result.data && result.data.success === false && result.data.message === "Transaction Pin not set!") {
        const info = result.data.data;
        const token = result.data.token;
        login(token, info);
        setIsProcessing(false);
        onClose();
        navigate("/dashboard");
        toast.error(result.data.message);
      } else {
        toast.error(result.data.message);
      }
    })
    .catch((error) => {
      console.error("Error during OTP verification:", error);
      if (error.response) {
        const errorCode = error.response.data.errorCode;
        if (errorCode === "KYC32") {
          navigate("/dashboard");
          toast.error(error.response.data.message ?? error.response.message);
        } else if (errorCode === "KYC30") {
          navigate("/dashboard");
          toast.error(error.response.data.message ?? error.response.message);
        } else {
          toast.error(error.response.data.message ?? error.message);
        }
      } else {
        toast.error(error.message);
      }
    })
    .finally(() => {
      setIsProcessing(false);
    });
  
  }, [otpValue, login, navigate, onClose]);

  useEffect(() => {
    if (delayPassed && otpValue.length === 5) {
      verifyOtp();
      setDelayPassed(false);
    }
  }, [delayPassed, otpValue, verifyOtp]);

  const handleOtpChange = (value) => {
    const updatedOtpValue = value.split("").slice(0, 5);
    setOtpValue(updatedOtpValue);

    setTimeout(() => {
      setDelayPassed(true);
    }, 3000);
  };

  return (
    <div className="w-full flex flex-col space-y-4 text-center py-5">
      <div className="flex flex-col space-y-2 text-start">
        <label
          htmlFor="otp"
          className="mb-1 text-secondary px-16 text-sm font-medium"
        >
          Enter OTP sent to your mail.
        </label>
        <OtpField value={otpValue} onChange={handleOtpChange} />
      </div>
      <div className="py-2 px-16">
        {timeout > 0 ? (
          <div className="w-full h-full flex flex-row space-x-2 p-3 rounded-md bg-lightSec">
            <div className="flex flex-row space-x-2">
              <FaCircleCheck size={25} className="text-green" />
              <div className="flex flex-row space-x-2">
                <p className="text-md text-dark">Code Sent -</p>
                <p className="text-md text-green">
                  {`${Math.floor(timeout / 60).toString().padStart(2, "0")}:${(timeout % 60).toString().padStart(2, "0")}`}
                </p>
              </div>
            </div>
          </div>
        ) : (
          timeoutEnded && (
            <div
              className="text-md text-green cursor-pointer"
              onClick={handleResendClick}
            >
              Resend Code
            </div>
          )
        )}
      </div>
      <div className="px-16">
        <SubmitButton disabled={isProcessing} onClick={verifyOtp}>
          <span className="text-white font-semibold">
            {isProcessing ? (
              <ClipLoader color="#fff" size={30} data-testid="loader" />
            ) : (
              "Login"
            )}
          </span>
        </SubmitButton>
      </div>
    </div>
  );
};

export default OtpForm;
