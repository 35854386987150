import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { accountService } from "../../../services/account.service";

const CorporateAccount = ({ onClose, getAccounts }) => {
  const [input, setInput] = useState({
    businessName: "",
    rcNumber: "",
    firstName: "",
    lastName: "",
    otherName: "", // This field is optional
    incorporationDate: "",
    phoneNumber: "",
    emailAddress: "",
    bvn: "",
    dateOfBirth: "",
    validityType: "permanent",
  });

  const [loading, setLoading] = useState(false);

  // Handle input changes
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to format date string to "YYYY-MM-DD"
  const formatDateString = (dateString) => {
    const parts = dateString.split("/");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month}-${day}`;
    }
    return dateString; // Return as is if unable to format
  };

  // Handle form submission
  const create = () => {
    const requiredFields = [
      "businessName",
      "rcNumber",
      "firstName",
      "lastName",
      "incorporationDate",
      "phoneNumber",
      "emailAddress",
      "bvn",
      "dateOfBirth",
    ];

    const emptyField = requiredFields.find((field) => input[field] === "");

    if (emptyField) {
      toast.error("All required fields must be filled!");
      return;
    }

    setLoading(true);

    const updatedInput = {
      ...input,
      incorporationDate: formatDateString(input.incorporationDate),
      dateOfBirth: formatDateString(input.dateOfBirth),
    };

    accountService
      .createCorporateAccount(updatedInput)
      .then((result) => {
        if (result && result.data.success) {
          toast.success("Virtual account created successfully");
          onClose();
          getAccounts(1); // Assuming this refreshes the account list
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data.message ?? "An error occurred.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-full px-5">
      <div className="">
        <p className="text-xl text-center font-semibold">Add New Corporate Virtual Account</p>
      </div>
      <div className="py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[
            { name: "businessName", label: "Business Name", type: "text" },
            { name: "rcNumber", label: "RC No", type: "text" },
            { name: "firstName", label: "Firstname", type: "text" },
            { name: "lastName", label: "Lastname", type: "text" },
            { name: "otherName", label: "Othername (Optional)", type: "text" }, // Updated label
            { name: "incorporationDate", label: "Incorporation Date", type: "date" },
            { name: "phoneNumber", label: "Phone Number", type: "text" },
            { name: "emailAddress", label: "Email Address", type: "email" },
            { name: "bvn", label: "BVN", type: "text" },
            { name: "dateOfBirth", label: "Date Of Birth", type: "date" },
          ].map((inputField) => (
            <div key={inputField.name}>
              <label className="block text-sm font-medium text-gray-700">
                {inputField.label}
              </label>
              <input
                type={inputField.type}
                name={inputField.name}
                value={input[inputField.name]}
                onChange={inputHandler}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder={inputField.label}
              />
            </div>
          ))}
        </div>
        <div className="mt-6">
          <button
            onClick={loading ? null : create}
            className="w-full py-2 px-4 bg-primary text-white font-semibold rounded-md hover:bg-secondary focus:outline-none"
          >
            {loading ? (
              <ClipLoader color="#fff" size={30} data-testid="loader" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CorporateAccount;
