import InputField from "../../../../components/controls/InputField";
import TextArea from "../../../../components/controls/TextArea";
import PhoneField from "../../../../components/controls/PhoneField";
import SelectField from "../../../../components/controls/SelectField";
import SubmitButton from "../../../../components/controls/SubmitButton";
import React, { useState } from "react";
import Util from "../../../../utils/util";
import States from "../../../../utils/state.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authService } from "../../../../services/auth.service";
import ClipLoader from "react-spinners/ClipLoader";
const BusinessForm = ({ setTab }) => {
  const [input, setInput] = useState({
    firstname: "",
    middlename: "",
    surname: "",
    phone: "",
    businessName: "",
    businessEmail: "",
    gender: "",
    businessCategory: "",
    bvn: "",
    nin: "", // New field for NIN
    dateOfBirth: "",
    estimatedDailyTransactionVolume: "", // New field
    estimatedDailyTransferVolume: "", // New field
    address: "",
    lga: "",
    state: "",
    country: "nigeria",
  });
  
  const [validation] = useState({
    validFirstName: true,
    validMiddleName: true,
    validSurName: true,
    validPhone: true,
    validBusinessName: true,
    validBusinessEmail: true,
    validBusinessBvn: true,
    validNin: true, // New validation field
    validBusinessDateOfBirth: true,
    validEstimatedDailyTransactionVolume: true, // New validation field
    validEstimatedDailyTransferVolume: true, // New validation field
    validAddress: true,
    validLga: true,
    validState: true,
  });
  
  const inputHandler = (e) => {
    const name = e.target.name;
    const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    setInput((prevInput) => ({ ...prevInput, [name]: capitalizedValue }));
    Util.validate(validation, input, [name]);
  };
  

  const [isProcessing, setIsProcessing] = useState(false);

  const sentMail = () => {
    Util.validate(validation, input, [
      "firstname",
      "middlename",
      "surname",
      "phone",
      "businessName",
      "businessEmail",
      "bvn",
      "nin", // New validation field
      "dateOfBirth",
      "estimatedDailyTransactionVolume", // New validation field
      "estimatedDailyTransferVolume", // New validation field
      "address",
      "lga",
      "state",
    ]);
  
    if (
      validation.validFirstName &&
      validation.validMiddleName &&
      validation.validSurName &&
      validation.validPhone &&
      validation.validBusinessName &&
      validation.validBusinessEmail &&
      validation.validBusinessBvn &&
      validation.validNin && // New validation
      validation.validBusinessDateOfBirth &&
      validation.validEstimatedDailyTransactionVolume && // New validation
      validation.validEstimatedDailyTransferVolume && // New validation
      validation.validAddress &&
      validation.validLga &&
      validation.validState
    ) {
      setIsProcessing(true);
  
      authService
        .completeProfile(input)
        .then((result) => {
          if (result && result.data) {
            setTab(4);
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      toast.error("All fields are required!");
    }
  };
  
  const [lgasOptions, setLgasOptions] = useState([]); 
  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    const selectedStateData = States.find(
      (item) => item.state === selectedState
    );

    setInput({
      ...input,
      state: selectedState,
      lga: "", // Reset lgas when state changes
    });

    if (selectedStateData) {
      // Update lgas for the selected state
      const lgasOptions = selectedStateData.lgas.map((lga) => (
        <option key={lga} value={lga}>
          {lga}
        </option>
      ));

      // Set lgas options for the second select input
      setLgasOptions(lgasOptions);
    }
  };

  const handleLgaChange = (event) => {
    setInput({
      ...input,
      lga: event.target.value,
    });
  };
  const [others, setOthers] = useState(false)
  const handleBusinessTypeChange = (event) => {
    const value = event.target.value;
    if (value === "Others") {
      setInput({ ...input, businessType: "", otherBusinessType: "" });
      setOthers(true)
    } else {
      setInput({ ...input, businessType: value, otherBusinessType: "" });
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-center">
      <div>
        <InputField
          value={input.firstname}
          type="text"
          label="Firstname"
          name="firstname"
          onChange={inputHandler}
        />
      </div>
      <div>
        <InputField
          value={input.middlename}
          type="text"
          label="Middlename"
          name="middlename"
          onChange={inputHandler}
        />
      </div>
      <div>
        <InputField
          value={input.surname}
          type="text"
          label="Surname"
          name="surname"
          onChange={inputHandler}
        />
      </div>

      <div className="">
        <PhoneField
          type="text"
          value={input.phone}
          label="Phone Number"
          onChange={inputHandler}
          name="phone"
        />
      </div>
      <div>
        <InputField
          value={input.businessName}
          type="text"
          label="Business Name"
          name="businessName"
          onChange={inputHandler}
        />
      </div>

      <div>
        <InputField
          value={input.businessEmail}
          type="text"
          onChange={inputHandler}
          label="Business Email Address"
          name="businessEmail"
        />
      </div>
      <div className="w-full">
        <SelectField
          name="gender"
          label="Gender"
          value={input.gender}
          onChange={(e)=>{
            setInput({...input, gender:e.target.value})
          }}
        >
          <option value="">Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </SelectField>
      </div>
      <div className="w-full">
        <SelectField
          name="businessCategory"
          label="Business Category"
          value={input.businessCategory}
          onChange={(e) => setInput({ ...input, businessCategory: e.target.value })}
        >
          <option value="">Select Business Category</option>
          <option value="IT">IT</option>
          <option value="Fintech">Fintech</option>
          <option value="E-Commerce">E-Commerce</option>
          <option value="Logistics & Mobility">Logistics & Mobility</option>
          <option value="Travel & Hospitality">Travel & Hospitality</option>
          <option value="Retail">Retail</option>
          <option value="Healthcare">Healthcare</option>
        </SelectField>
      </div>

      {others && (
          <InputField
          value={input.businessType}
          type="text"
          label="Other Business Type"
          name="businessType"
          onChange={handleBusinessTypeChange} 
        />
        )}
      <div>
        <InputField
          value={input.bvn}
          type="text"
          onChange={inputHandler}
          label="BVN"
          name="bvn"
        />
      </div>
      <div>
        <InputField
           value={input.dateOfBirth}
           type="date"
           label="Date Of Birth"
           name="dateOfBirth"
          onChange={inputHandler}
        />
      </div>
      <div>
        <InputField
          value={input.nin}
          type="text"
          onChange={inputHandler}
          label="NIN"
          name="nin"
        />
      </div>
      <div>
        <InputField
          value={input.estimatedDailyTransactionVolume}
          type="number"
          onChange={inputHandler}
          label="Estimated Daily Transaction Volume"
          name="estimatedDailyTransactionVolume"
        />
      </div>
      <div>
        <InputField
          value={input.estimatedDailyTransferVolume}
          type="number"
          onChange={inputHandler}
          label="Estimated Daily Transfer Volume"
          name="estimatedDailyTransferVolume"
        />
      </div>

      <div className="w-full">
        <SelectField
          name="state"
          label="State"
          value={input.state}
          onChange={handleStateChange}
        >
          <option value="">State</option>
          {States.map((item) => (
            <option key={item.state} value={item.state}>
              {item.state}
            </option>
          ))}
        </SelectField>
      </div>
      <div className="w-full">
        <SelectField
          name="lga"
          label="LGA"
          value={input.lga}
          onChange={handleLgaChange}
        >
          <option value="">LGA</option>
          {lgasOptions}
        </SelectField>
      </div>
      <div className="w-full md:col-span-2">
        <TextArea
          label="Address"
          id="Address"
          rows="2"
          onChange={(e) => {
            setInput({ ...input, address: e.target.value });
          }}
          value={input.address}
        />
      </div>

    
     

      <div className="md:col-span-2">
        <SubmitButton
          disabled={false}
          onClick={!isProcessing ? sentMail : null}
        >
          <span className="text-white font-semibold">
            {isProcessing ? (
              <ClipLoader color="#fff" size={30} data-testid="loader" />
            ) : (
              "Next"
            )}
          </span>
        </SubmitButton>
      </div>
    </div>
  );
};

export default BusinessForm;
