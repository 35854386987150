import React, {useState, useEffect} from 'react'
import { toast } from "react-toastify";
import Loading from "../../../components/loader/Loading";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { transactionService } from "../../../services/transaction.service";
const CollectionDetails = () => {
    const [transData, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const {reference} = useParams();
    const getTransactions = async () => {
        try {
          setIsLoading(true);
          const result = await transactionService.getTransactionsByReference(reference);
          if (result && result.data && result.data.success === true) {
            setData(result.data.data);
            setIsLoading(false);
          } else {
            toast.error('Error fetching transaction..');
            setData([]);
          }
        } catch (error) {
          console.error('Error fetching transaction:', error);
          setData({});
        } finally {
          setIsLoading(false);
        }
      };
    
      useEffect(() => {
        if (reference) {
          // Ensure reference is not undefined
          getTransactions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [reference]);
      const getStatusColor = (status) => {
        if (transData.status && typeof transData.status === 'string') {
            switch (transData.status.toLowerCase()) {
                case 'settled':
                case 'successful':
                    return 'text-green';
                case 'pending':
                    return 'text-amber-500';
                case 'failed':
                    return 'text-red';
                default:
                    return 'text-gray-700'; // Default color if status is not recognized
            }
        } else {
            return 'text-gray-700'; // Default color if status is not provided or not a string
        }
    };
    const updatedDate = new Date(transData.transactionDate * 1000);
    const formattedDate = updatedDate.toLocaleString();

  return (
    <div className="flex flex-col space-y-4 items-center justify-center my-20 px-6 md:px-24 mx-auto">
        
        <div className=" w-[800px] bg-white h-full rounded-md p-6">
<div className="flex flex-col space-y-4">
<h3 className="text-xl font-bold">Collection Details</h3>
{isLoading ? (
    <>
    <Loading />
    </>
  
):   <>
<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
             <div className="flex flex-col space-y-3">
                 <p className="text-gray-400 text-sm">Transaction ID</p>
                 <p className="text-gray-700 text-lg font-medium">{transData.reference}</p>
             </div>
             <div className="flex flex-col space-y-3">
                 <p className="text-gray-400 text-sm">Session ID</p>
                 <p className="text-gray-700 text-lg font-medium">{transData?.sessionId}</p>
             </div>
             <div className="flex flex-col space-y-3">
                 <p className="text-gray-400 text-sm">Amount</p>
                 <p className="text-gray-700 text-lg font-medium">₦ {transData?.amount}</p>
             </div>
             {/* <div className="flex flex-col space-y-3">
                 <p className="text-gray-400 text-sm">Virtual Account</p>
                 <p className="text-gray-700 text-lg font-medium">{transData?.details.virtualAccount}</p>
             </div> */}
             {/* <div className="flex flex-col space-y-3">
                 <p className="text-gray-400 text-sm">Account Number</p>
                 <p className="text-gray-700 text-lg font-medium">{transData?.details.accountNumber}</p>
             </div> */}
             <div className="flex flex-col space-y-3">
                 <p className="text-gray-400 text-sm">Remarks</p>
                 <p className="text-gray-700 text-lg font-medium">{transData?.description}</p>
             </div>
             <div className="flex flex-col space-y-3">
                 <p className="text-gray-400 text-sm">Transaction Response</p>
                 <p className={`text-lg font-medium ${getStatusColor(transData.status)}`}>{transData.status}</p>
             </div>
             <div className="flex flex-col space-y-3">
                 <p className="text-gray-400 text-sm">Time and Date</p>
                 <p className="text-gray-700 text-lg font-medium">{formattedDate}</p>
             </div>
         </div>
</>}
</div>
        </div>
    </div>
  )
}

export default CollectionDetails