import InputField from "../../../components/controls/InputField";
import SubmitButton from "../../../components/controls/SubmitButton";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { authService } from "services/auth.service";
const Form = ({ close }) => {
  const [input, setInput] = useState({
    pin: "",
    confirmPin: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const inputHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "").slice(0, 4);

    setInput({ ...input, [name]: value });
  };
  const transactionPin = () => {
    if (input.pin === input.confirmPin) {
      setIsProcessing(true);
      const req = {
        pin: input.pin,
      };
      authService
        .setPin(req)
        .then((result) => {
          if (result && result.data && result.data.success) {
            setTimeout(() => {
              close();
                  let pinSet = true;
               localStorage.setItem("pinSet", pinSet);
            }, 300);

            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data.message ?? error.message);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      toast.error("Transaction pin mismatch!");
    }
  };

  return (
    <div>
      <div className="flex flex-col space-y-4 py-4 px-4">
        <div>
          <InputField
            value={input.pin}
            type="text"
            label="Pin"
            name="pin"
            onChange={inputHandler}
          />
        </div>

        <div>
          <InputField
            value={input.confirmPin}
            type="text"
            label="Confirm Pin"
            name="confirmPin"
            onChange={inputHandler}
          />
        </div>

        <div className="my-2">
          <SubmitButton onClick={transactionPin}>
            <span className="text-white font-semibold">
              {isProcessing ? (
                <ClipLoader color="#fff" size={30} data-testid="loader" />
              ) : (
                "Submit"
              )}
            </span>
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default Form;
