import Util from "../utils/util";
import { http } from "../utils/http";

const server = localStorage.getItem("server");


let url = "";


if (server === "true") {

  url = process.env.REACT_APP_LIVE_URL;
} else {

  url = process.env.REACT_APP_TEST_URL;
}

let webhookService = {
  addWebhook: async (body) => {
    return await http.put(`${url}business/account/webhook`, body,
      Util.getRegisterHeaders(),
    );
  },
  getWebhook: async () => {
    return await http.get(`${url}business/account/webhook`,
     Util.getRegisterHeaders(),
    );
  },
  getWebhookNotification: async (page) => {
    return await http.get(`${url}business/transactions/notifications?limit=${Util.limit}&page=${page}`,
     Util.getRegisterHeaders(),
    );
  },
  getWebhookNotificationByStatus: async (status, page) => {
    return await http.get(`${url}business/transactions/notifications?limit=${Util.limit}&page=${page}&status=${status}`,
     Util.getRegisterHeaders(),
    );
  },
  getWebhookNotificationByReference: async (ref) => {
    return await http.get(`${url}business/transactions/notifications?reference=${ref}`,
     Util.getRegisterHeaders(),
    );
  },
  repush: async (id) => {
    return await http.post(`${url}business/transactions/notifications/${id}`, {},
     Util.getRegisterHeaders(),
    );
  },
};

export { webhookService };

