import React, { useState } from "react";
import Deposit from "../forms/Deposit";
import FundAccount from "../forms/FundAccount";

const Tabs = () => {
  const [tab, setTab] = useState(1);

  const changeTab = (t) => {
    setTab(t);
  };
  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row space-x-4 justify-between items-center">
          <div
            onClick={() => {
              changeTab(1);
            }}
            className={`${
              tab === 1
                ? "text-primary border-b-2 border-primary"
                : "text-secondary"
            } font-bold cursor-pointer`}
          >
            <p className="text-md">Deposit to wallet</p>
          </div>
          <div
            onClick={() => {
              changeTab(2);
            }}
            className={`${
              tab === 2
                ? "text-primary border-b-2 border-primary"
                : "text-secondary"
            } font-bold cursor-pointer`}
          >
            <p className="text-md">Fund Client Account</p>
          </div>
        </div>
        {tab === 1 ? <FundAccount /> :  <Deposit />}
      </div>
    </>
  );
};

export default Tabs;
