import OtpField from '../../../components/controls/OtpField';
import { FaCircleCheck } from "react-icons/fa6";
import SubmitButton from '../../../components/controls/SubmitButton';
import React, { useState, useEffect} from 'react'
import { transferService } from "../../../services/transfer.service";
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";

const OtpForm = ({token, onClose, reSend}) => {
  const [timeout, setTimeout] = useState(60);
  const [timeoutEnded, setTimeoutEnded] = useState(false);

  useEffect(() => {
    let timer;

    if (timeout > 0 && !timeoutEnded) {
      timer = setInterval(() => {
        setTimeout((prevTimeout) => prevTimeout - 1);
      }, 1000);
    } else if (timeout === 0 && !timeoutEnded) {
      setTimeoutEnded(true);
    }

    return () => clearInterval(timer);
  }, [timeout, timeoutEnded]);

  useEffect(() => {
    // Reset timeout and set timeoutEnded to false when reSend is triggered
    if (reSend) {
      setTimeout(60);
      setTimeoutEnded(false);
    }
  }, [reSend]);

  const handleResendClick = () => {
    reSend(); // Call the provided reSend function
    setTimeout(60);
    setTimeoutEnded(false);
  };

  const handleTimeoutEnd = () => {
    setTimeoutEnded(true);
  };
    const [otpValue, setOtpValue] = useState(Array(5).fill(''));
  
    const [isProcessing, setIsProcessing] =  useState(false)

    const verifyOtp = () => {
      const enteredOtp = otpValue.join('');
      setIsProcessing(true);
     const request = {
      otp:enteredOtp,
      token:token
     }
      transferService.transferApproved(request).then((result) =>{
        if (result && result.data && result.data.success) {
            setIsProcessing(false);
            onClose()
          toast.success(result.data.message)
        } else {
            toast.error(result.data.message)
        }
      }).catch((error)=>{
        toast.error(error.response?.data.message ?? error.message);
      }).finally(() =>{
        setIsProcessing(false);
      })
    };
    const [delayPassed, setDelayPassed]= useState(false)
  const handleOtpChange = (value) => {
    const updatedOtpValue = value.split("").slice(0, 5);
    setOtpValue(updatedOtpValue);
  
    setTimeout(() => {
      setDelayPassed(true);
    }, 3000);
  };
  
  useEffect(() => {
    if (delayPassed && otpValue.length === 5) {
      verifyOtp();
      setDelayPassed(false); // Reset the state for the next change
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayPassed, otpValue]);
  return (
    <>
     <div className="w-full flex flex-col space-y-4 text-center py-5">
      <div className="flex flex-col space-y-2 text-start">
        <label htmlFor="name" className="mb-1 text-secondary px-16 text-sm font-medium">
        Enter OTP sent to your mail to make pay out
      </label>
      <OtpField value={otpValue} onChange={handleOtpChange} />
      </div>
      <div className=" py-2 px-16">
      {timeout > 0 ? (
      <div className="w-full h-full flex flex-row space-x-2 p-3 rounded-md bg-lightSec">
            <div className="flex flex-row space-x-2">
              <FaCircleCheck size={25} className="text-green" />
              <div className="flex flex-row space-x-2">
                <p className="text-md text-dark">Code Sent -</p>
                <p className="text-md text-green">{`${Math.floor(timeout / 60)
                  .toString()
                  .padStart(2, '0')}:${(timeout % 60)
                  .toString()
                  .padStart(2, '0')}`}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-md text-green cursor-pointer" onClick={timeoutEnded ? handleResendClick : handleTimeoutEnd}>
             {timeoutEnded ? 'Resend Code' : 'Timeout Ended'}
          </div>
        )}
      </div>
      <div className="px-16">
                <SubmitButton disabled={false} onClick={verifyOtp}>
                <span className="text-white font-semibold">{isProcessing ? (
                                <ClipLoader
                                  color="#fff"
                                  size={30}
                                  data-testid="loader"
                                />

                              ) : (
                                "Pay"
                              )}</span>
                </SubmitButton>
            </div>
            
    </div>
        </>
  )
}

export default OtpForm