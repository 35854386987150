import { createSlice } from '@reduxjs/toolkit';

export const initialState = {}; 

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, action) => {
      return { ...state, wallet: action.payload };
    },
  },
});

export const { setWallet } = walletSlice.actions;

export default walletSlice.reducer;
