import React, { useState } from 'react'
import { BsMoonStars, BsStars } from "react-icons/bs";
const Theme = () => {
    const [theme, setTheme] = useState(1)
    const changeTheme = (t) => {
        setTheme(t)
    }
    return (
        <div className="w-full h-full bg-lig rounded-3xl py-2 px-2 flex flex-row space-x-5 items-center">
            <div onClick={() => changeTheme(1)} className={theme === 1 ? "w-full h-full  flex flex-row space-x-3 items-center py-2 px-3 bg-white rounded-2xl cursor-pointer" : "w-full h-full  flex flex-row space-x-3 items-center py-2 px-3 bg-transparent rounded-2xl cursor-pointer"}>
                <div className="">
                    <BsStars size={15} className="text-dark" />
                </div>
                <div className="hidden md:block">
                    <p className="text-dark text-sm">Light</p>
                </div>
            </div>
            <div onClick={() => changeTheme(2)} className={theme === 2 ? "w-full h-full  flex flex-row space-x-3 items-center py-2 px-3 bg-white rounded-2xl cursor-pointer" : "w-full h-full  flex flex-row space-x-3 items-center py-2 px-3 bg-transparent rounded-2xl cursor-pointer"}>
                <div className="">
                    <BsMoonStars size={15} className="text-dark" />
                </div>
                <div className="hidden md:block">
                    <p className="text-dark text-sm">Dark</p>
                </div>
            </div>
        </div>
    )
}

export default Theme