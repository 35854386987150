import React, {useState} from 'react'
import InputField from "../../../components/controls/InputField";
import SubmitButton from "../../../components/controls/SubmitButton";
import FileUpload from "../../../components/controls/FileUpload";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { kycService } from "../../../services/kyc.service";
import { uploadService } from "../../../services/fileUpload.service";
import ClipLoader from "react-spinners/ClipLoader";
import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const RegistrationTypeForm = () => {
  const navigate = useNavigate()
  const kycType = useSelector((state) => state.user.kycType); 
    const [input, setInput] = useState({
        type:kycType,
        number:"",
        document:""
    });
    const [uploadedFile, setUploadedFile] = useState(null);
    const [loading, setLoading] = useState(false);
   
    const inputHandler = (e) => {
      const name = e.target.name;
      const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    
      setInput((prevInput) => ({
        ...prevInput,
          [name]: capitalizedValue
      }));
    };
   
    const handleFileChange = (file) => {
      setLoading(true)
      setUploadedFile(file);
      updateInputFile("Certificate of incorporation", file, setLoading);
    };

    const handleReplaceClick = (file) => {
        file.preventDefault();
        document.getElementById("fileInput").click();
        setUploadedFile(file);
        updateInputFile("Certificate of incorporation", file);
      };
      const updateInputFile = async (name, selectedFile, setLoading) => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
            const uploadResponse = await uploadService.uploadFile(formData);
            
            if (
                uploadResponse.data &&
                uploadResponse.data.success &&
                uploadResponse.data.data
            ) {
                const fileURL = uploadResponse.data.data.url;
                toast.success("File uploaded successfully..");
                setInput(prevInput => ({
                    ...prevInput,
                    document: fileURL
                }));
            } else {
                toast.error(uploadResponse.data.message);
            }
        } catch (error) {
            toast.error(error.response?.data.message ?? error.message);
        } finally {
            setLoading(false); // Reset loading indicator
        }
    };
    
      const [isProcessing, setIsProcessing] = useState(false);
  const Submit = async () => {
    // if (Object.values(input.data).some(value => value === '') || input.documents.length !== 2) {
    //   toast.error("All fields are required!");
    //   return;
    // }
  
    setIsProcessing(true);
    try {
      const result = await kycService.addBusinessRegistrationType(input);
  
      if (result.data && result.data.data && result.data.success) {
        setTimeout(() => {
          setIsProcessing(false);
          navigate("/onboarding")
        }, 3000);
        toast.success(result.data.message);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data.message ?? error.message);
    } finally {
      setIsProcessing(false);
    }
  };
  
  return (
    <div className="flex flex-col space-y-4 p-4">
         <div>
              <InputField
                value={input.number}
                type="text"
                label="Business Registration Number RC"
                name="number"
                onChange={inputHandler}
              />
            </div>
            <div className="flex flex-col mx-auto space-y-2">
              <div className="flex flex-row justify-between">
                <h3 className="text-dark font-semibold text-start">
                  Upload Business Document
                </h3>
                {uploadedFile && (
                  <div className="text-right">
                    <p
                      className="text-primary cursor-pointer"
                      onClick={handleReplaceClick}
                    >
                      Replace
                    </p>
                  </div>
                )}
              </div>
              <FileUpload id="fileInput" onFileChange={handleFileChange} />
              {loading && <p className="text-primary">Uploading...</p>}
      
            </div>
            <div className="">
            <SubmitButton
  onClick={!isProcessing ? Submit : null}
  disabled={isProcessing || !input.document}
>
  {isProcessing ? (
    <ClipLoader color="#fff" size={30} data-testid="loader" />
  ) : (
    "Submit"
  )}
</SubmitButton>

            </div>
    </div>
  )
}

export default RegistrationTypeForm