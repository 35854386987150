import React, { useState, useMemo, useRef, useEffect } from "react";
import Table from "../../../components/Table/Table";
import TableOption from "../../../components/modal/TableOption";
import { SlOptionsVertical } from "react-icons/sl";
import { FiSearch } from "react-icons/fi";
import InputField from "../../../components/controls/InputField";
import SelectField from "../../../components/controls/SelectField";
// import Requery from "../../components/requery/Requery";
// import Dispute from "../../components/dispute/Dispute";
// import RightDrawer from "../../../components/drawer/RightDrawer";
import ExportButton from "../../../components/controls/ExportButton";
import { toast } from "react-toastify";
import Loading from "../../../components/loader/Loading";
import "react-toastify/dist/ReactToastify.css";
import { transactionService } from "../../../services/transaction.service";
import useAuth from "../../../usehook/useAuth";
import { Link } from "react-router-dom";
import { FiCopy } from "react-icons/fi";
import FilterByDate from "./FilterByDate";
const OutwardTable = () => {
  const { logout } = useAuth();
  const [transData, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getTransactions = async () => {
    try {
      setIsLoading(true);
      const result = await transactionService.getOutwardTransactions(
        currentPage
      );
      if (result && result.data && result.data.success === true) {
        setData(result.data.data);
        // alert(JSON.stringify(result.data.data))
        setTotalPages(result.data.pagination.pages);
        setIsLoading(false);
      } else {
        toast.error("Error fetching transaction..");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        logout();
      }
      setData([]);
      setTotalPages(0);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTransactions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageChange = ({ nextPage }) => {
    setCurrentPage(nextPage);
    getTransactions(nextPage);
  };
  const getTransactionsByStatus = async (status) => {
    try {
      setIsLoading(true);
      const result = await transactionService.getOutwardTransactionsByStatus(
        status,
        currentPage
      );
      if (result && result.data && result.data.success === true) {
        setData(result.data.data);
        // alert(JSON.stringify(result.data.data))
        setTotalPages(result.data.pagination.pages);
        setIsLoading(false);
      } else {
        toast.error("Error fetching transaction..");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        logout();
      }
      setData([]);
      setTotalPages(0);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const getTransactionsByRef = async (value) => {
    try {
      setIsLoading(true);
      const result = await transactionService.getTransactionsByReference(value);
      if (result && result.data && result.data.success === true && result.data.data) {
        setData([result.data.data]);
      
      } else {
        toast.error("Error fetching transactions.");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        logout();
      }
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };
  
  const [selectedStatus, setSelectedStatus] = useState('');
const [ref, setRef] = useState('')
  const handleStatusChange = (event) => {
    const status = event.target.value;
    setSelectedStatus(status);
    getTransactionsByStatus(status);
  };
  const handleReferenceChange = (event) => {
    const value = event.target.value;
    setRef(value);
    if (value === '') {
      // If the search value is empty, fetch all transactions
      getTransactions();
    } else {
      // Otherwise, fetch transactions by reference
      getTransactionsByRef(value);
    }
  };
  
  // const [isReQueryDrawerOpen, setIsReQueryDrawerOpen] = useState(false);

  // const [transId, setTransId] = useState(null);
  // const [sessionId, setSessionId] = useState(null);
  // const [amount, setAmount] = useState(null);
  // const [details, setDetails] = useState(null);
  // const [status, setStatus] = useState(null);
  // const [date, setDate] = useState(null);
  // const handleDrawerReQueryOpen = (
  //   reference,
  //   sessionId,
  //   amount,
  //   status,
  //   details,
  //   updatedAt
  // ) => {
  //   setTransId(reference);
  //   setSessionId(sessionId);
  //   setAmount(amount);
  //   setDetails(details);
  //   setStatus(status);
  //   setDate(updatedAt);
  //   setIsReQueryDrawerOpen(true);
  // };
  // const handleReQueryDrawerClose = () => {
  //   setIsReQueryDrawerOpen(false);
  // };
  //   const [isDisputeDrawerOpen, setIsDisputeDrawerOpen] = useState(false);
  // const handleDrawerDisputeOpen = () => {
  //   setIsDisputeDrawerOpen(true);
  // };
  // const handleDisputeDrawerClose = () => {
  //   setIsDisputeDrawerOpen(false);
  // };

  const [copiedValue, setCopiedValue] = useState(null);

  const handleCopy = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopiedValue(value);
       ;
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard", error);
      });
  };
  const columns = useMemo(() => {
    return [
      {
        Header: "Reference",
        accessor: "reference",
        Cell: ({ value }) => {
          // Check if the value is present and has a length greater than 8
          if (value && value.length > 8) {
            const firstFourLetters = value.substring(0, 4);
            const lastFourLetters = value.substring(value.length - 4);

            const modifiedValue = `${firstFourLetters}...${lastFourLetters}`;

            return (
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => handleCopy(value)}
              >
                {modifiedValue}
                <FiCopy
                  size={16}
                  className="ml-2"
                  onClick={() => handleCopy(value)}
                />
              </span>
            );
          } else {
            // Handle the case where the value is not present or too short
            return value;
          }
        },
      },
      {
        Header: "Session Id",
        accessor: "sessionId",
        Cell: ({ value }) => {
          // Check if the value is present and has a length greater than 8
          if (value && value.length > 8) {
            const firstFourLetters = value.substring(0, 4);
            const lastFourLetters = value.substring(value.length - 4);

            const modifiedValue = `${firstFourLetters}...${lastFourLetters}`;

            return (
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => handleCopy(value)}
              >
                {modifiedValue}
                <FiCopy
                  size={16}
                  className="ml-2"
                  onClick={() => handleCopy(value)}
                />
              </span>
            );
          } else {
            // Handle the case where the value is not present or too short
            return value;
          }
        },
      },

      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => {
          const formattedAmount = new Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currencyDisplay: "symbol",
          }).format(value);

          return (
            <>
              <div>{formattedAmount}</div>
            </>
          );
        },
      },
      {
        Header: "Processing fee",
        accessor: "charge",
        Cell: ({ value }) => {
          const formattedAmount = new Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currencyDisplay: "symbol",
          }).format(value);

          return (
            <>
              <div>{formattedAmount}</div>
            </>
          );
        },
      },
      {
        Header: "Net Amount",
        accessor: "netAmount",
        Cell: ({ value }) => {
          const formattedAmount = new Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currencyDisplay: "symbol",
          }).format(value);

          return (
            <>
              <div>{formattedAmount}</div>
            </>
          );
        },
      },
      {
        Header: "Response",
        accessor: "status",
        Cell: ({ row }) => {
          const status = row.original.status;
          let statusText, statusColor, borderColor, bgColor;

          if (status === "successful") {
            statusText = "Successful";
            statusColor = "text-green";
            borderColor = "border-green";
            bgColor = "bg-green/25";
          } else if (status === "pending") {
            statusText = "Pending";
            statusColor = "text-amber-600";
            borderColor = "border-amber-300";
            bgColor = "bg-amber-50";
          } else if (status === "paid") {
            statusText = "Paid";
            statusColor = "text-red";
            borderColor = "border-red";
            bgColor = "bg-red/25";
          } else if (status === "initiated") {
            statusText = "initiated";
            statusColor = "text-amber-600";
            borderColor = "border-amber-300";
            bgColor = "bg-amber-50";
          } else {
            statusText = "Failed";
            statusColor = "text-red";
            borderColor = "border-red";
            bgColor = "bg-red/25";
          }
          return (
            <div
              className={`flex flex-row space-x-4 justify-center items-center w-20 border rounded-md p-2 ${bgColor}  ${borderColor} `}
            >
              <span className={`text-center ${statusColor}`}>{statusText}</span>
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: ({ value }) => {
          const date = new Date(value * 1000); // Convert Unix timestamp to milliseconds
          const formattedDate = date.toLocaleString(); // Format the date as a string
          return <div>{formattedDate}</div>;
        },
      },
      {
        Header: "Action",
        accessor: "menu",
        Cell: ({ row }) => {
          return (
            <div>
              <TableOption
                index={0}
                icon={<SlOptionsVertical size={20} className="text-primary" />}
              >
                <ul className="flex flex-col space-y-1 py-2 text-start capitalize">
                  <li className="">
                    <Link to={`/transaction/${row.original.reference}`}>
                    <button
                      // onClick={() => {
                      //   // alert(JSON.stringify(row.original.details))
                      //   handleDrawerReQueryOpen(
                      //     row.original.reference,
                      //     row.original.sessionId,
                      //     row.original.amount,
                      //     row.original.status,
                      //     row.original.details,
                      //     row.original.updatedAt
                      //   );
                      // }}
                      className="w-full p-2 text-start hover:bg-primary hover:text-white"
                    >
                      Query
                    </button>
                    </Link>
                  </li>
                  {/* <li>
                    <button className="w-full p-2 text-start hover:bg-primary hover:text-white">
                      Re Push
                    </button>
                  </li> */}
                  {/* <li>
                    <button
                      className="w-full p-2 text-start hover:bg-primary hover:text-white"
                      onClick={() => handleDrawerDisputeOpen()}
                    >
                      Raise a dispute
                    </button>
                  </li> */}
                </ul>
              </TableOption>
            </div>
          );
        },
      },
    ];
  }, []);
  const [isModalUOpen, setIsModalUOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleUOpen = () => {
    setIsModalUOpen(!isModalUOpen);
  };


  return (
    <div className="p-3">
      <div> {copiedValue && <p>Copied: {copiedValue}</p>}</div>
      <div className="flex flex-col md:flex-row md:justify-between">
      <div className="relative md:mt-7">
            <ExportButton onClick={(e) =>{ e.stopPropagation()
              handleUOpen()
              }} />
            {isModalUOpen ? (
              <>
              <div
              ref={dropdownRef}
              className="absolute w-80 h-80 my-4 py-4 border bg-white shadow-xl px-3 mx-auto md:left-0 rounded-md z-50">
              <FilterByDate />
              </div>
              </>
            ) : null}
          </div>
        <div className="flex flex-col space-y-3 space-x-0 md:space-y-0 lg:space-y-0 md:flex-row md:space-x-5 justify-end">
          <div>
          <InputField
              type="text"
              label="Search"
              name="ref"
              placeholder="Search by reference"
              onChange={handleReferenceChange}
              value={ref}
              rightIcon={<FiSearch size={20} />}
            />
          </div>
          <div>
            <SelectField label="Filter By"  onChange={handleStatusChange}
              value={selectedStatus}>
              <option value="">All</option>
              <option value="successful">Successful</option>
              <option value="pending">Pending</option>
              <option value="failed">Failed</option>
              <option value="reversed">Reversed</option>
            </SelectField>
          </div>
          
        </div>
      </div>
      <div className="py-4">
        {isLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            {transData.length === 0 ? (
              <p className="text-center text-red-500 text-md font-semibold">
                No Transaction available
              </p>
            ) : (
              <Table
                columns={columns}
                data={transData}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            )}
          </>
        )}
      </div>
      {/* <RightDrawer
        isOpen={isReQueryDrawerOpen}
        onClose={handleReQueryDrawerClose}
      >
        <Requery
          transId={transId}
          sessionId={sessionId}
          amount={amount}
          status={status}
          details={details}
          date={date}
          onClose={handleReQueryDrawerClose}
        />
      </RightDrawer> */}
      {/* <RightDrawer
        isOpen={isDisputeDrawerOpen}
        onClose={handleDisputeDrawerClose}
      >
        <Dispute />
      </RightDrawer> */}
      {/* <CustomModal
        title="Search By Date"
        open={isModalUOpen}
        onClose={handleModalUClose}
        width={400}
        height={350}
      >
        <FilterByDate  onClose={handleModalUClose} />
      </CustomModal> */}
    </div>
  );
};

export default OutwardTable;
