import React, { useState } from 'react'
import { FaAngleLeft } from 'react-icons/fa';
import Tabs from './components/Tabs';

const Index = () => {
  const [tab, setTab] = useState(1);

  const changeTab = (t) => {
    setTab(t);
  };

  return (
    <div className="w-full lg:max-w-[1400px] p-4 lg:px-16 mx-auto">
      <div className="flex flex-row items-center justify-between mb-6">
        <div className="flex flex-row space-x-4 items-center">
          {tab === 2 || tab === 3 ? (
            <div
              onClick={() => changeTab(1)}
              className="bg-white h-8 w-8 border flex justify-center items-center border-ash rounded-full cursor-pointer"
            >
              <FaAngleLeft size={15} className="text-secondary" />
            </div>
          ) : null}
          <div className="flex flex-col space-y-2 py-2">
            <h3 className="text-dark text-2xl font-bold capitalize">Settings</h3>
          </div>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl p-4 sm:p-6 lg:p-10">
        <div className="flex flex-col space-y-6">
          <Tabs />
        </div>
      </div>
    </div>
  );
};

export default Index;
