import React, { useState, useEffect } from "react";
import DatePickerField from "../../../components/controls/DatePicker";
import SubmitButton from "../../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { transactionService } from "../../../services/transaction.service";
// import { utils, writeFile } from 'xlsx';
import * as XLSX from 'xlsx'
const FilterByDate = ({ logout }) => {
  const maxFutureMonths = 12;

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  const handleDateRangeChange = (dates) => {
    setDateRange({
      startDate: dates[0],
      endDate: dates[1]
    });
  };
  
const [data, setData] = useState([])
  const getTransactionsByDateRange = async () => {
    try {
      setData([])
      const result = await transactionService.getTransactionsByDate(
        dateRange.startDate.getTime() / 1000,
        dateRange.endDate.getTime() / 1000
      );
      if (result && result.data && result.data.success === true) {
        setData(result.data.data)
      } else {
        toast.error("Error fetching transaction..");
      }
    } catch (error) {
      console.error(error);
      setData([])
      if (error.response && error.response.status === 401) {
        logout();
      }
    }
    //hello
  };
  const exportToExcel = () => {
    const formattedData = data.map(item => ({
        ...item,
        completedAt: new Date(item.completedAt * 1000).toLocaleString(),
        ...item.details // Spread the details object
    }));

    // Remove unwanted properties
    const cleanedData = formattedData.map(({  details, userId, businessId, id, utilityType, stampDuty, category, completedAt, updatedAt, ...rest }) => rest);

    const columns = [
        { header: 'Created At', key: 'createdAt' },
        { header: 'Account Number', key: 'accountNumber' },
        { header: 'Bank Code', key: 'bankCode' },
        // { header: 'Account Name', key: 'accountName' }
    ];

    const worksheet = XLSX.utils.json_to_sheet(cleanedData, { header: columns });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'transactions');
    XLSX.writeFile(workbook, 'transactions.xlsx');
};


  useEffect(() => {
    // Download Excel if data is available
    console.log(JSON.stringify(data));
    if (data.length > 0) {
      exportToExcel()
      toast.success("transaction statement downloaded..");
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // Include 'data' as a dependency
  
  return (
    <div className="">
      <div className="flex flex-col space-y-3">
        <h1>Filter By Date</h1>
        <DatePickerField
          initialStartDate={dateRange.startDate}
          initialEndDate={dateRange.endDate}
          onDateRangeChange={handleDateRangeChange}
          maxFutureMonths={maxFutureMonths}
        />
        <SubmitButton onClick={getTransactionsByDateRange}>Filter</SubmitButton>
      </div>
    </div>
  );
};

export default FilterByDate;
