import React from 'react'
import { FaAngleLeft } from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import DirectorsForm from './forms/DirectorsForm';
const Directors = () => {
  const navigate = useNavigate()
  return (
    <div className="py-12 px-3 lg:px-24 mx-auto">
       <div className="flex flex-row space-x-4 items-center">
            <div onClick={() => {navigate("/onboarding")}} className="bg-white h-8 w-8 border flex justify-center items-center border-ash rounded-full cursor-pointer">
            <FaAngleLeft size={15} className="text-secondary" />
            </div>
    <div className="flex flex-col space-y-3 py-4">
        <div>
          <h3 className="text-dark text-xl md:text-2xl font-bold capitalize">
          Directors and shareholders
          </h3>
        </div>
          <p className="text-md font-regular text-secondary">
          Add and manage all the directors & shareholders of this business</p>
      </div>
      </div>
      <div className="w-full md:max-w-[1000px] h-full flex justify-center items-center bg-white p-3 md:p-6">
        <div>
         <DirectorsForm />
        </div>

      </div>
  </div>
  
  )
}

export default Directors