import React, { useState, useEffect } from "react";
import FileUpload from "../../../components/controls/FileUpload";
import SubmitButton from "../../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { kycService } from "../../../services/kyc.service";
import { uploadService } from "../../../services/fileUpload.service";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
const UpdateDocuments = () => {

  const navigate = useNavigate()
    const [status, setStatus] = useState([]);
    const [input, setInput] = useState([]);
    const [rejectedDocuments, setRejectedDocuments] = useState([]);
  const [updatedDocuments, setUpdatedDocuments] = useState([]);
    const getKycs = () => {
      kycService
        .getKycs()
        .then((result) => {
          if (result && result.data.success) {
            setStatus(result.data.data.Documents);
            // Initialize input state based on status
            const initialInput = result.data.data.Documents.map((doc) => ({
              name: doc.name,
              url: doc.url,
              docType: doc.docType,
              id: doc.id,
            }));
            setInput(initialInput);
            const rejectedDocs = result.data.data.Documents
            .filter(doc => doc.status === "rejected")
            .map(doc => ({
              name: doc.name,
              url: doc.url,
              docType: doc.docType,
              id: doc.id,
            }));
          setRejectedDocuments(rejectedDocs);
        
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  useEffect(() => {
    getKycs();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [uploadedCacFile, setUploadedCacFile] = useState(null);
  const [uploadedMemFile, setUploadedMemFile] = useState(null);
  const [uploadedOpFile, setUploadedOpFile] = useState(null);
  
// alert(JSON.stringify(id))
  const [isProcessing, setIsProcessing] = useState(false);
  const [loadingCac, setLoadingCac] = useState(false);
  const [loadingMem, setLoadingMem] = useState(false);
  const [loadingOp, setLoadingOp] = useState(false);
  const handleCacFileChange = (file) => {
    setLoadingCac(true); // Set loading indicator
    setUploadedCacFile(file);
    updateInputFile("Certificate of incorporation", file, setLoadingCac);
  };

  const handleMemFileChange = (file) => {
    setLoadingMem(true); // Set loading indicator
    setUploadedMemFile(file);
    updateInputFile("Memorandum & Article of Association", file, setLoadingMem);
  };

  const handleOpFileChange = (file) => {
    setLoadingOp(true); // Set loading indicator
    setUploadedOpFile(file);
    updateInputFile("Operating License", file, setLoadingOp);
  };
  const handleReplaceCacClick = (file) => {
    file.preventDefault();
    document.getElementById("cacFileInput").click();
    setUploadedCacFile(file);
    updateInputFile("Certificate of incorporation", file);
  };

  const handleReplaceMemClick = (file) => {
    file.preventDefault();
    document.getElementById("memFileInput").click();
    setUploadedMemFile(file);
    updateInputFile("Memorandum & Article of Association", file);
  };

  const handleReplaceOpClick = (file) => {
    file.preventDefault();
    document.getElementById("opFileInput").click();
    setUploadedOpFile(file);
    updateInputFile("Operating License", file);
  };
 
  const updateInputFile = async (name, selectedFile, setLoading) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
  
    try {
      const uploadResponse = await uploadService.uploadFile(formData);
  
      if (uploadResponse.data && uploadResponse.data.success) {
        const fileURL = uploadResponse.data.data.url;
        toast.success("File uploaded successfully.");
  
        // Update input state with the new file URL
        setUpdatedDocuments((prevInput) => {
          // Check if the item exists in the array
          const itemIndex = prevInput.findIndex((item) => item.name === name);
  
          // If the item exists, update it; otherwise, add it to the array
          if (itemIndex !== -1) {
            const updatedInput = [...prevInput];
            updatedInput[itemIndex] = { ...updatedInput[itemIndex], url: fileURL };
            return updatedInput;
          } else {
            return [...prevInput, { name, url: fileURL }];
          }
        });
  
        // Notify user of successful upload
        toast.success("File uploaded successfully.");
      } else {
        // Notify user of upload failure
        toast.error(uploadResponse.data.message);
      }
    } catch (error) {
      // Notify user of error
      toast.error(error.response?.data.message ?? error.message);
    } finally {
      // Set loading state to false
      setLoading(false);
    }
  };
  
  console.log(JSON.stringify(rejectedDocuments))
  console.log(JSON.stringify(updatedDocuments))

  const updateDocuments = async () => {
    setIsProcessing(true);

    for (const document of rejectedDocuments) {
      const updatedDoc = updatedDocuments.find((doc) => doc.name === document.name);
      if (updatedDoc) {
        const updatedDocumentData = { ...document, url: updatedDoc.url };

        try {
          alert(JSON.stringify(updatedDocumentData))
          const result = await kycService.updateDocument(document.id, updatedDocumentData);
          if (result.data && result.data.success) {
            navigate("/onboarding")
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        } catch (error) {
          toast.error(error.response?.data.message ?? error.message);
        }
      }
    }

    setIsProcessing(false);
  };

  const Submit = () => {
    updateDocuments();
  };

  return (
    <div className="py-12 px-3 md:px-24  mx-auto">
        <div className="flex flex-row space-x-2 md:space-x-4 items-center">
            <div onClick={() => {navigate("/business-upload")}} className="bg-white h-8 w-8 border flex justify-center items-center border-ash rounded-full cursor-pointer">
            <FaAngleLeft size={15} className="text-secondary" />
            </div>
        <div className="flex flex-col space-y-1 md:space-y-3 py-4">
        <div>
          <h3 className="text-dark text-xl md:text-2xl font-bold capitalize">
          Update Business Document
          </h3>
        </div>
          <p className="text-md font-regular text-secondary">
          Update your business document here</p>
      </div>
        </div>
   
      <div className="max-w-[1000px] h-full flex justify-center items-center bg-white p-3 md:p-6">
      
    <div className="flex flex-col space-y-4 py-4">
      {status.length > 0 && status[0].status === "rejected" && (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <h3 className="text-dark font-semibold">
              Certificate of Incorporation
            </h3>
            {uploadedCacFile && (
              <div className="">
                <p
                  className="text-primary cursor-pointer"
                  onClick={handleReplaceCacClick}
                >
                  Replace
                </p>
              </div>
            )}
          </div>
          
          <FileUpload id="cacFileInput" onFileChange={handleCacFileChange} />
          <div className="flex flex-col space-y-2">
            <div className="flex space-x-4">
                <p className="text-sm">Status:</p>
                <p className="text-sm text-red">{status[0]?.status}</p>
            </div>
            <div className="flex space-x-4">
                <p className="text-sm">Reason for Rejection:</p>
                <p className="text-sm text-red">{status[0]?.rejectionReason}</p>
            </div>
          </div>
          {loadingCac && <p className="text-primary">Uploading...</p>}
        </div>
      )}

      {status.length > 1 && status[1].status === "rejected" && (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <h3 className="text-dark font-semibold">
              Memorandum & Articles of Association
            </h3>
            {uploadedMemFile && (
              <div className="">
                <p
                  className="text-primary cursor-pointer"
                  onClick={handleReplaceMemClick}
                >
                  Replace
                </p>
              </div>
            )}
          </div>
          <FileUpload id="memFileInput" onFileChange={handleMemFileChange} />
          <div className="flex flex-col space-y-2">
            <div className="flex space-x-4">
                <p className="text-sm">Status:</p>
                <p className="text-sm text-red">{status[1]?.status}</p>
            </div>
            <div className="flex space-x-4">
                <p className="text-sm">Reason for Rejection:</p>
                <p className="text-sm text-red">{status[1]?.rejectionReason}</p>
            </div>
          </div>
          {loadingMem && <p className="text-primary">Uploading...</p>}
        </div>
      )}

      {status.length > 2 && status[2].status === "rejected" && (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row justify-between">
            <h3 className="text-dark font-semibold">
              Operating License{" "}
              <span className="text-secondary">(Optional)</span>
            </h3>
            {uploadedOpFile && (
              <div className="">
                <p
                  className="text-primary cursor-pointer"
                  onClick={handleReplaceOpClick}
                >
                  Replace
                </p>
              </div>
            )}
          </div>
          <FileUpload id="opFileInput" onFileChange={handleOpFileChange} />
          <div className="flex flex-col space-y-2">
            <div className="flex space-x-4">
                <p className="text-sm">Status:</p>
                <p className="text-sm text-red">{status[2]?.status}</p>
            </div>
            <div className="flex space-x-4">
                <p className="text-sm">Reason for Rejection:</p>
                <p className="text-sm text-red">{status[2]?.rejectionReason}</p>
            </div>
          </div>
          {loadingOp && <p className="text-primary">Uploading...</p>}
        </div>
      )}

      <div>
       <SubmitButton
  onClick={!isProcessing ? Submit : null}
  disabled={isProcessing || Object.values(input).every(value => value === "")}
>
  {isProcessing ? (
    <ClipLoader color="#fff" size={30} data-testid="loader" />
  ) : (
    "Update"
  )}
</SubmitButton>

      </div>
    </div>
    </div>
    </div>

  );
};

export default UpdateDocuments;
