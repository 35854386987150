import React from 'react'
import Tabup from './components/Tabup'

const Kyc = () => {
  return (
    <div className="py-12 md:px-24  mx-auto">
      <div className="flex flex-col space-y-3 py-4">
          <div>
            <h3 className="text-dark text-2xl font-bold capitalize">
            KYC Verification
            </h3>
          </div>
            <p className="text-md font-regular text-secondary">
            Complete/Update your business KYC details and submit for approval </p>
        </div>
        <div className="max-w-[1000px] h-100 flex justify-center items-center p-3 lg:p-6" style={{ backgroundColor: '#003022', height: '500px', borderRadius: '20px' }}>
          <div>
            <Tabup />
          </div>

        </div>
    </div>
  )
}

export default Kyc