import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setServer } from "../../../../store/api-session/apiSlice";
import { toast } from "react-toastify";
import SubmitButton from "../../../../components/controls/SubmitButton";
import "react-toastify/dist/ReactToastify.css";

const ServerForm = ({ onClose }) => {
  const [isChecked, setIsChecked] = useState(false);
  const userState = useSelector((state) => state.user);
  
  const kycStatus = userState.KycBusinesses && userState.KycBusinesses[0] ? userState.KycBusinesses[0].status : null;
  const dispatch = useDispatch();
  const [mode, setMode] = useState("");

  useEffect(() => {
    const serverValue = localStorage.getItem("server");
    if (serverValue !== null) {
      setIsChecked(serverValue === "true");
      setMode(serverValue);
    }
  }, []);

  const handleToggle = () => {
    if (kycStatus === "approved") {
      const newValue = !isChecked;
      setIsChecked(newValue);
      dispatch(setServer(newValue));
      localStorage.setItem("server", newValue);
      window.location.reload();
      onClose(false); // Close the modal without reverting
    } else {
      toast.error("KYC not verified !!");
    }
  };

  return (
    <div className="flex flex-col space-y-4 py-3">
      <p className="text-secondary text-sm">
        You are about to switch to{" "}
        <span className="uppercase font-bold">{mode === "false" ? "LIVE MODE" : "TEST MODE"}</span>. Kindly note that
        this will change your base URL and will fetch fresh data.
      </p>
      <div className="py-3 flex flex-row gap-8 justify-between">
        <SubmitButton onClick={() => onClose(true)} bgColor="bg-red/25" textColor="text-red">
          Cancel
        </SubmitButton>
        <SubmitButton onClick={handleToggle} bgColor="bg-green/25" textColor="text-green">
          Continue
        </SubmitButton>
      </div>
    </div>
  );
};

export default ServerForm;
