import React, { useState, useEffect } from "react";
import SelectField from "../../../components/controls/SelectField";
import SubmitButton from "../../../components/controls/SubmitButton";
import TextArea from "../../../components/controls/TextArea";
import FileUpload from "../../../components/controls/FileUpload";
import States from "../../../utils/state.json";
import { kycService } from "../../../services/kyc.service";
import { uploadService } from "../../../services/fileUpload.service";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";
const AddressForm = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState(false);
  const [input, setInput] = useState();
  const getKycs = () => {
    kycService
      .getKycs()
      .then((result) => {
        if (result && result.data?.success) {
          const kycData = result?.data.data;
          
          const address = kycData?.Address;
          if (address) {
            // Address object is not empty
            const document = address?.Documents[0];
            const initialInput = {
              address: address?.address || "",
              city: address?.city || "",
              state: address?.state || "",
              country: address?.country || "Nigeria",
              document: {
                  // id: document?.id || "",
                name: document?.name || "",
                url: document?.url || ""
              }
            };
  
            setInput(initialInput);
            setStatus(true); // Set status to true
          } else {
            // Address object is empty
            setInput({
              address: "",
              city: "",
              state: "",
              country: "Nigeria",
              document: {
                // id:"",
                name: "",
                url: ""
              }
            });
            setStatus(false); // Set status to false
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
useEffect(() => {
  getKycs();
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  const [lgasOptions, setLgasOptions] = useState([]);
  const [uploadedAddressFile, setUploadedAddressFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);

  const handleAddressFileChange = (file) => {
    setLoadingAddress(true);
    setUploadedAddressFile(file);
    updateInputFile("proof of address", file, setLoadingAddress);
  };

  const handleReplaceAddressClick = (file) => {
    file.preventDefault();
    document.getElementById("addressFileInput").click();
    setUploadedAddressFile(file);
    updateInputFile("proof of address", file);
  };

  const updateInputFile = async (name, selectedFile, setLoading) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const uploadResponse = await uploadService.uploadFile(formData);

      if (
        uploadResponse.data &&
        uploadResponse.data.success &&
        uploadResponse.data.data
      ) {
        const fileURL = uploadResponse.data.data.url;
        toast.success("file uploaded successfully..");
      
        setInput((prevInput) => ({
          ...prevInput,
          document: {
            name: name, 
            url: fileURL,
          },
        }));
        
      } else {
        toast.error(uploadResponse.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data.message ?? error.message);
    } finally {
      setLoading(false); // Reset loading indicator
    }
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    const selectedStateData = States.find(
      (item) => item.state === selectedState
    );

    setInput((prevInput) => ({
      ...prevInput,
      state: selectedState.charAt(0).toUpperCase() + selectedState.slice(1),
      city: "",
    }));

    if (selectedStateData) {
      const lgasOptions = selectedStateData.lgas.map((lga) => (
        <option key={lga} value={lga}>
          {lga}
        </option>
      ));

      setLgasOptions(lgasOptions);
    }
  };

  const handleLgaChange = (event) => {
    setInput((prevInput) => ({
      ...prevInput,
      city:
        event.target.value.charAt(0).toUpperCase() +
        event.target.value.slice(1),
    }));
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    if(status){
      try {
      
        const result = await kycService.updateBusinessAddress(input);
        if (result && result.data.data && result.data.success) {
          toast.success("Address updated successfully...");
          navigate("/onboarding")
        } else {
          toast.error(result.data.message);
        }
      } catch (error) {
        toast.error(error.response?.data.message ?? error.message);
      } finally {
        setIsProcessing(false);
      }
    }
    else{
      try {
        const result = await kycService.addBusinessAddress(input);
        if (result && result.data.data && result.data.success) {
          toast.success("Address added successfully...");
          navigate("/onboarding")
        } else {
          toast.error(result.data.message);
        }
      } catch (error) {
        toast.error(error.response?.data.message ?? error.message);
      } finally {
        setIsProcessing(false);
      }
    }
   
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
      <div className="w-full">
        <SelectField
          name="state"
          label="State"
          value={input?.state}
          onChange={handleStateChange}
        >
          <option value="">State</option>
          {States.map((item) => (
            <option key={item.state} value={item.state}>
              {item.state}
            </option>
          ))}
        </SelectField>
      </div>
      <div className="w-full">
        <SelectField
          name="city"
          label="City"
          value={input?.city}
          onChange={handleLgaChange}
        >
          <option value="">City</option>
          {lgasOptions}
        </SelectField>
      </div>
      <div className="md:col-span-2">
        <TextArea
          label="Address"
          name="address"
          rows="2"
          value={input?.address}
          onChange={(e) => {
            const { value } = e.target;
            const capitalizedValue =
              value.charAt(0).toUpperCase() + value.slice(1);

            setInput((prevInput) => ({
              ...prevInput,
              address: capitalizedValue,
            }));
          }}
        />
      </div>
      <div className="flex flex-col mx-auto space-y-2">
        <div className="flex flex-row justify-between">
          <h3 className="text-dark font-semibold text-start">
            Upload Prof of address
          </h3>
          {uploadedAddressFile && (
            <div className="text-right">
              <p
                className="text-primary cursor-pointer"
                onClick={handleReplaceAddressClick}
              >
                Replace
              </p>
            </div>
          )}
        </div>
        <FileUpload
          id="addressFileInput"
          onFileChange={handleAddressFileChange}
        />
        {loadingAddress && <p className="text-primary">Uploading...</p>}
      </div>
      <div></div>
      <div className="my-2">
        <SubmitButton
          onClick={!isProcessing ? handleSubmit : null}
          disabled={
            isProcessing ||
            input?.address === "" ||
            input?.city === "" ||
            input?.state === "" ||
            input?.country === "" ||
            !input?.document.url ||
            !input?.document.name
              ? true
              : false
          }
        >
          {isProcessing ? (
            <ClipLoader color="#fff" size={30} data-testid="loader" />
          ) : (
            <>
           {status ? "update" : "submit"}
           </>
          )}
        </SubmitButton>
      </div>
    </div>
  );
};

export default AddressForm;
