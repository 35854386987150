// import React from 'react'
const IMG_PATH ='/img/logos/'
const HeaderLogo = () => {
  return (

    <div className="py-2">
        <img className="w-40 h-12   md:w-48 md:h-12 mx-auto" src={`${IMG_PATH}Bellbank-MFB.png`} alt="logo"/>
    </div>
  )
}


export default HeaderLogo