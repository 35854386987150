import React, { useState, useEffect, useRef } from 'react';
import Toggle from "./components/Toggle";
import PropTypes from "prop-types";
import UserDropDown from "./components/UserDropDown";
import Notification from "./components/Notification";
import MenuIcon from "./components/menu";
import Theme from './components/Theme';
import TransactionPin from '../../transactionPin/index';
import OtpModal from "../../../components/modal/OtpModal";
import { useSelector } from "react-redux";

const Navbar = ({ toggleSidebar }) => {
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const userState = useSelector((state) => state.user);
    const kycStatus = userState.KycBusinesses && userState.KycBusinesses[0] ? userState.KycBusinesses[0].status : null;
  const { kycType } = userState;
  const userPin = userState.User?.pin;
// console.log(userState)

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsUserDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
    setIsNotificationDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, [isUserDropdownOpen]);

  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
  const dropNotidownRef = useRef(null);

  const closeNotiDropdown = (event) => {
    if (dropNotidownRef.current && !dropNotidownRef.current.contains(event.target)) {
      setIsNotificationDropdownOpen(false);
    }
  };

  const toggleNotiDropdown = () => {
    setIsNotificationDropdownOpen(!isNotificationDropdownOpen);
    setIsUserDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', closeNotiDropdown);
    return () => {
      document.removeEventListener('click', closeNotiDropdown);
    };
  }, [isNotificationDropdownOpen]);
  const [isModalUOpen, setIsModalUOpen] = useState(false);
  const handleUOpen = ()=>{
    setIsModalUOpen(true);
  }
  const handleModalUClose = () => setIsModalUOpen(false);
  const pin = localStorage.getItem('pinSet')
  useEffect(() =>{
    if(kycStatus === "approved"){
    if(userPin === null){
      if(pin){
      handleUOpen();
      }
    }
  }

  }, [userPin, kycStatus, pin])
  return (
    <div className="w-full bg-white shadow-sm p-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row justify-start items-center space-x-3">
          <div className="block">
            <MenuIcon toggleSidebar={toggleSidebar} />
          </div>
        </div>
        <div className="flex flex-row space-x-5 items-center md:justify-end">
        {kycType === "RC" ? (
          <div>
            <Toggle />
          </div>
        ): null }
          <div className="">
            <Theme />
          </div>
          <div className="">
            <Notification dropNotidownRef={dropNotidownRef} isNotificationDropdownOpen={isNotificationDropdownOpen} toggleNotiDropdown={toggleNotiDropdown} />
          </div>
          <div className="">
          <UserDropDown dropdownRef={dropdownRef} isUserDropdownOpen={isUserDropdownOpen} toggleDropdown={toggleDropdown} />
          </div>
        </div>
      </div>
      
      <OtpModal title="Set Transaction Pin"  open={isModalUOpen} onClose={handleModalUClose} width={400} height={350}>
                <TransactionPin  onClose={handleModalUClose} />
            </OtpModal>
    </div>
  );
};

Navbar.propTypes = {
  toggleSidebar: PropTypes.func,
};

export default Navbar;
