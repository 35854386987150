import React, { useState } from "react";
import SelectField from "../../../components/controls/SelectField";
import SubmitButton from "../../../components/controls/SubmitButton";
import TextArea from "../../../components/controls/TextArea";

const CollectionRequery = ({ reference, receiptNumber, amount, remark, creditAccountNumber, status, updatedAt }) => {
    reference = reference || "";
    receiptNumber = receiptNumber || "";
    amount = amount || 0;
    remark = remark || "";
    creditAccountNumber = creditAccountNumber || "";
    status = status || "";
    updatedAt = updatedAt || "";

    console.log(`${reference}, ${receiptNumber}, ${amount}, ${remark}, ${creditAccountNumber}, ${status}, ${updatedAt}`);
    
    const [textValue, setTextValue] = useState("");
    const [dispute, setDispute] = useState(false);

    const handleTextChange = (e) => {
        setTextValue(e.target.value);
    };

    const openDispute = () => {
        setDispute(prevDispute => !prevDispute);
    };

    const getStatusColor = (status) => {
        if (status && typeof status === 'string') {
            switch (status.toLowerCase()) {
                case 'settled':
                case 'successful':
                    return 'text-green';
                case 'pending':
                    return 'text-amber-500';
                case 'failed':
                    return 'text-red';
                default:
                    return 'text-gray-700'; // Default color if status is not recognized
            }
        } else {
            return 'text-gray-700'; // Default color if status is not provided or not a string
        }
    };

    const formattedDate = new Date(updatedAt).toLocaleDateString();

    return (
        <div className="w-full px-5">
            <div className="">
                <p className="text-xl text-center font-semibold">Transaction Details</p>
            </div>
            <div className="py-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col space-y-3">
                        <p className="text-gray-400 text-sm">Transaction ID</p>
                        <p className="text-gray-700 text-lg font-medium">{reference}</p>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <p className="text-gray-400 text-sm">Session ID</p>
                        <p className="text-gray-700 text-lg font-medium">{receiptNumber}</p>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <p className="text-gray-400 text-sm">Amount</p>
                        <p className="text-gray-700 text-lg font-medium">₦ {amount}</p>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <p className="text-gray-400 text-sm">Credited Account</p>
                        <p className="text-gray-700 text-lg font-medium">{creditAccountNumber}</p>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <p className="text-gray-400 text-sm">remark</p>
                        <p className="text-gray-700 text-lg font-medium">{remark}</p>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <p className="text-gray-400 text-sm">Transaction Response</p>
                        <p className={`text-lg font-medium ${getStatusColor(status)}`}>{status}</p>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <p className="text-gray-400 text-sm">Transaction Date</p>
                        <p className="text-gray-700 text-lg font-medium">{formattedDate}</p>
                    </div>
                </div>
                <div className="py-4 flex flex-col md:flex-row justify-between">
                    <div className="hidden md:flex" />
                    <div className="justify-end ">
                        <SubmitButton onClick={openDispute}>Raise a Dispute</SubmitButton>
                    </div>
                </div>
                {dispute && (
                    <div className="py-4">
                        <div className="flex flex-col space-y-4">
                            <div>
                                <SelectField name="" label="Reason" value="">
                                    <option value="">Temporary</option>
                                    <option value="">Permanent</option>
                                </SelectField>
                            </div>
                            <div>
                                <TextArea
                                    label="Description"
                                    value={textValue}
                                    onChange={handleTextChange}
                                    placeholder="Enter text here..."
                                />
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <SubmitButton>Submit</SubmitButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CollectionRequery;
