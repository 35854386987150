import React from 'react'
import Form from './form/Form'

const TransactionPin = ({onClose}) => {
  return (
    <div>
        <Form close={onClose} />
    </div>
  )
}

export default TransactionPin