import React from 'react'
import BorderButton from '../../../components/controls/BorderButton';
// import CustomModal from '../../../components/modal/CustomModal';
import { useNavigate } from 'react-router-dom';
const DirectorDetails = ({details, close}) => {
    // alert(JSON.stringify(details))
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const handleDrawerOpen = () => {
    //   setIsDrawerOpen(true);
    // };
    // const handleDrawerClose = () => {
    //   setIsDrawerOpen(false);
    // };
    const navigate = useNavigate();
  return (
    <div className="md:w-80 w-full p-4">
        <div className="flex flex-col space-y-2">
            <div className="flex flex-row space-x-4">
                <p className="text-md font-semibold text-dark">Name:</p>
                <p className="text-md font-semibold text-secondary">{`${details?.firstname} ${details?.middlename} ${details?.surname}`}</p>
            </div>
            <div className="flex flex-row space-x-4">
                <p className="text-md font-semibold text-dark">Phone:</p>
                <p className="text-md font-semibold text-secondary">{details?.phone}</p>
            </div>
            <div className="flex flex-row space-x-4">
                <p className="text-md font-semibold text-dark">Role:</p>
                <p className="text-md font-semibold text-secondary">{details?.role}</p>
            </div>
            <div className="flex flex-row space-x-4">
                <p className="text-md font-semibold text-dark">BVN :</p>
                <p className="text-md font-semibold text-secondary">{details?.bvn}</p>
            </div>
            <div className="flex flex-row space-x-6 py-5">
            <div className="flex justify-center items-end">
            <BorderButton
value="Edit"
borderColor="border-primary"
         onClick={() => {
         navigate(`/update-director/${details.id}`)
        }}
         />
            </div>
            <div className="flex justify-center items-end">
           <BorderButton
value="Delete"
borderColor="border-red"
textColor="text-red"
        //  onClick={() => {
        //   handleDrawerOpen()
        // }}
         />
            </div>
            </div>
        </div>
    </div>
  )
}

export default DirectorDetails