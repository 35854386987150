import React, { useState } from "react";
import Util from "../../../utils/util";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/controls/Input";
import SubmitButton from "../../../components/controls/SubmitButton";
import { accountService } from "../../../services/account.service";
import ClipLoader from "react-spinners/ClipLoader";

const IndividualAccount = ({ onClose, getAccounts }) => {
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    phoneNumber: "",
    emailAddress: "",
    bvn: "",
    gender: "male",
    dateOfBirth: "",
  });

  const [loading, setLoading] = useState(null);
  const [validation] = useState({
    validFirstName: true,
    validLastName: true,
    validPhoneNumber: true,
    validEmailAddress: true,
    validBvn: true,
    validDateOfBirth: true,
  });

  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value });
    Util.validate(validation, input, [name]);
  };

  const create = () => {
    Util.validate(validation, input, [
      "firstName",
      "lastName",
      "phoneNumber",
      "emailAddress",
      "bvn",
      "gender",
      "dateOfBirth",
    ]);

    if (
      validation.validFirstName &&
      validation.validLastName &&
      validation.validPhoneNumber &&
      validation.validEmailAddress &&
      validation.validBvn &&
      validation.validDateOfBirth
    ) {
      setLoading(true);

      const updatedInput = { ...input, dateOfBirth: formatDateString(input.dateOfBirth) };

      accountService
        .createIndividualAccount(updatedInput)
        .then((result) => {
          if (result && result.data && result.data.success) {
            toast.success("Virtual account created successfully");
            onClose();
            getAccounts(1);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response?.data.message ?? error.response.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("All fields are required!");
    }
  };

  const formatDateString = (dateString) => {
    const parts = dateString.split("/");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month}-${day}`;
    }
    return dateString;
  };

  return (
    <div className="w-full px-5">
      <div className="">
        <p className="text-xl text-center font-semibold">Add New Virtual Account</p>
      </div>
      <div className="py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Input
              type="text"
              name="firstName"
              label="Firstname"
              placeholder="Firstname"
              value={input.firstName}
              onChange={inputHandler}
            />
          </div>
          <div>
            <Input
              type="text"
              name="lastName"
              label="Lastname"
              placeholder="Lastname"
              value={input.lastName}
              onChange={inputHandler}
            />
          </div>
          <div>
            <Input
              type="text"
              name="middleName"
              label="Middle Name"
              placeholder="Othername"
              value={input.middleName}
              onChange={inputHandler}
            />
          </div>
          <div>
            <Input
              type="text"
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone Number"
              value={input.phoneNumber}
              onChange={inputHandler}
            />
          </div>
          <div>
            <Input
              type="text"
              name="emailAddress"
              label="Email Address"
              placeholder="Email Address"
              value={input.emailAddress}
              onChange={inputHandler}
            />
          </div>
          <div>
            <Input
              type="text"
              name="bvn"
              label="BVN"
              placeholder="BVN"
              value={input.bvn}
              onChange={inputHandler}
            />
          </div>
          <div>
            <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
              Gender
            </label>
            <select
              name="gender"
              id="gender"
              value={input.gender}
              onChange={inputHandler}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div>
            <Input
              type="date"
              name="dateOfBirth"
              label="Date Of Birth"
              value={input.dateOfBirth}
              onChange={inputHandler}
            />
          </div>
          <div className="md:col-span-2">
            <SubmitButton onClick={!loading ? create : null}>
              {loading ? (
                <ClipLoader color="#fff" size={30} data-testid="loader" />
              ) : (
                "Submit"
              )}
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualAccount;
