import React, { useState, useEffect, useRef } from "react";
import AccountTable from "./Components/Table";
import IndividualTable from "./Components/IndividualTable";
import RightDrawer from '../../components/drawer/RightDrawer';
import Button from "../../components/controls/Button";
import IndividualAccount from './forms/IndividualAccount';
import CoperateAccount from './forms/CoperateAccount';
import { accountService } from "../../services/account.service";
import useAuth from "../../usehook/useAuth";

const VirtualAccounts = () => {
  const { logout } = useAuth();
  const [accountData, setAccount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [setPageSize] = useState(10);
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const [add, setAdd] = useState(false);
  const dropdownRef = useRef(null);
  const [isIndividualDrawerOpen, setIsIndividualDrawerOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const getCoAccounts = async (page) => {
    try {
      setIsLoading(true);
      const result = await accountService.getCorporateAccounts(page);
      if (result && result.data && result.data.success === true) {
        setAccount(result.data.data);
        setPagination(result.data?.pagination);
      } else {
        setAccount([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        logout();
      }
      setAccount([]);
      setPagination({});
    } finally {
      setIsLoading(false);
    }
  };

  const getInAccounts = async (page) => {
    try {
      setIsLoading(true);
      const result = await accountService.getIndividualAccounts(page);
      if (result && result.data && result.data.success === true) {
        setAccount(result.data.data);
        setPagination(result.data?.pagination);
      } else {
        setAccount([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        logout();
      }
      setAccount([]);
      setPagination({});
    } finally {
      setIsLoading(false);
    }
  };

  const changeTab = (t) => {
    setTab(t);
    setCurrentPage(1);
  };

  const toggleDropdown = () => {
    setAdd(!add);
  };

  const openAdd = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setAdd(false);
    }
  };

  useEffect(() => {
    if (tab === 1) {
      getInAccounts(currentPage);
    } else {
      getCoAccounts(currentPage);
    }
    document.addEventListener('click', openAdd);
    return () => {
      document.removeEventListener('click', openAdd);
    };
  }, [tab, currentPage]);

  const handleDrawerIndividualOpen = () => {
    setIsIndividualDrawerOpen(true);
  };

  const handleDrawerCorperateOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleIndividualDrawerClose = () => {
    setIsIndividualDrawerOpen(false);
  };

  return (
    <div className="px-6 py-12">
      <div className="py-6">
        <div className="flex flex-col md:flex-row md:justify-between">
          <p className="text-xl text-dark font-semibold">Accounts</p>
          <div className="flex flex-row space-x-4">
            <div
              onClick={() => changeTab(1)}
              className={`text-md font-bold cursor-pointer ${
                tab === 1 ? "border-b-2 border-dark text-primary" : "text-dark"
              }`}
            >
              <p>Individual</p>
            </div>
            <div
              onClick={() => changeTab(2)}
              className={`text-md font-bold cursor-pointer ${
                tab === 2 ? "border-b-2 border-dark text-primary" : "text-dark"
              }`}
            >
              <p>Co-operate</p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3 bg-white rounded-xl">
        <div className="flex flex-col space-y-5 md:flex-row md:justify-between">
          <div />
          <div className="flex flex-row space-x-5 justify-end">
            <div className="mt-3 relative">
              <Button
                value="Create Account"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown();
                }}
                bgColor="bg-primary"
                textColor="text-white"
              />
              {add && (
                <div
                  ref={dropdownRef}
                  className="absolute z-50 bg-white w-40 h-24 -mt-12 rounded-lg shadow-md py-4 pb-4 px-2"
                >
                  <ul className="flex flex-col space-y-2">
                    <li
                      onClick={handleDrawerIndividualOpen}
                      className="w-full p-1 text-gray-400 hover:bg-primary hover:text-white cursor-pointer"
                    >
                      Individual
                    </li>
                    <li
                      onClick={handleDrawerCorperateOpen}
                      className="w-full p-1 text-gray-400 hover:bg-primary hover:text-white cursor-pointer"
                    >
                      Co-operate
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {tab === 1 ? (
          <IndividualTable
            accountData={accountData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            pagination={pagination}
            isLoading={isLoading}
          />
        ) : (
          <AccountTable
            accountData={accountData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            pagination={pagination}
            isLoading={isLoading}
          />
        )}
      </div>
      <RightDrawer isOpen={isIndividualDrawerOpen} onClose={handleIndividualDrawerClose}>
        <IndividualAccount getAccounts={getInAccounts} onClose={handleIndividualDrawerClose} />
      </RightDrawer>
      <RightDrawer isOpen={isDrawerOpen} onClose={handleDrawerClose}>
        <CoperateAccount getAccounts={getCoAccounts} onClose={handleDrawerClose} />
      </RightDrawer>
    </div>
  );
};

export default VirtualAccounts;
