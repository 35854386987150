import React, { useState } from 'react';
import Profile from './Profile';
// import Upgrade from './Upgrade';
// import Security from './Security';
import WebHook from 'pages/preferences/webhook';
import WeebhookTable from 'pages/preferences/webhook/components/WeebhookTable';
import Help from './Help';
import ApiKeys from 'pages/apiKeys';


const Tabs = () => {
    const IMG_PATH = '/icons/';
    const [tab, setTab] = useState(1);

    const changeTab = (t) => {
        setTab(t);
    };

    return (
        <div className="flex flex-col md:flex-row space-x-12 py-5">
            <div className="w-60 flex flex-row md:flex-col md:space-y-4 space-y-2">
                <div onClick={() => changeTab(1)} className="flex items-center space-x-2 cursor-pointer">
                    <img src={`${IMG_PATH}userr.svg`} alt="Profile" className="w-4 h-4" />
                    <p className="text-sm">Profile</p>
                </div>
                {/* <div onClick={() => changeTab(2)} className="flex items-center space-x-2 cursor-pointer">
                    <img src={`${IMG_PATH}upgrade.svg`} alt="Upgrade Account" className="w-6 h-6" />
                    <p className="text-sm">Upgrade Account</p>
                </div> */}
                <div onClick={() => changeTab(3)} className="flex items-center space-x-2 cursor-pointer">
                    <img src={`${IMG_PATH}weebhook.svg`} alt="WebHooks" className="w-4 h-4" />
                    <p className="text-sm">WebHooks</p>
                </div>
                <div onClick={() => changeTab(2)} className="flex items-center space-x-2 cursor-pointer">
                    <img src={`${IMG_PATH}help.svg`} alt="Help Center" className="w-4 h-4" />
                    <p className="text-sm">Help Center</p>
                </div>
                <div className="flex items-center space-x-2 cursor-pointer">
                    <img src={`${IMG_PATH}logout.svg`} alt="Log-out" className="w-4 h-4" />
                    <p className="text-sm">Log-out</p>
                </div>
            </div>
            <div className="bg-white w-full rounded-md mx-auto px-0 py-1">
                {tab === 1 ? (
                    <Profile />
                ) : tab === 2 ? (
                    <Help />
                ) : tab === 3 ? (
                    <ApiKeys />
                ) : null}
            </div>
        </div>
    );
};

export default Tabs;
