import React from 'react'
import TransactionTable from './components/Table'

const WebhookNotification = () => {
  return (
    <div className="p-6">
        <div className="py-4 flex flex-col space-y-3">
            <p className="text-xl text-dark font-semibold">Webhooks</p>
            <p className="text-dark text-sm">See all webhook transactions</p>
        </div>
        <div className="py-6 bg-white w-full h-full rounded-xl">
        <TransactionTable />
        </div>
        
    </div>
  )
}

export default WebhookNotification