import React, { useState, useEffect } from "react";
// import Input from "../../../components/controls/Input";
import { apikeyService } from "../../../services/apikey.service";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "../../../usehook/useAuth";
import { FaCopy } from "react-icons/fa";
import { webhookService } from "../../../services/webhook.service";
const Form = () => {
  const { logout } = useAuth();
  const [data, setData] = useState({
    consumerSecret: "",
    consumerKey: "",
  });
  // const [loading, setLoading] = useState(true);
  const getApikeys = () => {
    // setLoading(true);

    apikeyService
      .generateKey()
      .then((result) => {
        if (result && result.data.data && result.data.success) {
          setData(result.data.data);
        } else {
          setData({});
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          logout();
        }
      })
      
  };
  
  const [webhookdata, setWebhookData] = useState({
    webhookUrl:'',
  });
  const getWebhooks = () => {

    webhookService
      .getWebhook()
      .then((result) => {
        if (result && result.data.data && result.data.success) {
    
            setWebhookData(result.data.data[0]);
        } else {
          setData({});
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          logout();
        }
      })
      .finally(() => {
    
      });
  };
  useEffect(() => {
    getWebhooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data.consumerSecret);
    toast.success("Copied to clipboard!");
  };
  const copyconsumerKey = () => {
    navigator.clipboard.writeText(data.consumerKey);
    toast.success("Copied to clipboard!");
  };
  const create = () => {
  
      // setLoading(true);
const input = {
    webhookUrl: webhookdata?.webhookUrl,
    environment: "test",
}
// alert(JSON.stringify(input))
      webhookService
        .addWebhook(input)
        .then((result) => {
          if (result && result.data.data && result.data.success) {
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          // setLoading(false);
          toast.error(error?.response?.message ?? error?.response?.data?.message);
        })
        .finally(() => {
          // setLoading(false);
        });
  };
  return (
    <div className="py-4 flex flex-col space-y-12 px-4 md:px-12 mx-auto">
      <div className="flex flex-col space-y-4">
        <p className="text-md text-secondary">API Configuration - Live Mode</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <p className="text-md ">Consumer Secret Key: </p>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center space-x-3">
              <input
                type="text"
                name=""
                className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3  focus:border-primary"
                value={data?.consumerSecret}
                onChange={(e) => {
                  setData({ ...data, consumerSecret: e.target.value });
                }}
                disabled
              />
              <FaCopy
                className="cursor-pointer text-primary"
                onClick={copyToClipboard}
              />
              {/* {loading ? (
            <ClipLoader color="#00BC74" size={30} data-testid="loader" />
          ) : ( */}

              {/* )} */}
            </div>

            <p
              onClick={getApikeys}
              className="py-3 text-primary cursor-pointer"
            >
              Generate Key
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <p className="text-md ">Consumer Key: </p>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center space-x-3">
              <input
                type="text"
                name=""
                className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3  focus:border-primary"
                value={data?.consumerKey}
                onChange={(e) => {
                  setData({ ...data, consumerKey: e.target.value });
                }}
                disabled
              />
              <FaCopy
                className="cursor-pointer text-primary"
                onClick={copyconsumerKey}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <p className="text-md ">Webhook Url: </p>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center space-x-3">
              <input
                type="text"
                name=""
                className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3  focus:border-primary"
                value={webhookdata?.webhookUrl}
                onChange={(e) => {
                  // console.log(e.target.value)
                  setWebhookData({ ...webhookdata, webhookUrl: e.target.value });
                }}
                
              />
              {/* {loading ? (
                <ClipLoader color="#fff" size={30} data-testid="loader" />
              ) : ( */}
              <p
              onClick={create}
              className="py-3 text-primary cursor-pointer"
            >
              {data.webhookUrl === null ? "Create"  :  "Update"}
            </p>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-4">
        <p className="text-md text-secondary">API Configuration - Test Mode</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <p className="text-md ">Consumer Secret Key: </p>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center space-x-3">
              <input
                type="text"
                name=""
                className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3  focus:border-primary"
                value={data?.consumerSecret}
                onChange={(e) => {
                  setData({ ...data, consumerSecret: e.target.value });
                }}
                disabled
              />
              <FaCopy
                className="cursor-pointer text-primary"
                onClick={copyToClipboard}
              />
              {/* {loading ? (
            <ClipLoader color="#00BC74" size={30} data-testid="loader" />
          ) : ( */}

              {/* )} */}
            </div>
            <p
              onClick={getApikeys}
              className="py-3 text-primary cursor-pointer"
            >
              Generate Key
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <p className="text-md ">Consumer Key: </p>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center space-x-3">
              <input
                type="text"
                name=""
                className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3  focus:border-primary"
                value={data?.consumerKey}
                onChange={(e) => {
                  setData({ ...data, consumerKey: e.target.value });
                }}
                disabled
              />
              <FaCopy
                className="cursor-pointer text-primary"
                onClick={copyconsumerKey}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <p className="text-md ">Webhook Url: </p>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center space-x-3">
              <input
                type="text"
                name=""
                className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3  focus:border-primary"
                value={webhookdata?.webhookUrl}
                onChange={(e) => {
                  setData({ ...data, webhookUrl: e.target.value });
                }}
                
              />
              {/* {loading ? ( */}
                <ClipLoader color="#fff" size={30} data-testid="loader" />
              {/* ) : ( */}
              <p
              onClick={create}
              className="py-3 text-primary cursor-pointer"
            >
              {data.webhookUrl !== "" ? "Update" : "Create"}
            </p>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="flex">
            <p className="text-red text-sm">Note: this is for test don't use it on production</p>
        </div>
      </div>
    </div>
  );
};

export default Form;
