import React from 'react'
import OutwardTable from './Components/Table'

const Outwards = () => {
  return (
    <div className="p-6">
    <div className="py-4">
        <p className="text-xl text-dark font-semibold">Pay Out Payments</p>
    </div>
    <div className="py-6 bg-white h-screen w-full rounded-xl">
    <OutwardTable />
    </div>
    
</div>
  )
}

export default Outwards