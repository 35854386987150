import React, { useState, useMemo, useEffect } from "react";
import Table from "../../../components/Table/Table";
import { FiSearch, FiCopy } from "react-icons/fi";
import { SlOptionsVertical } from 'react-icons/sl';
import InputField from "../../../components/controls/InputField";
import SelectField from "../../../components/controls/SelectField";
import CollectionRequery from "../../components/collectRequery/CollectionRequery";
import ExportButton from "../../../components/controls/ExportButton";
import RightDrawer from "../../../components/drawer/RightDrawer";
import { toast } from "react-toastify";
import { transactionService } from "../../../services/transaction.service";
import Loading from "../../../components/loader/Loading";
import useAuth from "../../../usehook/useAuth";
import { Link } from "react-router-dom";
import FilterByDate from "./FilterByDate";
import { Menu } from '@headlessui/react';

const CollectionTable = () => {
  const { logout } = useAuth();
  const [transData, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
  const getTransactions = async (page = 1) => {
    try {
      setIsLoading(true);
      const result = await transactionService.getInwardTransactions(page);
      if (result && result.data && result.data.success === true) {
        setData(result.data.data);
        setTotalPages(result.data.pagination.pages);
      } else {
        toast.error("Error fetching transaction..");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        logout();
      }
      setData([]);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const handlePageChange = ({ nextPage }) => {
    setCurrentPage(nextPage);
    getTransactions(nextPage);
  };

  const getTransactionsByStatus = async (status) => {
    try {
      setIsLoading(true);
      const result = await transactionService.getInwardTransactionsByStatus(
        status,
        currentPage
      );
      if (result && result.data && result.data.success === true) {
        setData(result.data.data);
        setTotalPages(result.data.pagination.pages);
      } else {
        toast.error("Error fetching transaction..");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        logout();
      }
      setData([]);
      setTotalPages(0);
    } finally {
      setIsLoading(false);
    }
  };

  const getTransactionsByRef = async (value) => {
    try {
      setIsLoading(true);
      const result = await transactionService.getCollectionByReference(value);
      if (result && result.data && result.data.success === true && result.data.data) {
        setData([result.data.data]);
      } else {
        toast.error("Error fetching transactions.");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        logout();
      }
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const [ref, setRef] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusChange = (event) => {
    const status = event.target.value;
    setSelectedStatus(status);
    getTransactionsByStatus(status);
  };

  const handleReferenceChange = (event) => {
    const value = event.target.value;
    setRef(value);
    if (value === "") {
      getTransactions();
    } else {
      getTransactionsByRef(value);
    }
  };

  const [isReQueryDrawerOpen, setIsReQueryDrawerOpen] = useState(false);
  const [isExportDrawerOpen, setIsExportDrawerOpen] = useState(false);

  const [transactionDetails, setTransactionDetails] = useState({
    reference: "",
    receiptNumber: "",
    amount: 0,
    creditAccountNumber: "",
    status: "",
    remark: "",
    updatedAt: 0,
  });

  const handleDrawerReQueryOpen = (
    reference,
    receiptNumber,
    amount,
    remarks,
    creditAccountNumber,
    status,
    updatedAt
  ) => {
    setTransactionDetails({
      reference,
      receiptNumber,
      amount,
      creditAccountNumber,
      status,
      remark: remarks,
      updatedAt,
    });
    setIsReQueryDrawerOpen(true);
  };

  const handleReQueryDrawerClose = () => {
    setIsReQueryDrawerOpen(false);
  };

  const handleUOpen = () => {
    setIsExportDrawerOpen(!isExportDrawerOpen);
  };

  const handleCopy = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        console.log(`Copied: ${value}`);
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard", error);
      });
  };

  const repushWebhook = (reference) => {
    console.log(`Repushing webhook for transaction: ${reference}`);
    // Add your webhook repushing logic here
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Reference",
        accessor: "reference",
        Cell: ({ value }) => {
          if (value && value.length > 8) {
            const firstFourLetters = value.substring(0, 4);
            const lastFourLetters = value.substring(value.length - 4);
            const modifiedValue = `${firstFourLetters}...${lastFourLetters}`;

            return (
              <span
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                onClick={() => handleCopy(value)}
              >
                {modifiedValue}
                <FiCopy size={16} className="ml-2" onClick={() => handleCopy(value)} />
              </span>
            );
          } else {
            return value;
          }
        },
      },
      {
        Header: "Session ID",
        accessor: "receiptNumber",
        Cell: ({ value }) => {
          if (value && value.length > 8) {
            const firstFourLetters = value.substring(0, 4);
            const lastFourLetters = value.substring(value.length - 4);
            const modifiedValue = `${firstFourLetters}...${lastFourLetters}`;

            return (
              <span
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                onClick={() => handleCopy(value)}
              >
                {modifiedValue}
                <FiCopy size={16} className="ml-2" onClick={() => handleCopy(value)} />
              </span>
            );
          } else {
            return value;
          }
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => {
          const formattedAmount = new Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currencyDisplay: "symbol",
          }).format(value);

          return <div>{formattedAmount}</div>;
        },
      },
      {
        Header: "Credit Account",
        accessor: "creditAccountNumber",
      },
      {
        Header: "Remark",
        accessor: "remarks",
      },
      {
        Header: "Response",
        accessor: "status",
        Cell: ({ row }) => {
          const status = row.original.status;
          let statusText, statusColor, borderColor, bgColor;

          if (status === "created" || status === "settled") {
            statusText = status.charAt(0).toUpperCase() + status.slice(1);
            statusColor = "text-green";
            borderColor = "border-green";
            bgColor = "bg-green/25";
          } else if (status === "pending") {
            statusText = "Pending";
            statusColor = "text-amber-600";
            borderColor = "border-amber-300";
            bgColor = "bg-amber-50";
          } else {
            statusText = "Initiated";
            statusColor = "text-amber-600";
            borderColor = "border-amber-300";
            bgColor = "bg-amber-50";
          }

          return (
            <div className={`flex flex-row space-x-4 justify-center items-center w-20 border rounded-md p-2 ${bgColor} ${borderColor}`}>
              <span className={`text-center ${statusColor}`}>{statusText}</span>
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: ({ value }) => {
          const date = new Date(value);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const hour = String(date.getHours()).padStart(2, "0");
          const minute = String(date.getMinutes()).padStart(2, "0");
          const second = String(date.getSeconds()).padStart(2, "0");

          return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        },
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          const { reference, receiptNumber, amount, creditAccountNumber, status, updatedAt, remarks } = row.original;
          return (
          <Menu>
            <Menu.Button className="text-gray-600 px-2 py-1 rounded-full hover:bg-gray-100">
              <SlOptionsVertical size={20} /> 
            </Menu.Button>
            <Menu.Items className="absolute bg-white border border-gray-200 rounded shadow-lg mt-2 z-10">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`block w-full text-left px-4 py-2 ${active ? 'bg-gray-100' : ''}`}
                    onClick={() =>
                      handleDrawerReQueryOpen(
                        reference,
                        receiptNumber,
                        amount,
                        remarks,
                        creditAccountNumber,
                        status,
                        updatedAt
                      )
                    }
                  >
                    View Details
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`block w-full text-left px-4 py-2 ${active ? 'bg-gray-100' : ''}`}
                    onClick={() => repushWebhook(reference)}
                  >
                    Repush Webhook
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Menu>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-8">
            {/* Search, Filter, and Export Actions */}
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 justify-between items-center">
              {/* Search Box */}

              <div className="flex flex-col space-y-3 space-x-0 md:space-y-0 lg:space-y-0 md:flex-row md:space-x-5 justify-end">
          <div>
          <InputField
              type="text"
              label="Search"
              name="ref"
              placeholder="Search by reference"
              onChange={handleReferenceChange}
              value={ref}
              rightIcon={<FiSearch size={20} />}
            />
          </div>
              </div>

              {/* Status Filter */}
              <div className="w-full sm:w-1/4">
            <SelectField label="Filter By"  onChange={handleStatusChange}
              value={selectedStatus}>
              <option value="">All</option>
              <option value="successful">Successful</option>
              <option value="pending">Pending</option>
              <option value="failed">Failed</option>
              <option value="reversed">Reversed</option>
            </SelectField>
          </div>

              {/* Export Button */}
              <div className="w-full sm:w-auto flex justify-end sm:justify-start">
                <ExportButton onClick={handleUOpen} />
              </div>
            </div>

            {/* Table Component */}
            <Table
              columns={columns}
              data={transData}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </div>
        </div>
      )}

      {/* RightDrawer for ReQuery */}
      <RightDrawer isOpen={isReQueryDrawerOpen} onClose={handleReQueryDrawerClose}>
        <CollectionRequery
          reference={transactionDetails.reference}
          receiptNumber={transactionDetails.receiptNumber}
          amount={transactionDetails.amount}
          creditAccountNumber={transactionDetails.creditAccountNumber}
          status={transactionDetails.status}
          remark={transactionDetails.remark}
          updatedAt={transactionDetails.updatedAt}
          onClose={handleReQueryDrawerClose}
        />
      </RightDrawer>

      {/* RightDrawer for Export */}
      <RightDrawer isOpen={isExportDrawerOpen} onClose={() => setIsExportDrawerOpen(false)}>
        {/* Export Drawer Content */}
        <div>
          <FilterByDate />
        </div>
      </RightDrawer>
    </>
  );
};

export default CollectionTable;
