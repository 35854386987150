import React from 'react'
import { LuDownload } from "react-icons/lu";
const ExportButton = ({ value, onClick, bgColor, textColor, disabled }) => {
  return (
    <div> <button
    className={`w-full flex  rounded-xl p-3 border border-gray-300 cursor-pointer bg-transparent text-dark`}
    onClick={onClick}
    disabled={disabled} // Apply the disabled prop to the button element
  >
    <LuDownload size={20} style={{ marginRight: "5px" }} />
   export
  </button></div>
  )
}

export default ExportButton