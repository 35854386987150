import React from 'react';

const RadioField = ({id, checked, label, onChange, name, value }) => {
  return (
    <div className="flex items-center mb-4">
      <input
        type="radio"
        id={`${id}-${value}`}
        name={name}
        checked={checked}
        onChange={() => onChange(value)}
        className="mr-2 sr-only"
      />
      <label
        htmlFor={`${id}-${value}`} 
        className="flex items-center cursor-pointer text-secondary text-sm font-medium space-x-3"
      >
        <div
          className={`relative w-6 h-6 rounded-full border border-gray-300 transition-all duration-200 ease-in-out ${
            checked
              ? 'bg-white border-primary border-2'
              : 'bg-transparent'
          }`}
        >
          {checked && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute inset-0 m-auto w-4 h-4 fill-current text-primary pointer-events-none"
              viewBox="0 0 12 12"
            >
              <circle cx="6" cy="6" r="5" />
            </svg>
          )}
        </div>
       <span> {label}</span>
      </label>
    </div>
  );
};

export default RadioField;
