import React, { useState, useEffect } from "react";
import DatePickerField from "../../components/controls/DatePicker";
import SubmitButton from "../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { transactionService } from "../../services/transaction.service";
import * as XLSX from 'xlsx';

const FilterByDate = ({ logout, acct }) => {
  const maxFutureMonths = 12;

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  const handleDateRangeChange = (dates) => {
    setDateRange({
      startDate: dates[0],
      endDate: dates[1]
    });
  };

  const [data, setData] = useState([]);

  const getTransactionsByDateRange = async () => {
    try {
      setData([]);
      const result = await transactionService.getACollectionByDate(
        dateRange.startDate.getTime() / 1000,
        dateRange.endDate.getTime() / 1000,
        acct
      );
      if (result && result.data && result.data.success === true) {
        setData(result.data.data);
      } else {
        toast.error("Error fetching transactions.");
      }
    } catch (error) {
      console.error(error);
      setData([]);
      if (error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const exportToExcel = () => {
    const formattedData = data.map(item => ({
      reference: item.reference,
      receiptNumber: item.receiptNumber,
      amount: item.amount,
      creditAccountNumber: item.creditAccountNumber,
      remarks: item.remarks,
      status: item.status,
      updatedAt: new Date(item.updatedAt * 1000).toLocaleDateString('en-GB') // Formatting the date
    }));

    const columns = [
      { header: 'Reference', key: 'reference' },
      { header: 'Session ID', key: 'receiptNumber' },
      { header: 'Amount', key: 'amount' },
      { header: 'Credit Account', key: 'creditAccountNumber' },
      { header: 'Remark', key: 'remarks' },
      { header: 'Status', key: 'status' },
      { header: 'Date', key: 'updatedAt' }
    ];

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: columns.map(col => col.key) });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'transactions');
    XLSX.writeFile(workbook, 'transactions.xlsx');
  };

  useEffect(() => {
    if (data.length > 0) {
      exportToExcel();
      toast.success("Transaction statement downloaded.");
    }
  }, [data]);

  return (
    <div className="">
      <div className="flex flex-col space-y-3">
        <h1>Filter By Date</h1>
        <DatePickerField
          initialStartDate={dateRange.startDate}
          initialEndDate={dateRange.endDate}
          onDateRangeChange={handleDateRangeChange}
          maxFutureMonths={maxFutureMonths}
        />
        <SubmitButton onClick={getTransactionsByDateRange}>Download</SubmitButton>
      </div>
    </div>
  );
};

export default FilterByDate;
