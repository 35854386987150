import Util from "../utils/util";
import { http } from "../utils/http";
const server = localStorage.getItem("server");

let url = "";


if (server === "true") {

  url = process.env.REACT_APP_LIVE_URL;
} else {
 
  url = process.env.REACT_APP_TEST_URL;
}
let mockService = {

 testDeposit: async (body) => {
    return await http.post(`${url}business/account/test-deposit`, body, 
      Util.getRegisterHeaders(),
    );
  },
  creditAccount: async (body) => {
    return await http.post(`${url}business/account/fund-account`, body, 
      Util.getRegisterHeaders(),
    );
  },
}
export  {mockService}