import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthLayout from './layouts/authLayout';

import NotFound from "./pages/404/NotFound";
import ProtectedRoute from './utils/routes/ProtectedRoute';
import routes from './utils/routes/routes';
import authRoutes from './utils/routes/authRoutes';
import SessionTimeout from './SessionTimeout'
import useAuth from "./usehook/useAuth";
function App() {
  const { logout } = useAuth();
  //logout
  return (
    <div className="App">
     <Routes>
      
        {authRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<AuthLayout>{<route.component />}</AuthLayout>} />
        ))}

        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<ProtectedRoute path={route.path}>{<route.component />}</ProtectedRoute>}
          />
        ))}

        <Route path="*" element={<NotFound />} />
      </Routes>
      <SessionTimeout timeoutInMinutes={15} onTimeout={logout} />
    </div>
  );
}

export default App;
