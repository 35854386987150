import React from 'react';
import MainCard from "../../../components/cards/MainCard";
import { BsArrowDownLeftCircle, BsArrowUpRightCircle } from "react-icons/bs";
import SelectField from "../../../components/controls/SelectField";
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Register Chart.js components
Chart.register(...registerables);

const Statistics = () => {
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Expense',
                data: [0, 10, 30, 60, 50, 50, 20, 35, 12, 56, 44, 32],
                borderColor: 'red',
                backgroundColor: 'transparent',
                tension: 0.6, // Adjust the tension for the zig-zag effect
            },
            {
                label: 'Income',
                data: [40, 20, 30, 60, 50, 50, 20, 55, 22, 36, 64, 12],
                borderColor: 'green',
                backgroundColor: 'transparent',
                tension: 0.6, // Adjust the tension for the zig-zag effect
            },
        ],
    };

    // Chart.js options
    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="bg-lig w-full h-full p-3">
            <div className="flex flex-col space-y-5">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <MainCard>
                        <div className="flex flex-col space-y-3">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col space-y-2">
                                    <p className="text-dark text-sm">Total Income</p>
                                    <p className="text-dark text-2xl font-semibold">₦ 200.00</p>
                                </div>
                                <div>
                                    <BsArrowDownLeftCircle size={35} className="text-green" />
                                </div>
                            </div>
                            <hr className="w-full h-px bg-ash" />
                            <div className="flex flex-row space-x-1">
                                <p className="text-sm text-green">0%</p>
                                <p className="text-secondary text-sm">increase compared to last week</p>
                            </div>
                        </div>
                    </MainCard>
                    <MainCard>
                        <div className="flex flex-col space-y-3">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col space-y-2">
                                    <p className="text-dark text-sm">Total Expense</p>
                                    <p className="text-dark text-2xl font-semibold">₦ 200.00</p>
                                </div>
                                <div>
                                    <BsArrowUpRightCircle size={35} className="text-red" />
                                </div>
                            </div>
                            <hr className="w-full h-px bg-ash" />
                            <div className="flex flex-row space-x-1">
                                <p className="text-sm text-green">0%</p>
                                <p className="text-secondary text-sm">increase compared to last week</p>
                            </div>
                        </div>
                    </MainCard>
                </div>
                <MainCard>
                    <div className="flex flex-col space-y-3">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col space-y-2">
                                <p className="text-dark text-sm">Total Volume Balance</p>
                                <p className="text-dark text-2xl font-semibold">₦ 200.00</p>
                            </div>
                            <div>
                                <SelectField value="">
                                    <option value="">All Times</option>
                                    <option value="successful">1 Week</option>
                                    <option value="pending">1 Month</option>
                                    <option value="failed">3 Months</option>
                                    <option value="reversed">6 Months</option>
                                    <option value="reversed">1 Year</option>
                                </SelectField>
                            </div>
                        </div>
                        <Line data={data} options={options} />
                    </div>
                </MainCard>
            </div>
        </div>
    );
}

export default Statistics;
