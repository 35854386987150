import Util from "../utils/util";
import { http } from "../utils/http";

const server = localStorage.getItem("server");


let url = "";
let env = "";

if (server === "true") {

  url = process.env.REACT_APP_LIVE_URL;
  env = "live"
} else {

  url = process.env.REACT_APP_TEST_URL;
  env="test"
}

let apikeyService = {
 
  generateKey: async () => {
    return await http.post(`${url}auth/business/generate-api-key?environment=${env}`, {},
     Util.getRegisterHeaders(),
    );
  },

};

export { apikeyService };

