import React, { useMemo } from "react";
import Table from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import Loading from "../../../components/loader/Loading"
const AccountTable = ({ getAccounts, accountData, currentPage, setCurrentPage, pagination, pageSize, setPageSize, isLoading }) => {

  const handlePageChange = ({ nextPage, previousPage }) => {
    setCurrentPage({ nextPage, previousPage });
    getAccounts(nextPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to the first page when changing page size
    getAccounts(1);
  };
  const columns = useMemo(() => {
    return [
      {
        Header: "Account Number",
        accessor: "accountNumber",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
        Cell: ({ value }) => (
          <span>{value ? value.charAt(0).toUpperCase() + value.slice(1) : ''}</span>
        ),
      },
      // {
      //   Header: "Business Name",
      //   accessor: "businessName",
      //   Cell: ({ value }) => (
      //     <span>{value ? value.charAt(0).toUpperCase() + value.slice(1) : ''}</span>
      //   ),
      // },
      {
        Header: "Account Type",
        accessor: "accountType",
        Cell: ({ value }) => (
          <span>{value ? value.charAt(0).toUpperCase() + value.slice(1) : ''}</span>
        ),
      },
      {
        Header: "Validity Type",
        accessor: "validityType",
        Cell: ({ value }) => (
          <span>{value ? value.charAt(0).toUpperCase() + value.slice(1) : ''}</span>
        ),
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: ({ value }) => {
          if (!value) return <div>No date available</div>;
          const date = new Date(value * 1000); // Convert Unix timestamp to milliseconds
          const formattedDate = date.toLocaleString(); // Format the date as a string
          return <div>{formattedDate}</div>;
        },
      },
      {
        Header: "Action",
        accessor: "menu",
        Cell: ({ row }) => {
          return (
            <div>
              {/* <TableOption
                index={0}
                icon={<SlOptionsVertical size={20} className="text-primary" />}
              >
                <ul className="flex flex-col space-y-1 py-2 px-4 capitalize">
                  <li className=""> */}
                  <Link to={`/transaction/${row.original.accountNumber}`}>
                    <button className="w-full p-2 text-start text-md font-semibold text-dark">
                     View Transactions
                    </button>
                    </Link>
                  {/* </li>
                 
                </ul>
              </TableOption> */}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="">
      <div className="py-4">
      {isLoading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        {accountData.length === 0 ? (
                            <p className="text-center text-red-500 text-md font-semibold">No Virtual Account available</p>
                        ) : (
                          <Table columns={columns}
                          data={accountData}
                          onPageChange={handlePageChange}
                          totalPages={pagination.pages}
                          currentPage={currentPage}
                          onPageSizeChange={handlePageSizeChange}
                          pageSize={pageSize} />
                        )}
                    </>
                )}
        
      </div>
    </div>
  );
};

export default AccountTable;
