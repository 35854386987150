import Util from "../utils/util";
import { http } from "../utils/http";

let authService = {
  login: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/login`, body,
      Util.getHeaders(),
    );
  },
  verifyLoginOtp: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/login/verify-otp`, body,
     Util.getHeaders(),
     console.log(Util.getHeaders(), 'token')
    );
  },
  register: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/register`, body, 
      Util.getHeaders(),
    );
  },
  verifyEmailOtp: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/email/verify`, body, 
      Util.getRegisterHeaders(),
    );
  },
  verifyPhoneOtp: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/phone/verify`, body,
     Util.getRegisterHeaders(),
    );
  },
  completeProfile: async (body) => {
    return await http.put(`${process.env.REACT_APP_LIVE_URL}auth/business/profile`, body,
      Util.getRegisterHeaders(),
    );
  },
  setPassword: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/password`, body,
     Util.getRegisterHeaders(),
    );
  },
  forgotPassword: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/forgot-password`, body,
    Util.getHeaders(),
    );
  },
  verifyForgotPassword: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/forgot-password/verify`, body, 
      Util.getRegisterHeaders(),
  );
  },
  resetPassword: async (body) => {
    return await http.put(`${process.env.REACT_APP_LIVE_URL}auth/business/reset-password`, body, 
      Util.getRegisterHeaders(),
    );
  },
  changePassword: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/password/change`, body, 
      Util.getRegisterHeaders(),
    );
  },
 resendOtp: async () => {
    return await http.get(`${process.env.REACT_APP_LIVE_URL}auth/business/register/resend-otp`,
    Util.getRegisterHeaders(),
    );
  },
  getProfile: async () => {
    return await http.get(`${process.env.REACT_APP_LIVE_URL}auth/business/me`,
    Util.getRegisterHeaders(),
    );
  },
  setPin: async (body) => {
    return await http.post(`${process.env.REACT_APP_LIVE_URL}auth/business/pin`, body, 
      Util.getRegisterHeaders(),
    );
  },
};

export { authService };

