import { createSlice } from '@reduxjs/toolkit';

export const initialState = {}; 

export const serverSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setServer: (state, action) => {
      return { ...state, walserver: action.payload };
    },
  },
});

export const { setServer } = serverSlice.actions;

export default serverSlice.reducer;
