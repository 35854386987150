// UploadForm.jsx
import React, { useState } from 'react';
import FileUpload from "../../../components/controls/FileUpload";
import SubmitButton from "../../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { kycService } from "../../../services/kyc.service";
import { uploadService } from "../../../services/fileUpload.service";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
const UploadForm = () => {
  const navigate = useNavigate()
  const [uploadedCacFile, setUploadedCacFile] = useState(null);
  const [uploadedMemFile, setUploadedMemFile] = useState(null);
  const [uploadedOpFile, setUploadedOpFile] = useState(null);
  const [input, setInput] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loadingCac, setLoadingCac] = useState(false);
  const [loadingMem, setLoadingMem] = useState(false);
  const [loadingOp, setLoadingOp] = useState(false);
  const handleCacFileChange = (file) => {
    setLoadingCac(true); // Set loading indicator
    setUploadedCacFile(file);
    updateInputFile("CAC Form", file, setLoadingCac);
  };

  const handleMemFileChange = (file) => {
    setLoadingMem(true); // Set loading indicator
    setUploadedMemFile(file);
    updateInputFile("Memorandum & Article of Association", file, setLoadingMem);
  };

  const handleOpFileChange = (file) => {
    setLoadingOp(true); // Set loading indicator
    setUploadedOpFile(file);
    updateInputFile("Operating License", file, setLoadingOp);
  };
  const handleReplaceCacClick = (file) => {
    file.preventDefault();
    document.getElementById('cacFileInput').click();
    setUploadedCacFile(file);
    updateInputFile("CAC Form", file);
  
  };
  
  const handleReplaceMemClick = (file) => {
    file.preventDefault();
    document.getElementById('memFileInput').click();
    setUploadedMemFile(file);
    updateInputFile("Memorandum & Article of Association", file);
 
  };
  
  const handleReplaceOpClick = (file) => {
    file.preventDefault();
    document.getElementById('opFileInput').click();
    setUploadedOpFile(file);
    updateInputFile("Operating License", file);
  };


  const updateInputFile = async (name, selectedFile, setLoading) => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const uploadResponse = await uploadService.uploadFile(formData);

      if (uploadResponse.data && uploadResponse.data.success && uploadResponse.data.data) {
        const fileURL = uploadResponse.data.data.url;
        toast.success("file uploaded successfully..");
        setInput((prevInput) => {
          const existingFileIndex = prevInput.findIndex((item) => item.name === name);

          if (existingFileIndex !== -1) {
            const updatedInput = [...prevInput];
            updatedInput[existingFileIndex] = { name, url: fileURL };
            console.log(JSON.stringify(updatedInput));
            return updatedInput;
          } else {
            return [...prevInput, { name, url: fileURL }];
          }
        });
      } else {
        toast.error(uploadResponse.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data.message ?? error.message);
    } finally {
      setLoading(false); // Reset loading indicator
    }
  };
  
  const Submit = async () => {
    if (input.length === 3) {
      setIsProcessing(true);
  
      kycService
        .addBusinessDocuments(input)
        .then((result) => {
          if (result && result.data.success) {
            setTimeout(() => {
              setIsProcessing(false);
              navigate("/onboarding")
            }, 3000);
            toast.success("Documents uploaded successfully...");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data.message ?? error.message);
        });
    } else {
      toast.error("All fields are required!");
    }
  };
  return (
    <div className="flex flex-col space-y-4 py-4">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row justify-between">
          <h3 className="text-dark font-semibold">Certificate of Incorporation</h3>
          {uploadedCacFile && (
            <div className="">
              <p className="text-primary cursor-pointer" onClick={handleReplaceCacClick}>
                Replace
              </p>
            </div>
          )}
        </div>
        <FileUpload id="cacFileInput" onFileChange={handleCacFileChange} />
        {loadingCac && <p className="text-primary">Uploading...</p>}
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row justify-between">
          <h3 className="text-dark font-semibold">Memorandum & Articles of Association</h3>
          {uploadedMemFile && (
            <div className="">
              <p className="text-primary cursor-pointer" onClick={handleReplaceMemClick}>
                Replace
              </p>
            </div>
          )}
        </div>
        <FileUpload id="memFileInput" onFileChange={handleMemFileChange} />
        {loadingMem && <p className="text-primary">Uploading...</p>}
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row justify-between">
          <h3 className="text-dark font-semibold">Operating License <span className="text-secondary">(Optional)</span></h3>
          {uploadedOpFile && (
            <div className="">
              <p className="text-primary cursor-pointer" onClick={handleReplaceOpClick}>
                Replace
              </p>
            </div>
          )}
        </div>
        <FileUpload id="opFileInput" onFileChange={handleOpFileChange} />
        {loadingOp && <p className="text-primary">Uploading...</p>}
      </div>
      <div>
      <SubmitButton
        onClick={!isProcessing && input.length === 3 ? Submit : null}
        disabled={isProcessing || input.length !== 3}
      >
        {isProcessing ? (
          <ClipLoader color="#fff" size={30} data-testid="loader" />
        ) : (
          "Submit"
        )}
      </SubmitButton>
          </div>
    </div>
  );
};

export default UploadForm;
