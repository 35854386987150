import React from 'react'
import NewPayout from './forms/NewPayout'
// import TransferTable from './Components/Table'

const Transfer = () => {
  return (
    <div className="p-6">
    
    <div className="py-6 max-w-[400px] mx-auto justify-center shadow-sm rounded-xl bg-white">
      <div>
      <h3 className="text-xl text-dark text-center font-semibold">New Pay Out</h3>
      </div>
  <NewPayout />

    </div>
    
</div>
  )
}

export default Transfer