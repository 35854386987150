import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './auth/userSlice';
import walletReducer from './wallet/walletSlice';
import serverReducer from './api-session/apiSlice';

const rootReducer = combineReducers({
 user: userReducer,
 wallet:walletReducer,
 server:serverReducer,
});

export default rootReducer;
