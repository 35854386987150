import Collections from "../../pages/collections";
import VirtualAccounts from "../../pages/virtualAccount";
import Transfer from "../../pages/transfer";
import Outwards from "../../pages/transfer/Outwards";
import Dashboard from "../../pages/dashboard";
import Transaction from "../../pages/virtualAccount/Transaction";
import ApiKeys from "../../pages/apiKeys";
import Kyc from "../../pages/kyc/";
import Settings from "../../pages/settings/index";
import WebhookNotification from "../../pages/webhookNotification/";
import BusinessUpload from "../../pages/kyc/BusinessUpload";
import Directors from "../../pages/kyc/Directors";
import BusinessAddress from "../../pages/kyc/BusinessAddress";
import RegisterType from "../../pages/kyc/RegisterType";
import KYCWizard from "../../pages/kyc/KYCWizard";
import Transactions from "../../pages/transactions/index";
import BusinessTransactions from "../../pages/transactions/index";
import TransactionDetails from "../../pages/transfer/Components/TransactionDetails";
import CollectionDetails from "../../pages/collections/Components/CollectionDetails";
// import AddMoney from "../../pages/banking/addMoney/index";
// import SendMoney from "../../pages/banking/sendMoney/index";
// import Airtime from "../../pages/billsPayments/airtime/index";
// import Data from "../../pages/billsPayments/data/index";
// import Cable from "../../pages/billsPayments/cable/index";
// import Electricity from "../../pages/billsPayments/electricity/index";
// import Invoice from "../../pages/BusinessTools/invoice/index";
// import Create from "../../pages/BusinessTools/invoice/components/forms/Create";
// import Product from "../../pages/BusinessTools/product/index";
// import CreateProduct from "../../pages/BusinessTools/product/components/forms/Create";
// import PaymentLink from "../../pages/BusinessTools/paymentLink/index";
// import CreateLink from "../../pages/BusinessTools/paymentLink/components/forms/Create";
// import ComingSoon from "../../pages/comingSoon/index"
// import Payroll from "../../pages/BusinessTools/payRoll/index"
// import CreatePayroll from "../../pages/BusinessTools/payRoll/components/forms/Create"
// import Staff from "../../pages/manager/staff/index"
// import AddStaff from "../../pages/manager/staff/components/forms/Create"
// import Role from "../../pages/manager/roles/index"
// import AddRole from "../../pages/manager/roles/components/forms/Create"
import UpdateDocuments from "../../pages/kyc/forms/UpdateDocuments";
import UpdateDirector from "../../pages/kyc/forms/UpdateDirector";
import TestFundAccount from "../../pages/TestFundAccount/index";
import KYCForm from "pages/kyc/KYCForm";


let routes = [
  {
    path: `/dashboard`,
    component: Dashboard,
    protected: true
  },
  {
    path: `/kyc`,
    component: KYCForm,
    protected: true
  },
  {
    path: `/fund-test`,
    component: TestFundAccount,
    protected: true
  },
  // {
  //   path: `/role`,
  //   component: Role,
  //   protected: true
  // },
  // {
  //   path: `/add-role`,
  //   component: AddRole,
  //   protected: true
  // },
  // {
  //   path: `/staff`,
  //   component: Staff,
  //   protected: true
  // },
  // {
  //   path: `/add-staff`,
  //   component: AddStaff,
  //   protected: true
  // },
  // {
  //   path: `/coming-soon`,
  //   component: ComingSoon,
  //   protected: true
  // },
  {
    path: `/transactions`,
    component: Transactions,
    protected: true
  },
  {
    path: `/business-transactions`,
    component: BusinessTransactions,
    protected: true
  },
  // {
  //   path: `/add-money`,
  //   component: AddMoney,
  //   protected: true
  // },
  // {
  //   path: `/send-money`,
  //   component: SendMoney,
  //   protected: true
  // },
  // {
  //   path: `/airtime`,
  //   component: Airtime,
  //   protected: true
  // },
  // {
  //   path: `/internet`,
  //   component: Data,
  //   protected: true
  // },
  // {
  //   path: `/invoice`,
  //   component: Invoice,
  //   protected: true
  // },
  // {
  //   path: `/create-invoice`,
  //   component: Create,
  //   protected: true
  // },
  // {
  //   path: `/product`,
  //   component: Product,
  //   protected: true
  // },
  // {
  //   path: `/create-product`,
  //   component: CreateProduct,
  //   protected: true
  // },
  // {
  //   path: `/payment-link`,
  //   component: PaymentLink,
  //   protected: true
  // },
  // {
  //   path: `/create-payment-link`,
  //   component: CreateLink,
  //   protected: true
  // },
  // {
  //   path: `/payroll`,
  //   component: Payroll,
  //   protected: true
  // },
  // {
  //   path: `/create-payroll`,
  //   component: CreatePayroll,
  //   protected: true
  // },
  // {
  //   path: `/cable-tv`,
  //   component: Cable,
  //   protected: true
  // },
  // {
  //   path: `/electricity`,
  //   component: Electricity,
  //   protected: true
  // },

  {
    path: `/transaction/:reference`,
    component: TransactionDetails,
    protected: true
  },
  {
    path: `/collection/:reference`,
    component: CollectionDetails,
    protected: true
  },
  {
    path: `/collections-history`,
    component: Collections,
    protected: true
  },
  {
    path: `/virtual-account`,
    component: VirtualAccounts,
    protected: true
  },
  {
    path: `/transaction/:acct`,
    component: Transaction,
    protected: true
  },
  {
    path: `/interbank-transfer`,
    component: Transfer,
    protected: true
  },
  {
    path: `/outward-history`,
    component: Outwards,
    protected: true
  },
  {
    path: `/api-keys`,
    component: ApiKeys,
    protected: true
  },
  {
    path: `/settings`,
    component: Settings,
    protected: true
  },
  {
    path: `/webhook`,
    component: WebhookNotification,
    protected: true
  },
  {
    path: `/onboarding`,
    component: Kyc,
    protected: true
  },
  {
    path: `/business-upload`,
    component: BusinessUpload,
    protected: true
  },
  {
    path: `/directors`,
    component: Directors,
    protected: true
  },
  {
    path: `/business-address`,
    component: BusinessAddress,
    protected: true
  },
  {
    path: `/registration-type`,
    component:RegisterType,
    protected: true
  },
  {
    path: `/update-document/:id`,
    component:UpdateDocuments,
    protected: true
  },
  {
    path: `/update-director/:id`,
    component:UpdateDirector,
    protected: true
  },
];

export default routes;
