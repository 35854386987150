import Util from "../utils/util";
import { http } from "../utils/http";

// Function to get the server type from localStorage
const getServerType = () => localStorage.getItem("server");

// Base URLs for test and live environments
const testUrl = "https://api.bellmonie.com/v1/business/sandbox/";
const liveUrl = "https://api.bellmonie.com/v1/business/";

// Function to get the correct base URL based on the server type
const getBaseUrl = () => {
  const server = getServerType();
  return server === "true" ? liveUrl : testUrl;
};

let transactionService = {
  getMyTransactions: async () => {
    const url = `${getBaseUrl()}transactions/me`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getInwardTransactions: async (page) => {
    const url = `${getBaseUrl()}transactions/collections?limit=${Util.limit}&page=${page}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getInwardTransactionsByStatus: async (status, page) => {
    const url = `${getBaseUrl()}transactions/collections?limit=${Util.limit}&page=${page}&status=${status}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getACollectionTransaction: async (creditAccountNumber, page) => {
    const url = `${getBaseUrl()}transactions/collections?limit=${Util.limit}&page=${page}&creditAccountNumber=${creditAccountNumber}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getAllTransactions: async (page) => {
    const url = `${getBaseUrl()}transactions/me?limit=${Util.limit}&page=${page}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getOutwardTransactions: async (page) => {
    const url = `${getBaseUrl()}transactions/me?limit=${Util.limit}&page=${page}&type=debit`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getOutwardTransactionsByStatus: async (status, page) => {
    const url = `${getBaseUrl()}transactions/me?limit=${Util.limit}&status=${status}&page=${page}&type=debit`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getTransactionsByDate: async (startDate, endDate) => {
    const url = `${getBaseUrl()}transactions/exports?startDate=${startDate}&endDate=${endDate}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getTransactionsByReference: async (reference) => {
    const url = `${getBaseUrl()}transactions/reference/${reference}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getCollectionByReference: async (reference) => {
    const url = `${getBaseUrl()}transactions/collections/${reference}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getCollectionsByDate: async (startDate, endDate) => {
    const url = `${getBaseUrl()}transactions/collections/exports?startDate=${startDate}&endDate=${endDate}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getACollectionByDate: async (startDate, endDate, creditAccountNumber) => {
    const url = `${getBaseUrl()}transactions/collections/exports?startDate=${startDate}&endDate=${endDate}&creditAccountNumber=${creditAccountNumber}`;
    return await http.get(url, Util.getRegisterHeaders());
  }
};

export { transactionService };
