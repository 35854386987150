import InputField from '../../../../components/controls/InputField'
import SubmitButton from '../../../../components/controls/SubmitButton';
import React, { useState} from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { authService } from '../../../../services/auth.service';
import ClipLoader from "react-spinners/ClipLoader";
const NewPassword = ({setTab}) => {
  const [input, setInput] = useState({
    confirmPassword:"",
    password: "",
}); 
 const inputHandler = (e) => {
    const name = e.target.name;
    setInput({ ...input, [name]: e.target.value });

    // Reset passwordError when the user changes the password input
    if (name === "newPassword" || name === "confirmPassword") {
      validatePasswordStrength(e.target.value);
    }
  };
  const [passwordError, setPasswordError] = useState("");


  const validatePasswordStrength = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    const isValidPassword = passwordRegex.test(password);

    if (!isValidPassword) {
      setPasswordError(
        "Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      );
    } else {
      setPasswordError("");
    }
  };

const [passwordVisible, setPasswordVisible] = useState(false);
const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
};
const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
};

const [isProcessing, setIsProcessing] =  useState(false)

const newPassword = () => {
    if (input.password === input.confirmPassword) {
        if (!passwordError) {
            setIsProcessing(true)
        authService.resetPassword({password: input.password}).then((result) =>{
            if(result){
                setTab(5);
                setIsProcessing(true)
                toast.success(result.data.message);
            }else{
                toast.error(result.data.message);
            }
        }).catch((error) =>{
            setIsProcessing(false)
          toast.error(error.response.data.message);
        }).finally(()=>{
            setIsProcessing(false)
        })
      
    } else {
     toast.error("Password mis match!")
    }
}
}
  return (
    <div className="w-full flex flex-col space-y-4 text-center py-5">

            <div>
            <InputField 
          value={input.password}
          type={passwordVisible ? "text" : "password"}
          onChange={inputHandler}
          label="Password"
          name="password"
          rightIcon={
            passwordVisible ? (
              <FaRegEyeSlash size={20} onClick={togglePasswordVisibility} />
            ) : (
              <FaRegEye size={20} onClick={togglePasswordVisibility} />
            )
          }
        />
                 {passwordError && <p className="text-red">{passwordError}</p>}
            </div>
            <div>
            <InputField 
          value={input.confirmPassword}
          type={confirmPasswordVisible ? "text" : "password"}
          onChange={inputHandler}
          label="Confirm Password"
          name="confirmPassword"
          rightIcon={
            confirmPasswordVisible ? (
              <FaRegEyeSlash size={20} onClick={toggleConfirmPasswordVisibility} />
            ) : (
              <FaRegEye size={20} onClick={toggleConfirmPasswordVisibility} />
            )
          }
        />
            </div>
            <div>
                <SubmitButton disabled={false} onClick={newPassword}>
                    <span className="text-white font-semibold">
                    {isProcessing ? (<ClipLoader
                                  color="#fff"
                                  size={30}
                                  data-testid="loader"
                                />
                              ) : (
                                "Next"
                              )}
                    </span>
                </SubmitButton>
            </div>

        </div>
  )
}

export default NewPassword