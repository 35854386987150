
import InputField from '../../../../components/controls/InputField'
import SubmitButton from '../../../../components/controls/SubmitButton';
import React, { useState} from 'react'
import Util from "../../../../utils/util";
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { authService } from '../../../../services/auth.service';
import ClipLoader from "react-spinners/ClipLoader";
const ForgotPassForm = ({setTab, updateEmailValue}) => {
    const [input, setInput] = useState({
        email: "",
    });
    const [validation] = useState({
		validEmail: true,
	});
	const inputHandler = (e) => {
		const name = e.target.name;
		setInput({ ...input, [name]: e.target.value });
		Util.validate(validation, input, [name]);
	};
 const [isProcessing, setIsProcessing] =  useState(false)

    const sentMail = () => {
        Util.validate(validation, input, ["email"]);
		if ( validation.validEmail) {
            setIsProcessing(true);
      authService.forgotPassword(input).then((result) =>{
        if (result && result.data.success) {
            setTab(2)
            updateEmailValue(input.email)
            setIsProcessing(false);
          toast.success(result.data.message)
          localStorage.setItem("token", result.data.token)
        } else {
            toast.error(result.data.message)
        }
      }).catch((error)=>{
        toast.error(error.response.data.message);
      }).finally(()=>{
        setIsProcessing(false);
      })
        
    } else {
        toast.error("All fields are required!")
    }
    }
    


    return (
        <div className="w-full flex flex-col space-y-4 text-center py-5">

            <div>
                <InputField value={input.email}
                    type="text"
                    // icon={<YourIconComponent />} 
                    onChange={inputHandler}
                    label="Email Address"
                    name="email"

                />
            </div>
            <div>
                <SubmitButton disabled={false} onClick={sentMail}>
                    <span className="text-white font-semibold">
                    {isProcessing ? (
                                <ClipLoader
                                  color="#fff"
                                  size={30}
                                  data-testid="loader"
                                />
                              ) : (
                                "Next"
                              )}
                    </span>
                </SubmitButton>
            </div>

        </div>
    )
}

export default ForgotPassForm