import React, { useState } from "react";
import SelectField from "../../../components/controls/SelectField";
import SubmitButton from "../../../components/controls/SubmitButton";
import TextArea from "../../../components/controls/TextArea";
const Requery = ({
  transId,
  sessionId,
  amount,
  status,
  sourceAccount,
  sourceName,
  sourceBank,
  destinationAccount,
  destinationName,
  destinationBank,
  date,
}) => {
  //  console.log(JSON.stringify(details))
  const [textValue, setTextValue] = useState("");
  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };
  const [dispute, setDispute] = useState(false);
  const openDispute = () => {
    setDispute((prevDispute) => !prevDispute);
  };
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "successful":
        return "text-green-500";
      case "pending":
        return "text-amber-500";
      case "failed":
        return "text-red-500";
      default:
        return "text-gray-700"; // Default color if status is not recognized
    }
  };
  const updatedDate = new Date(date * 1000);
  const formattedDate = updatedDate.toLocaleString();
  return (
    <div className="w-full px-5">
      <div className="">
        <p className="text-xl text-center font-semibold">Transaction Details</p>
      </div>
      <div className="py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">Transaction ID</p>
            <p className="text-gray-700 text-lg font-medium">{transId}</p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">Session ID</p>
            <p className="text-gray-700 text-lg font-medium">{sessionId}</p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">Amount</p>
            <p className="text-gray-700 text-lg font-medium">₦ {amount}</p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">From Account Number</p>
            <p className="text-gray-700 text-lg font-medium">
              {sourceAccount}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">From Account Name</p>
            <p className="text-gray-700 text-lg font-medium">
              {sourceName}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400">From Bank</p>
            <p className="text-gray-700 text-lg font-medium">
              {sourceBank}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">To Account Number</p>
            <p className="text-gray-700 text-lg font-medium">
              {destinationAccount}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">To Account Name</p>
            <p className="text-gray-700 text-lg font-medium">
              {destinationName}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400">To Bank</p>
            <p className="text-gray-700 text-lg font-medium">
              {destinationBank}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">Transaction Response</p>
            <p className={`text-lg font-medium ${getStatusColor(status)}`}>
              {status}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="text-gray-400 text-sm">Time and Date</p>
            <p className="text-gray-700 text-lg font-medium">{formattedDate}</p>
          </div>
        </div>
        <div className="py-4 flex flex-col md:flex-row justify-between">
          <div className="hidden md:flex" />
          <div className="justify-end ">
            <SubmitButton onClick={openDispute}>Raise a Dispute</SubmitButton>
          </div>
        </div>
        {dispute && (
          <>
            <div className="py-4">
              <div className="flex flex-col space-y-4">
                <div>
                  <SelectField name="" label="Reason" value="">
                    <option value="">Temporary</option>
                    <option value="">Permanent</option>
                  </SelectField>
                </div>
                <div>
                  <TextArea
                    label="Description"
                    value={textValue}
                    onChange={handleTextChange}
                    placeholder="Enter text here..."
                  />
                </div>
                <div className="w-1/2 flex justify-end">
                  <SubmitButton
                  //  onClick={!loading ? signin : null}
                  >
                    {/* {loading ? (
                            <ClipLoader
                                color="#fff"
                                size={30}
                                data-testid="loader"
                            />
                        ) : ( */}
                    Submit
                    {/* )} */}
                  </SubmitButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Requery;
