import { useState } from "react";
import InputField from "../../components/controls/InputField";
import SubmitButton from "../../components/controls/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "../../services/auth.service";
import { FaArrowRight } from "react-icons/fa";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import { FiLock } from "react-icons/fi";
import { FaRegEye, FaRegEyeSlash, FaRegEnvelope } from "react-icons/fa6";
import OtpModal from "../../components/modal/OtpModal";
import OtpForm from "./OtpForm";
import { storage } from "utils/storage";
const Login = () => {
  const [form, setForm] = useState({
    contact: "",
    password: "",
    deviceId: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const [isModalUOpen, setIsModalUOpen] = useState(false);
  const handleModalUClose = () => setIsModalUOpen(false);
  const signin = () => {
    if (form?.contact === "" || form?.password === "") {
      toast.error("All Field are required");
      return; // Stop further execution
    }
    setLoading(true);
    const request = {
      contact: form?.contact,
      password: form?.password,
      deviceId: "",
    };
    authService
      .login(request)
      .then((result) => {
        if (result && result.data.success) {
          toast.success(result.data.message);
          storage.save("token", result.data.token)
          setTimeout(() => {
            setIsModalUOpen(true);
          }, 300);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ?? error.response.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      signin();
    }
  };
  const IMG_PATH = "/img/logos/";
  return (
    <div className="">
      <div className="bg-white h-screen">
        <div className="header py-4 text-center">
          <p className="text-xl uppercase text-dark font-bold text-center py-3">
            Welcome Back
          </p>
          <p className="text-gray-300">
            Securely login to your bellmonie account.
          </p>
        </div>
        <div className="login-form px-3 md:p-8 flex flex-col space-y-6">
          <div className="">
            <InputField
              type="text"
              name="contact"
              label="Email Address"
              placeholder="Email Address"
              onChange={(e) => {
                setForm({ ...form, contact: e.target.value });
              }}
              value={form?.contact}
              leftIcon={<FaRegEnvelope size={20} />}
            />
          </div>
          <div className="">
            <InputField
              type={passwordVisible ? "text" : "password"}
              value={form?.password}
              name="password"
              label="Password"
              placeholder="Password"
              onChange={(e) => {
                setForm({ ...form, password: e.target.value });
              }}
              leftIcon={<FiLock size={20} />}
              rightIcon={
                passwordVisible ? (
                  <FaRegEyeSlash size={20} onClick={togglePasswordVisibility} />
                ) : (
                  <FaRegEye size={20} onClick={togglePasswordVisibility} />
                )
              }
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="text-right">
            <Link to="/forgot-password">
              <p className="text-md text-primary font-semibold">
                Forget Password
              </p>
            </Link>
          </div>
          <div>
            <SubmitButton onClick={signin}>
              {loading ? (
                <ClipLoader color="#fff" size={30} data-testid="loader" />
              ) : (
                "Login"
              )}
            </SubmitButton>
          </div>
        </div>
        <div className="py-2  flex flex-row justify-center items-center space-x-2">
          <p className="text-sm text-secondary">Don't you have an account?</p>

          <p
            onClick={() => navigate("/signup")}
            className="text-sm text-primary flex flex-row justify-center items-center space-x-2 cursor-pointer"
          >
            <span>Sign Up</span>
            <span>
              <FaArrowRight />
            </span>
          </p>
        </div>
        <div className="flex flex-col justify-center items-center mx-auto px-6 py-4 space-y-4">
          <p className="text-md text-secondary text-center ">
            Product of
            <span className="text-md text-primary font-bold px-2">
            Bellbank Microfinance Bank
            </span>
          </p>
          <div className="flex flex-row justify-center items-start space-x-2">
            <img
              className="w-6 h-6"
              src={`${IMG_PATH}/cbn.svg`}
              alt="cbn-img"
            />
            <p className="text-md text-secondary text-center">Lincensed By</p>
            <p className="text-md text-primary text-center">
              Central Bank Of Nigeria
            </p>
          </div>
        </div>
      </div>
      <OtpModal
        title="Verify Otp"
        open={isModalUOpen}
        onClose={handleModalUClose}
        width={400}
        height={350}
      >
        <OtpForm
          email={form.contact}
          reSend={signin}
          onClose={handleModalUClose}
        />
      </OtpModal>
    </div>
  );
};

export default Login;