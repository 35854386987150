import React, { useState } from 'react'
import { BellIcon } from "@heroicons/react/24/outline";
import {  FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi";
const Notification = ({dropNotidownRef, isNotificationDropdownOpen, toggleNotiDropdown }) => {


  const [tab, setTab] = useState(1);

  const changeTab= (t) =>{
    setTab(t)
  }

  return (
    <>
    <div className="w-10 h-10 border border-lightSec rounded-full flex items-center justify-center">
   <div className="cursor-pointer"
    onClick={(e) => {
          e.stopPropagation();
          toggleNotiDropdown();}}>
          <BellIcon className="h-6 w-6 text-dark" />
   </div>
   
    {isNotificationDropdownOpen && (
        <div
          ref={dropNotidownRef}
          className="absolute flex flex-col space-y-3 top-20 right-10 md:right-28 lg:right-48 w-80 h-60 bg-white shadow-lg rounded-lg py-4 px-4 z-50"
        >
         
          <ul className="flex  space-x-4 cursor-pointer">
           <li onClick={(t) =>{changeTab(1)}} className={tab ===1 ? `text-dark  border-b-4 font-bold border-dark text-md`:`text-secondary text-md font-medium cursor-pointer`}>All</li>
           <li onClick={(t) =>{changeTab(2)}} className={tab ===2 ? `text-dark  border-b-4 font-bold border-dark text-md`:`text-secondary text-md font-medium cursor-pointer`}>Read</li>
           <li onClick={(t) =>{changeTab(3)}} className={tab ===3 ? `text-dark  border-b-4 font-bold border-dark text-md`:`text-secondary text-md font-medium cursor-pointer`}>Unread</li>
          </ul>
          <div>
            {tab ===1 ?(<>
            <ul className="flex flex-col space-y-4">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowUpRight size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-green text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowDownLeft size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-red text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowDownLeft size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-red text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
            </ul>
            </>):tab ===2?(<>
            <ul className="flex flex-col space-y-4">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowUpRight size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-green text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowDownLeft size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-red text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowDownLeft size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-red text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
            </ul>
            </>):(<>
            <ul className="flex flex-col space-y-4">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowUpRight size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-green text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowDownLeft size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-red text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                  <div className="w-10 h-10 bg-ash rounded-full flex justify-center items-center">
                  <FiArrowDownLeft size={25} className="text-dark" />
                  </div>
                  <div className="flex flex-col space-y-0">
                    <p className="text-dark text-sm font-bold">From Micheal Pedro</p>
                    <p className="text-secondary text-xs">Received <span>Merchant</span></p>
                  </div>
                </div>
                <div className="flex flex-col ">
                <p className="text-red text-sm font-bold">+ ₦200.00</p>
                    <p className="text-secondary text-xs">29 Apr 2023</p>
                </div>
              </div>
            </ul>
            </>)}
           
          </div>
        </div>
      )}
    </div>
    </>
  )
}

export default Notification