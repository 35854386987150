import React from 'react'
import { FaCircleCheck } from "react-icons/fa6";
import SubmitButton from '../../../../components/controls/SubmitButton';
import { useNavigate } from 'react-router-dom';
const AccountSuccess = () => {
  const navigate =  useNavigate();
  return (

    <div className="flex flex-col  space-y-8  py-5">
      <div className="px-12 mx-auto">
        <FaCircleCheck size={120} className="text-green" />
      </div>
       <div className="px-16">
                <SubmitButton disabled={false} onClick={() => navigate("/")}>
                    <span className="text-white font-semibold">
                       Get Started
                    </span>
                </SubmitButton>
            </div>
    </div>
  )
}

export default AccountSuccess