import React, { useState, useEffect } from "react";
import InputField from "../../../components/controls/InputField";
import SelectField from "../../../components/controls/SelectField";
import SubmitButton from "../../../components/controls/SubmitButton";
import TextArea from "../../../components/controls/TextArea";
import FileUpload from "../../../components/controls/FileUpload";
import RadioField from "../../../components/controls/RadioField";
// import RightDrawer from "../../../components/drawer/RightDrawer";
// import Button from "../../../components/controls/Button";
// import BorderButton from "../../../components/controls/BorderButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DirectorDetails from "./DirectorDetails";
import States from "../../../utils/state.json";
import { kycService } from "../../../services/kyc.service";
import { uploadService } from "../../../services/fileUpload.service";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
const UpdateDirector = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const [directors, setDirectors] = useState([]);
 
  const getKycs = () => {
    kycService
      .getKycs()
      .then((result) => {
        if (result && result.data?.success) {
          const kycData = result?.data.data;

          const directors = kycData?.Directors;
          if (directors) {
           
            setDirectors(directors);
          } else {
            setDirectors([]);
          
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getKycs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {

    if (directors.length > 0 && id) {
        const directorToUpdate = directors.find((director) => director.id.toString() === id);
      if (directorToUpdate) {
       
        setInput((prevInput) => ({
          ...prevInput,
          data: {
            ...prevInput.data,
            firstname: directorToUpdate.firstname,
            surname: directorToUpdate.surname,
            email: directorToUpdate.email,
            dateOfBirth: directorToUpdate.dateOfBirth,
            bvn: directorToUpdate.bvn,
            title: directorToUpdate.title,
            role: directorToUpdate.role,
            equity: directorToUpdate.equity,
            phone: directorToUpdate.phone,
            identityType: directorToUpdate.identityType,
            identityNumber: directorToUpdate.identityNumber,
            country: directorToUpdate.country,
            issuingDate: directorToUpdate.issuingDate,
            expiryDate: directorToUpdate.expiryDate,
            address: directorToUpdate.address,
            city: directorToUpdate.city,
            state: directorToUpdate.state,
          },
        }));
      }else{
        setInput({
            data: {
                firstname: "",
                surname: "",
                email: "",
                dateOfBirth: "",
                bvn: "",
                title: "",
                role: "",
                equity: "",
                phone: "",
                identityType: "",
                identityNumber: "",
                country: "",
                issuingDate: "",
                expiryDate: "",
                address: "",
                city: "",
                state: "",
                
              },
        })
      }
    }
  }, [directors, id]);

  const [input, setInput] = useState({
    data: {
      firstname: "",
      surname: "",
      email: "",
      dateOfBirth: "",
      bvn: "",
      title: "",
      role: "",
      equity: "",
      phone: "",
      identityType: "",
      identityNumber: "",
      country: "",
      issuingDate: "",
      expiryDate: "",
      address: "",
      city: "",
      state: "",
    },
    documents: [],
  });
  const [uploadedIdFile, setUploadedIdFile] = useState(null);
  const [uploadedAddressFile, setUploadedAddressFile] = useState(null);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [loadingId, setLoadingId] = useState(false);

  const inputHandler = (e) => {
    const name = e.target.name;
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);

    setInput((prevInput) => ({
      ...prevInput,
      data: {
        ...prevInput.data,
        [name]: capitalizedValue,
      },
    }));
  };
  const equityHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // Remove any non-digit characters
    value = value.replace(/\D/g, "");

    // Ensure the value is between 0 and 100
    value = Math.min(Math.max(parseInt(value), 0), 100);

    setInput((prevInput) => ({
      ...prevInput,
      data: {
        ...prevInput.data,
        [name]: value.toString(),
      },
    }));
  };

  const handleIdFileChange = (file) => {
    setLoadingId(true);
    setUploadedIdFile(file);
    updateInputFile("ID card", file, setLoadingId);
  };
  const handleAddressFileChange = (file) => {
    setLoadingAddress(true);
    setUploadedAddressFile(file);
    updateInputFile("proof of address", file, setLoadingAddress);
  };
  const handleReplaceIdClick = (file) => {
    file.preventDefault();
    document.getElementById("idFileInput").click();
    setUploadedIdFile(file);
    updateInputFile("ID card", file);
  };

  const handleReplaceAddressClick = (file) => {
    file.preventDefault();
    document.getElementById("addressFileInput").click();
    setUploadedAddressFile(file);
    updateInputFile("proof of address", file);
  };
  const updateInputFile = async (name, selectedFile, setLoading) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const uploadResponse = await uploadService.uploadFile(formData);

      if (
        uploadResponse.data &&
        uploadResponse.data.success &&
        uploadResponse.data.data
      ) {
        const fileURL = uploadResponse.data.data.url;
        toast.success("file uploaded successful..");
        setInput((prevInput) => {
          const existingFileIndex = prevInput.documents.findIndex(
            (item) => item.name === name
          );

          if (existingFileIndex !== -1) {
            const updatedDocuments = [...prevInput.documents];
            updatedDocuments[existingFileIndex] = { name, url: fileURL };

            return {
              ...prevInput,
              documents: updatedDocuments,
            };
          } else {
            return {
              ...prevInput,
              documents: [...prevInput.documents, { name, url: fileURL }],
            };
          }
        });
      } else {
        toast.error(uploadResponse.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data.message ?? error.message);
    } finally {
      setLoading(false); // Reset loading indicator
    }
  };

  const handleRadioChange = (selectedRole) => {
    setInput((prevInput) => ({
      ...prevInput,
      data: {
        ...prevInput.data,
        role: selectedRole,
      },
    }));
  };

  const [lgasOptions, setLgasOptions] = useState([]); // Add this line to declare the state for lgasOptions

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    const selectedStateData = States.find(
      (item) => item.state === selectedState
    );

    setInput((prevInput) => ({
      ...prevInput,
      data: {
        ...prevInput.data,
        state: selectedState.charAt(0).toUpperCase() + selectedState.slice(1),
        city: "",
      },
    }));

    if (selectedStateData) {
      // Update lgas for the selected state
      const lgasOptions = selectedStateData.lgas.map((lga) => (
        <option key={lga} value={lga}>
          {lga}
        </option>
      ));

      // Set lgas options for the second select input
      setLgasOptions(lgasOptions);
    }
  };

  const handleLgaChange = (event) => {
    setInput((prevInput) => ({
      ...prevInput,
      data: {
        ...prevInput.data,
        city:
          event.target.value.charAt(0).toUpperCase() +
          event.target.value.slice(1),
      },
    }));
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const Submit = async () => {
    // if (Object.values(input.data).some(value => value === '') || input.documents.length !== 2) {
    //   toast.error("All fields are required!");
    //   return;
    // }

    setIsProcessing(true);
    try {
      const result = await kycService.updateBusinessDirectors(id, input);

      if (result.data && result.data.data && result.data.success) {
        setTimeout(() => {
          setIsProcessing(false);
          navigate("/onboarding")
        }, 3000);
        toast.success(result.data.message);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data.message ?? error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="py-12 px-3 md:px-24  mx-auto">
      <div className="flex flex-row space-x-2 md:space-x-4 items-center">
        <div onClick={() => {navigate("/directors")}} className="bg-white h-8 w-8 border flex justify-center items-center border-ash rounded-full cursor-pointer">
            <FaAngleLeft size={15} className="text-secondary" />
            </div>
        <div className="flex flex-col space-y-1 md:space-y-3 py-4">
          <div>
            <h3 className="text-dark text-xl md:text-2xl font-bold capitalize">
              Update Director
            </h3>
          </div>
          <p className="text-md font-regular text-secondary">
            Update director here
          </p>
        </div>
      </div>

      <div className="max-w-[1000px] h-full flex justify-center items-center bg-white p-3 md:p-6">
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
            <div>
              <InputField
                value={input.data.firstname}
                type="text"
                label="Firstname"
                name="firstname"
                onChange={inputHandler}
              />
            </div>
            <div>
              <InputField
                value={input.data.surname}
                type="text"
                label="Surname"
                name="surname"
                onChange={inputHandler}
              />
            </div>
            <div>
              <InputField
                value={input.data.email}
                type="text"
                label="Email Address"
                name="email"
                onChange={inputHandler}
              />
            </div>
            <div>
              <InputField
                value={input.data.phone}
                type="text"
                label="Phone Number"
                name="phone"
                onChange={inputHandler}
              />
            </div>
            <div>
              <InputField
                value={input.data.bvn}
                type="text"
                onChange={inputHandler}
                label="Bvn"
                name="bvn"
              />
            </div>
            <div>
              <InputField
                value={input.data.dateOfBirth}
                type="date"
                label="Date Of Birth"
                name="dateOfBirth"
                onChange={inputHandler}
              />
            </div>
            <div>
              <InputField
                value={input.data.title}
                type="text"
                label="Job Title"
                name="title"
                onChange={inputHandler}
              />
            </div>

            <div className=" flex flex-row space-x-5 items-center mt-8">
              <div className="flex justify-end">
                <RadioField
                  checked={input.data.role === "director"}
                  onChange={() => handleRadioChange("director")}
                  name="role"
                  label="Director"
                  id="director"
                  value={input.data.role}
                />
              </div>
              <div className="flex justify-end">
                <RadioField
                  checked={input.data.role === "shareholder"}
                  onChange={() => handleRadioChange("shareholder")}
                  name="role"
                  label="Shareholder"
                  id="shareholder"
                  value={input.data.role}
                />
              </div>
              <div className="flex justify-end">
                <RadioField
                  checked={input.data.role === "both"}
                  onChange={() => handleRadioChange("both")}
                  name="role"
                  label="Both"
                  id="both"
                  value={input.data.role}
                />
              </div>
            </div>

            <div>
              <InputField
                value={input.data.equity}
                type="text"
                label="Equity"
                name="equity"
                onChange={equityHandler}
              />
            </div>
            <div className="w-full">
              <SelectField
                name="identityType"
                label="ID type"
                value={input.data.identityType}
                onChange={(e) => {
                  const { value } = e.target;
                  setInput((prevInput) => ({
                    ...prevInput,
                    data: {
                      ...prevInput.data,
                      identityType: value,
                    },
                  }));
                }}
              >
                <option value="">Identity Type</option>
                <option value="Passport">Passport</option>
                <option value="Nin">Nin</option>
                <option value="Voters Card">Voters Card</option>
              </SelectField>
            </div>
            <div>
              <InputField
                value={input.data.identityNumber}
                type="text"
                label="ID Number"
                name="identityNumber"
                onChange={inputHandler}
              />
            </div>
            <div>
              <InputField
                value={input.data.country}
                type="text"
                label="Issuing Country"
                name="country"
                onChange={inputHandler}
              />
            </div>
            <div>
              <InputField
                value={input.data.issuingDate}
                type="date"
                label="Issuing Date"
                name="issuingDate"
                onChange={inputHandler}
              />
            </div>
            <div>
              <InputField
                value={input.data.expiryDate}
                type="date"
                label="Expire Date"
                name="expiryDate"
                onChange={inputHandler}
              />
            </div>
            <div className="col-span-1 lg:col-span-2">
              <TextArea
                label="Address"
                name="address"
                rows="2"
                value={input.data.address}
                onChange={(e) => {
                  const { value } = e.target;
                  setInput((prevInput) => ({
                    ...prevInput,
                    data: {
                      ...prevInput.data,
                      address: value,
                    },
                  }));
                }}
              />
            </div>
            <div className="w-full">
              <SelectField
                name="state"
                label="State"
                value={input.data.state}
                onChange={handleStateChange}
              >
                <option value="">State</option>
                {States.map((item) => (
                  <option key={item.state} value={item.state}>
                    {item.state}
                  </option>
                ))}
              </SelectField>
            </div>
            <div className="w-full">
              <SelectField
                name="city"
                label="City"
                value={input.data.city}
                onChange={handleLgaChange}
              >
                <option value="">City</option>
                {lgasOptions}
              </SelectField>
            </div>

            <div className="flex flex-col mx-auto space-y-2">
              <div className="flex flex-row justify-between">
                <h3 className="text-dark font-semibold text-start">
                  Upload ID
                </h3>
                {uploadedIdFile && (
                  <div className="text-right">
                    <p
                      className="text-primary cursor-pointer"
                      onClick={handleReplaceIdClick}
                    >
                      Replace
                    </p>
                  </div>
                )}
              </div>
              <FileUpload id="idFileInput" onFileChange={handleIdFileChange} />
              {loadingId && <p className="text-primary">Uploading...</p>}
            </div>
            <div className="flex flex-col mx-auto space-y-2">
              <div className="flex flex-row justify-between">
                <h3 className="text-dark font-semibold text-start">
                  Upload Prof of address
                </h3>
                {uploadedAddressFile && (
                  <div className="text-right">
                    <p
                      className="text-primary cursor-pointer"
                      onClick={handleReplaceAddressClick}
                    >
                      Replace
                    </p>
                  </div>
                )}
              </div>
              <FileUpload
                id="addressFileInput"
                onFileChange={handleAddressFileChange}
              />
              {loadingAddress && <p className="text-primary">Uploading...</p>}
            </div>
            <div></div>
            <div className="my-2">
              <SubmitButton
                onClick={!isProcessing ? Submit : null}
                disabled={
                  isProcessing ||
                  input.documents?.length !== 2 ||
                  input.documents?.some((doc) => !doc.url || !doc.name)
                }
              >
                {isProcessing ? (
                  <ClipLoader color="#fff" size={30} data-testid="loader" />
                ) : (
                  "Submit"
                )}
              </SubmitButton>
            </div>
            <div></div>
          </div>
        </>
      </div>
    </div>
  );
};

export default UpdateDirector;
