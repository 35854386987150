import React from 'react'
import Tabs from './components/Tabs'

const TestFundAccount = () => {
  return (
    <div className="p-6">
    <div className="py-4 flex flex-col space-y-3">
        <p className="text-xl text-dark font-semibold">Fund Test Account</p>
        <p className="text-dark text-sm">fund test account and make account deposit</p>
    </div>
    <div className="py-6 px-4 max-w-[400px] mx-auto justify-center shadow-sm rounded-xl bg-white">
     <Tabs />

    </div>
</div>
  )
}

export default TestFundAccount