// import React from "react";
import PropTypes from "prop-types";

const SubmitButton = ({ children, onClick, bgColor, textColor, disabled }) => {
  return (
    <>
      <button
        // style={{ height: "50px" }} 
        className={`w-full rounded-lg  px-4 py-3 md:px-6 text-center cursor-pointer ${bgColor} ${textColor} ${disabled === true ? `${bgColor}/50`: `${bgColor}`}`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

SubmitButton.defaultProps = {
  children: "",
  bgColor: "bg-primary",
  textColor: "text-white",
  disabled: false,
  onClick: () => {},
};

export default SubmitButton;
