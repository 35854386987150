import React from 'react'
import { BiSupport } from "react-icons/bi";
import Input from '../../../components/controls/Input'
const Help = () => {
  return (
    <div className="bg-white w-full p-6">
    <div className="flex flex-col space-y-4">
        <div className="bg-lightSec w-full p-3 rounded-lg flex flex-row justify-between items-center space-y-3">
<div className="flex flex-col space-y-2">
  <div className="flex flex-row space-x-1 items-center">
    <BiSupport size={20} className="text-secondary" />
    <p className="text-sm text-secondary">7*24hr available</p>
  </div>
  <p className="text-md text-dark font-bold">Online Servcie</p>
</div>
<div className="justify-end">
<button className="bg-white p-2 text-dark rounded-lg">Chat Now</button>
</div>
        </div>
        <div className="flex flex-col md:flex-row space-x-5">
        <div>
                    <Input
                        value="0122338890"
                        type="text"
                        // onChange={inputHandler}
                        label="Contact Number"
                        name="email"
                        disabled

                    />
                </div>
                <div>
                    <Input
                        value="Support@bellmonie.com"
                        type="text"
                        // onChange={inputHandler}
                        label="Email Address"
                        name="email"
                        disabled

                    />
                </div>
        </div>
        </div>
        </div>
  )
}

export default Help