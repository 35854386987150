import React, { useState, useMemo, useEffect, useRef } from "react";
import Table from "../../components/Table/Table";
import TableOption from "../../components/modal/TableOption";
import { SlOptionsVertical } from "react-icons/sl";
import InputField from "../../components/controls/InputField";
import { FiSearch } from "react-icons/fi";
import SelectField from "../../components/controls/SelectField";
import ExportButton from "../../components/controls/ExportButton";
import { toast } from "react-toastify";
import { transactionService } from '../../services/transaction.service';
import Loading from "../../components/loader/Loading"
import useAuth from "../../usehook/useAuth";
import { useParams } from "react-router-dom";
import { FiCopy } from "react-icons/fi";
import FilterByDate from "./FilterByDate";
const Transaction = () => {
  const { logout } = useAuth();
  const [transData, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {acct} = useParams();
  const getTransactions = async () => {
    try {
        setIsLoading(true);
        const result = await transactionService.getACollectionTransaction(acct, currentPage);
        if (result && result.data && result.data.success === true) {
          setData(result.data.data);
            // alert(JSON.stringify(result.data.data))
            setTotalPages(result.data.pagination.pages);
            setIsLoading(false);
        } else {
          toast.error("Error fetching transaction..")
          setData([]);
        }
    } catch (error) {
        console.error(error);
        if(error.response.status === 401){
          logout()
        }
        setData([]);
        setTotalPages(0);
        setIsLoading(false);
    } finally {
        setIsLoading(false);
    }
};
useEffect(() => {
  getTransactions(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
const handlePageChange = ({ nextPage, previousPage }) => {
  setCurrentPage({ nextPage, previousPage });
  getTransactions(nextPage); 
};
const getTransactionsByStatus = async (status) => {
  try {
    setIsLoading(true);
    const result = await transactionService.getInwardTransactionsByStatus(
      status,
      currentPage
    );
    if (result && result.data && result.data.success === true) {
      setData(result.data.data);
      // alert(JSON.stringify(result.data.data))
      setTotalPages(result.data.pagination.pages);
      setIsLoading(false);
    } else {
      toast.error("Error fetching transaction..");
      setData([]);
    }
  } catch (error) {
    console.error(error);
    if (error.response.status === 401) {
      logout();
    }
    setData([]);
    setTotalPages(0);
    setIsLoading(false);
  } finally {
    setIsLoading(false);
  }
};
const getTransactionsByRef = async (value) => {
  try {
    setIsLoading(true);
    const result = await transactionService.getTransactionsByReference(value);
    if (result && result.data && result.data.success === true && result.data.data) {
      setData([result.data.data]);
    
    } else {
      toast.error("Error fetching transactions.");
      setData([]);
    }
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status === 401) {
      logout();
    }
    setData([]);
  } finally {
    setIsLoading(false);
  }
};
const [ref, setRef] = useState('')
const [selectedStatus, setSelectedStatus] = useState('');

const handleStatusChange = (event) => {
  const status = event.target.value;
  setSelectedStatus(status);
  getTransactionsByStatus(status);
};
const handleReferenceChange = (event) => {
  const value = event.target.value;
  setRef(value);
  if (value === '') {
    // If the search value is empty, fetch all transactions
    getTransactions();
  } else {
    // Otherwise, fetch transactions by reference
    getTransactionsByRef(value);
  }
};
  const [isReQueryDrawerOpen, setIsReQueryDrawerOpen] = useState(false);
  const [isDisputeDrawerOpen, setIsDisputeDrawerOpen] = useState(false);

  const handleDrawerReQueryOpen = () => {
    setIsReQueryDrawerOpen(true);
  };
  const handleDrawerDisputeOpen = () => {
    setIsDisputeDrawerOpen(true);
  };
  // const handleDisputeDrawerClose = () => {
  //   setIsDisputeDrawerOpen(false);
  // };
  // const handleReQueryDrawerClose = () => {
  //   setIsReQueryDrawerOpen(false);
  // };
  useEffect(() => {
    // Open the ReQuery drawer when isReQueryDrawerOpen changes
    if (isReQueryDrawerOpen) {
      handleDrawerReQueryOpen();
    }

    // Open the Dispute drawer when isDisputeDrawerOpen changes
    if (isDisputeDrawerOpen) {
      handleDrawerDisputeOpen();
    }
  }, [isReQueryDrawerOpen, isDisputeDrawerOpen]);
  const [copiedValue, setCopiedValue] = useState(null);

  const handleCopy = (value) => {
    // Copy the value to the clipboard
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopiedValue(value);
        console.log(`Copied: ${value}`);
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard", error);
      });
  };
  const columns = useMemo(() => {
    return [
      {
        Header: "Reference",
        accessor: "reference",
        Cell: ({ value }) => {
          // Check if the value is present and has a length greater than 8
          if (value && value.length > 8) {
            const firstFourLetters = value.substring(0, 4);
            const lastFourLetters = value.substring(value.length - 4);

            const modifiedValue = `${firstFourLetters}...${lastFourLetters}`;

            return (
              <span
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={() => handleCopy(value)}
          >
            {modifiedValue}
            <FiCopy size={16} className="ml-2" onClick={() => handleCopy(value)} />
          </span>
            );
          } else {
            // Handle the case where the value is not present or too short
            return value;
          }
        },
      },
      {
        Header: "Session ID",
        accessor: "receiptNumber",
        Cell: ({ value }) => {
          // Check if the value is present and has a length greater than 8
          if (value && value.length > 8) {
            const firstFourLetters = value.substring(0, 4);
            const lastFourLetters = value.substring(value.length - 4);

            const modifiedValue = `${firstFourLetters}...${lastFourLetters}`;

            return (
              <span
              style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={() => handleCopy(value)}
            >
              {modifiedValue}
              <FiCopy size={16} className="ml-2" onClick={() => handleCopy(value)} />
            </span>
            );
          } else {
            // Handle the case where the value is not present or too short
            return value;
          }
        },
      },
      
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => {
            
          const formattedAmount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN', 
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value);
  
          return (
            <>
              <div>{formattedAmount}</div>
            </>
          );
        },
      },
      {
        Header: "Credit Account",
        accessor: "creditAccountNumber",
       
      },
      {
        Header: "Remark",
        accessor: "remarks",
       
      },
      {
        Header: "Response",
        accessor: "status",
        Cell: ({ row }) => {
          const status = row.original.status;
          let statusText, statusColor, borderColor, bgColor;

          if (status === "successful") {
            statusText = "Successful";
            statusColor = "text-green";
            borderColor = "border-green";
            bgColor = "bg-green/25";
          } else if (status === "pending") {
            statusText = "Pending";
            statusColor = "text-amber-600";
            borderColor = "border-amber-300";
            bgColor = "bg-amber-50";
          } else if (status === "paid") {
            statusText = "Paid";
            statusColor = "text-red";
            borderColor = "border-red";
            bgColor = "bg-red/25";
          } else if (status === "initiated") {
            statusText = "initiated";
            statusColor = "text-amber-600";
            borderColor = "border-amber-300";
            bgColor = "bg-amber-50";
          } else {
            statusText = "Failed";
            statusColor = "text-red";
            borderColor = "border-red";
            bgColor = "bg-red/25";
          }

          return (
            <div
              className={`flex flex-row space-x-4 justify-center items-center w-20 border rounded-md p-2 ${bgColor}  ${borderColor} `}
            >
              <span className={`text-center ${statusColor}`}>{statusText}</span>
            </div>
          );
        },
      },
     
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: ({ value }) => {
          const date = new Date(value * 1000); // Convert Unix timestamp to milliseconds
          const formattedDate = date.toLocaleString(); // Format the date as a string
          return (
            <div>
              {formattedDate}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "menu",
        Cell: ({ row }) => {
          return (
            <div>
              <TableOption
                index={0}
                icon={<SlOptionsVertical size={20} className="text-primary" />}
              >
                <ul className="flex flex-col space-y-1 py-2 capitalize">
                  <li className="">
                    <button
                      onClick={() =>
                          handleDrawerReQueryOpen()
                        }
                    className="w-full p-2 text-start hover:bg-primary hover:text-white">
                      Query
                    </button>
                  </li>
                  <li>
                    <button
                      className="w-full p-2 text-start hover:bg-primary hover:text-white"
                      //   onClick={() =>
                      //     openModal(
                      //       row.original.id,
                      //       row.original.stage,
                      //       row.original.status
                      //     )
                      //   }
                    >
                      Re Push
                    </button>
                  </li>
                  {/* <li>
                    <button
                      className="w-full p-2 text-start hover:bg-primary hover:text-white"
                      onClick={() =>
                        handleDrawerDisputeOpen()
                      }
                    >
                      Raise a dispute
                    </button>
                  </li> */}
                </ul>
              </TableOption>
            </div>
          );
        },
      },
    ];
  }, []);
  const [isModalUOpen, setIsModalUOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleUOpen = () => {
    setIsModalUOpen(!isModalUOpen);
  };
  return (
    <div className="p-6">
       
       <div className="p-3">
        <div> {copiedValue && <p>Copied: {copiedValue}</p>}</div>
      <div className="flex flex-col space-y-5 md:flex-row md:justify-between">
      <div className="relative md:mt-7">
            <ExportButton onClick={(e) =>{ e.stopPropagation()
              handleUOpen()
              }} />
            {isModalUOpen ? (
              <>
              <div
              ref={dropdownRef}
              className="absolute w-80 h-80 my-4 py-4 border bg-white shadow-xl px-3 mx-auto md:left-0 rounded-md z-50">
              <FilterByDate acct={acct} />
              </div>
              </>
            ) : null}
          </div>
       <div className="flex flex-row space-x-5 justify-end">
       <div>
          <InputField
           type="text"
           label="Search"
           name="ref"
           placeholder="Search by reference"
           onChange={handleReferenceChange}
           value={ref}
           rightIcon={<FiSearch size={20} />}
          />
        </div>
        <div>
        <SelectField label="Filter By"  onChange={handleStatusChange}
              value={selectedStatus}>
              <option value="">All</option>
              <option value="created">Created</option>
              <option value="pending">Pending</option>
              <option value="settled">Settled</option>
             
            </SelectField>
        </div>
        <div className="md:mt-7">
       <ExportButton />
       </div>
       </div>
      </div>
      <div className="py-4">
      {isLoading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        {transData.length === 0 ? (
                            <p className="text-center text-red-500 text-md font-semibold">No Transaction available</p>
                        ) : (
                          <Table 
                          columns={columns}
                          data={transData}
                          onPageChange={handlePageChange}
                          totalPages={totalPages}
                          currentPage={currentPage}
                           />
                        )}
                    </>
                )}
       
      </div>
</div>
</div>
  )
}

export default Transaction